import Home from "./Pages/Home";
import Privacy from "./Pages/Privacy";
import TermsConditions from "./Pages/TermsConditions";
import Registration from "./Pages/Registration";
import VBMOnly from "./Pages/VBMOnly";
import VBMIDEntry from "./Pages/VBMIDEntry";
import VBMMailingAddress from "./Pages/VBMMailingAddress";
import VBMType from "./Pages/VBMType";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { store } from "./store";
import EligibilityCitizenship from "./Pages/EligibilityCitizenship";
import EligibilityAge from "./Pages/EligibilityAge";
import EligibilityNotice from "./Pages/EligibilityNotice";
import NotEligible from "./Pages/NotEligible";
import StayInTouch from "./Pages/StayInTouch";
import ThankYou from "./Pages/ThankYou";
import IDVerification from "./Pages/IDVerification";
import IDEntry from "./Pages/IDEntry";
import PartyAffiliation from "./Pages/PartyAffiliation";
import HasMailingAddress from "./Pages/HasMailingAddress";
import MailingAddress from "./Pages/MailingAddress";
import AdditionalQuestions from "./Pages/AdditionalQuestions";
import RequiredHelp from "./Pages/RequiredHelp";
import ReviewInformation from "./Pages/ReviewInformation";
import AddSignature from "./Pages/AddSignature";
import HadAssistance from "./Pages/HadAssistance";
import Confirmation from './Pages/Confirmation';
import VBMConfirmation from "./Pages/VBMConfirmation";
import AssistanceInformation from "./Pages/AssistanceInformation";
import { updateInformation } from "./store/information/informationSlice";
import { getPartnerFields } from "./services/api/voter";
import { useEffect } from "react";
import AssistanceAddSignature from "./Pages/AssistanceAddSignature";
import LastFourEntry from "./Pages/LastFourEntry";
import AddressInformation from "./Pages/AddressInformation";
import PaperApplication from "./Pages/PaperApplication";
import UpdateInformation from "./Pages/UpdateInformation";
import PreviousName from "./Pages/PreviousName";
import NewName from "./Pages/NewName";
import PreviousAddress from "./Pages/PreviousAddress";
import NewAddress from "./Pages/NewAddress";
import CurrentAddress from "./Pages/CurrentAddress";
import RegistrationAddress from "./Pages/RegistrationAddress";
import PageNotFound from "./Pages/PageNotFound";
import { useLocation } from "react-router-dom";
import BallotRequest from "./Pages/BallotRequest";
import BallotRequestConfirmation from "./Pages/BallotRequestConfirmation";
import SurveyQuestion from "./Pages/SurveyQuestion";
import ComingSoon from "./Pages/ComingSoon";

const GuardedRoute = ({ children }) => {
  const checkedRegistration = useSelector((state) => state.registrant.registered);
  const vbmOnly = useSelector((state) => state.registrant.vbm_only);

  if (checkedRegistration == null && !vbmOnly) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const GuardedRouteComingSoon = ({ children }) => {
  if (process.env.REACT_APP_COMING_SOON && process.env.REACT_APP_COMING_SOON === 'true') {
    return <Navigate to="/coming-soon" replace />;
  }

  return children;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('content').classList.remove('splash-bkgd');
  }, [pathname]);

  return null;
}

function AppStack({history}) {
  const currentLanguage = useSelector(state => state.settings.currentLanguage);

  useEffect(() => {
    //console.log(currentLanguage);
  }, [currentLanguage]);

  return (
    <Router>
      <ScrollToTop />
      <div className="flex flex-col h-screen">
        <Header />
        <main id="content" className="flex flex-1">
          <div className="container flex-1 p-4 mx-auto sm:px-8 md:pt-12 md:pb-20">
            <Routes>
              <Route path="/" element={<GuardedRouteComingSoon><Home /></GuardedRouteComingSoon>} />

              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/stay-in-touch" element={<StayInTouch />} />
              <Route path="/not-eligible" element={<NotEligible />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route path="/vbm-confirmation" element={<VBMConfirmation />} />
              <Route path="/new-registration" element={<GuardedRoute><Registration /></GuardedRoute>} />
              <Route path="/update-registration" element={<GuardedRoute><Registration /></GuardedRoute>} />
              <Route path="/eligibility-notice" element={<GuardedRoute><EligibilityNotice /></GuardedRoute>} />
              <Route path="/eligibility-citizenship" element={<GuardedRoute><EligibilityCitizenship /></GuardedRoute>} />
              <Route path="/eligibility-age" element={<GuardedRoute><EligibilityAge /></GuardedRoute>} />
              <Route path="/id-verification" element={<GuardedRoute><IDVerification /></GuardedRoute>} />
              <Route path="/id-entry" element={<GuardedRoute><IDEntry /></GuardedRoute>} />
              <Route path="/party-affiliation" element={<GuardedRoute><PartyAffiliation /></GuardedRoute>} />
              <Route path="/has-mailing-address" element={<GuardedRoute><HasMailingAddress /></GuardedRoute>} />
              <Route path="/mailing-address" element={<GuardedRoute><MailingAddress /></GuardedRoute>} />
              <Route path="/additional-questions" element={<GuardedRoute><AdditionalQuestions /></GuardedRoute>} />
              <Route path="/review-information" element={<GuardedRoute><ReviewInformation /></GuardedRoute>} />
              <Route path="/add-signature" element={<GuardedRoute><AddSignature /></GuardedRoute>} />
              <Route path="/required-help" element={<GuardedRoute><RequiredHelp /></GuardedRoute>} />
              <Route path="/ballot-request" element={<GuardedRoute><BallotRequest /></GuardedRoute>} />
              <Route path="/ballot-request-confirmation" element={<GuardedRoute><BallotRequestConfirmation /></GuardedRoute>} />
              <Route path="/had-assistance" element={<GuardedRoute><HadAssistance /></GuardedRoute>} />
              <Route path="/assistance-information" element={<GuardedRoute><AssistanceInformation /></GuardedRoute>} />
              <Route path="/assistance-signature" element={<GuardedRoute><AssistanceAddSignature /></GuardedRoute>} />
              <Route path="/address-information" element={<GuardedRoute><AddressInformation /></GuardedRoute>} />
              <Route path="/last-four-entry" element={<GuardedRoute><LastFourEntry /></GuardedRoute>} />
              <Route path="/paper-application" element={<GuardedRoute><PaperApplication /></GuardedRoute>} />
              <Route path="/update-information" element={<GuardedRoute><UpdateInformation /></GuardedRoute>} />
              <Route path="/previous-name" element={<GuardedRoute><PreviousName /></GuardedRoute>} />
              <Route path="/new-name" element={<GuardedRoute><NewName /></GuardedRoute>} />
              <Route path="/previous-address" element={<GuardedRoute><PreviousAddress /></GuardedRoute>} />
              <Route path="/new-address" element={<GuardedRoute><NewAddress /></GuardedRoute>} />
              <Route path="/current-address" element={<GuardedRoute><CurrentAddress /></GuardedRoute>} />
              <Route path="/registration-address" element={<GuardedRoute><RegistrationAddress /></GuardedRoute>} />
              <Route path="/survey-question" element={<GuardedRoute><SurveyQuestion /></GuardedRoute>} />

              <Route path="/vbm-id-entry" element={<GuardedRoute><VBMIDEntry /></GuardedRoute>} />
              <Route path="/vbm-only" element={<GuardedRoute><VBMOnly /></GuardedRoute>} />
              <Route path="/vbm-mailing-address" element={<GuardedRoute><VBMMailingAddress /></GuardedRoute>} />
              <Route path="/vbm-type" element={<GuardedRoute><VBMType /></GuardedRoute>} />

              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default AppStack;
