import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { isValidZip } from '../helpers';
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function VBMMailingAddressForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      has_mailing_address: 'no',
      mailing_address_1: '',
      mailing_address_2: '',
      mailing_unit_type: '',
      mailing_unit_number: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zipcode: '',
      mailing_is_usa: true,
      mailing_country: '',
      mailing_address_is_my: '',
    }
  });

  const { has_mailing_address, mailing_is_usa } = watch();

  const onSubmit = function(data) {
    data.mailing_country = (data.mailing_is_usa === 'yes' ? 'United States' : data.mailing_country);
    data.mailing_zipcode = (data.mailing_is_usa === 'yes' ? data.mailing_zipcode.replace(/\-\_*$/g, '') : data.mailing_zipcode);
    submit(data);
  };

  React.useEffect(() => {
    const {
      has_mailing_address,
      mailing_address_1,
      mailing_address_2,
      mailing_unit_type,
      mailing_unit_number,
      mailing_city,
      mailing_state,
      mailing_zipcode,
      mailing_is_usa,
      mailing_country,
      mailing_address_is_my,
    } = registrant;

    setValue('has_mailing_address', has_mailing_address);
    setValue('mailing_address_1', mailing_address_1);
    setValue('mailing_address_2', mailing_address_2);
    setValue('mailing_unit_type', mailing_unit_type);
    setValue('mailing_unit_number', mailing_unit_number);
    setValue('mailing_city', mailing_city);
    setValue('mailing_state', mailing_state);
    setValue('mailing_zipcode', mailing_zipcode);
    setValue('mailing_is_usa', mailing_is_usa);
    setValue('mailing_country', mailing_country);
    setValue('mailing_address_is_my', mailing_address_is_my);
  }, [registrant]);

  React.useEffect(() => {
    setValue('mailing_state', '');
    setValue('mailing_zipcode', '');

    if (mailing_is_usa == 'yes') {
      setValue('mailing_country', 'United States');
    }
  }, [mailing_is_usa]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="has_mailing_address"
          control={control}
          render={({ field }) => <label className="radio">
              <input {...field} type="radio" value="no" defaultChecked={registrant.has_mailing_address === 'no' ? true : false} />
              <span></span> <span className="pl-4">{getContent('has_mailing_address.mailing_ask_yes')}</span>
            </label>
          }
        />
      </div>
      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="has_mailing_address"
          control={control}
          render={({ field }) => <label className="radio">
              <input {...field} type="radio" value="yes" defaultChecked={registrant.has_mailing_address === 'yes' ? true : false} />
              <span></span>  
              <span className="pl-4">{getContent('has_mailing_address.mailing_ask_no')}</span>
            </label>
          }
        />
      </div>
      
      {has_mailing_address === 'yes' && 
        <div className="py-8"> 
          <div className="flex-1 p-2">
            <label>{getContent("fields.mailing_address_in_usa")}*</label>
            <div className="flex items-center justify-between p-2 ">
              <Controller
                name="mailing_is_usa"
                control={control}
                render={({ field }) => <label className="radio">
                    <input {...field} type="radio" value="yes" defaultChecked={registrant.mailing_is_usa == 'yes' ? true : false} />
                    <span></span> <span className="pl-4">{getContent('buttons.yes')}</span>
                  </label>
                }
              />
            </div>
            <div className="flex items-center justify-between p-2 ">
              <Controller
                name="mailing_is_usa"
                control={control}
                render={({ field }) => <label className="radio">
                    <input {...field} type="radio" value="no" defaultChecked={registrant.mailing_is_usa == 'no' ? true : false} />
                    <span></span>  
                    <span className="pl-4">{getContent('buttons.no')}</span>
                  </label>
                }
              />
            </div>
          </div>

          <div className="flex-1 p-2">
            <label>{getContent('fields.mailing_address')}*</label>
            <Controller
              name="mailing_address_1"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.mailing_address_1 ? 'error' : ''}`} />}
            />
            {errors.mailing_address_1?.type === 'required' && <span className="error">{getContent('validation.mailing_address')}</span>}
          </div>

          <div className="flex-1 p-2">
            <label>{getContent('fields.mailing_address_2')}</label>
            <Controller
              name="mailing_address_2"
              control={control}
              rules={{ required: false }}
              render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="25" className={`${errors.mailing_address_2 ? 'error' : ''}`} />}
            />
          </div>

          <div className="flex">
            <div className="flex-1 p-2">
              <label>{getContent('fields.unit_type')}</label>
              <Controller
                name="mailing_unit_type"
                control={control}
                render={({ field }) => {
                  return (
                    <div className="select">
                      <select {...field}>
                        <option value="">{getContent('placeholders.dropdown')}</option>
                        {Object.keys(information?.unitTypes).map((key, i) => {
                          return (
                            <option key={i} value={key}>{information?.unitTypes[key][currentLanguage]}</option>
                          );
                        })}
                      </select>
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex-1 p-2">
              <label>{getContent('fields.unit_number')}</label>
              <Controller
                name="mailing_unit_number"
                control={control}
                render={({ field }) => <input {...field} placeholder="40B" type="text" maxLength="8" autoComplete="off" />}
              />
            </div>
          </div>

          <div className="flex">
            <div className="flex-1 p-2">
              <label>{getContent('fields.mailing_city')}*</label>
              <Controller
                name="mailing_city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="20" className={`${errors.mailing_city ? 'error' : ''}`} />}
              />
              {errors.mailing_city?.type === 'required' && <span className="error">{getContent('validation.mailing_city')}</span>}

            </div>
            <div className="flex-1 p-2">
              <label>{getContent('fields.mailing_state')}*</label>
              {mailing_is_usa === 'yes' ? (
                <Controller
                  name="mailing_state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <div className={`select ${errors.mailing_state ? 'error' : ''}`} >
                        <select {...field}>
                          <option value="">{getContent('placeholders.dropdown')}</option>
                          {Object.keys(information?.states).map((key, i) => {
                            return (
                              <option key={i} value={key}>{information?.states[key]}</option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  }}
                />
              ) : (
                <Controller
                  name="mailing_state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <input {...field} placeholder="State" type="text" autoComplete="off" maxLength="20" className={`${errors.mailing_state ? 'error' : ''}`} />}
                />
              )}
              {errors.mailing_state?.type === 'required' && <span className="error">{getContent('validation.mailing_state')}</span>}
            </div>

          </div>

          <div className="flex-1 p-2">
            <label>{getContent('fields.mailing_zipcode')}*</label>
            {mailing_is_usa === 'yes' ? (
              <>
                <Controller
                  name="mailing_zipcode"
                  control={control}
                  rules={{
                    required: true,
                    maxlength: 10,
                    validate: {
                      zipcode: value => (isValidZip(value) && mailing_is_usa === 'yes') || getContent('validation.mailing_zipcode'),
                    }
                  }}
                  render={({ field }) => {
                    return <InputMask placeholder="12345" className={`input ${errors.mailing_zipcode ? 'error' : ''}`} {...field} mask="99999-????" formatChars={{ 9: '[0-9]', '?': '[0-9]' }} beforeMaskedStateChange={({ nextState }) => { return nextState.value.replace(/\_*$/g, ''); }} />
                  }}
                />
                {errors.mailing_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
                {errors.mailing_zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.mailing_zipcode')}</span>}
              </>
            ) : ( 
              <>
                <Controller
                  name="mailing_zipcode"
                  control={control}
                  rules={{
                    required: true,
                    maxlength: 10,
                    validate: {
                      zipcode: value => true,
                    }
                  }}
                  render={({ field }) => {
                    return <input placeholder="" className={`input ${errors.mailing_zipcode ? 'error' : ''}`} {...field} autoComplete="off" maxLength="10" />
                  }}
                />
                {errors.mailing_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
              </>
            )}
          </div>
          
          {mailing_is_usa === 'no' && 
            <div className="flex">
              <div className="flex-1 p-2">
                <label>{getContent('fields.mailing_country')}*</label>
                <Controller
                  name="mailing_country"
                  control={control}
                  rules={{ required: (mailing_is_usa === 'no' ? true : false) }}
                  render={({ field }) => <input {...field} placeholder="Country" type="text" autoComplete="off" maxLength="40" className={`${errors.mailing_country ? 'error' : ''}`} />}
                />
                {errors.mailing_country?.type === 'required' && <span className="error">{getContent('validation.mailing_country')}</span>}

              </div>
            </div>
          }

          <div className="flex">
            <div className="flex-1 p-2">
              <label>{getContent('fields.mailing_address_is_my')}*</label>
              <Controller
                name="mailing_address_is_my"
                control={control}
                rules={{ required: true, }}
                render={({ field }) => <input {...field} placeholder={getContent('placeholders.mailing_address_is_my')} type="text" autoComplete="off" maxLength="30" className={`${errors.mailing_address_is_my ? 'error' : ''}`} />}
              />
              {errors.mailing_address_is_my?.type === 'required' && <span className="error">{getContent('validation.mailing_address_is_my')}</span>}

            </div>
          </div>
        </div>
      }


      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default VBMMailingAddressForm;