import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidAge, isValidDate, isValidZip } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function ResidentialInformationForm({ submit, cancel }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [municipalities, setMunicipalities] = React.useState([]);

  const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      address_1: '',
      address_2: '',
      address_2_type: '',
      address_2_number: '',
      city: '',
      zipcode: '',
      county: '',
      municipality: '',
      has_mailing_address: false,
      mailing_address_1: '',
      mailing_address_2: '',
      mailing_unit_type: '',
      mailing_unit_number: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zipcode: '',
      mailing_is_usa: 'yes',
      mailing_country: 'United States',
      mailing_address_is_my: '',
    }
  });

  const onSubmit = data => {
    data.mailing_zipcode = data.mailing_zipcode.replace(/\-\_*$/g, '');

    if (registrant.ballot_request_address_type === 'R') {
      data.ballot_request_address_1 = `${data.address_1}${data.address_2_type !== '' ? ', '+data.address_2_type+' '+data.address_2_number : ''}${data.address_2 !== '' ? ', '+data.address_2 : ''}`.substring(0, 40);
      data.ballot_request_city = data.city;
      data.ballot_request_state = 'PA';
      data.ballot_request_zipcode = data.zipcode;
    } else {
      if (registrant.vbm_only) {
        data.ballot_request_address_1 = `${data.mailing_address_1}${data.mailing_unit_type !== '' ? ', '+data.mailing_unit_type+' '+data.mailing_unit_number : ''}${data.mailing_address_2 !== '' ? ', '+data.mailing_address_2 : ''}`.substring(0, 40);
        data.ballot_request_city = data.mailing_city;
        data.ballot_request_state = data.mailing_state;
        data.ballot_request_zipcode = data.mailing_zipcode;
        data.ballot_request_country = data.mailing_country;
      } else {
        data.ballot_request_address_1 = `${data.mailing_address_1}`.substring(0, 40);
        data.ballot_request_city = data.mailing_city;
        data.ballot_request_state = data.mailing_state;
        data.ballot_request_zipcode = data.mailing_zipcode;
      }
    }
    
    submit({
      ...data,
      mailing_country: (data.mailing_is_usa === 'yes' ? 'United States' : data.mailing_country),
      has_mailing_address: data.has_mailing_address ? "yes" : "no"
    })
  };

  const { has_mailing_address, mailing_is_usa } = watch();

  React.useEffect(() => {
    const {
      address_1,
      address_2,
      address_2_type,
      address_2_number,
      city,
      zipcode,
      county,
      municipality,
      mailing_address_1,
      mailing_address_2,
      mailing_unit_type,
      mailing_unit_number,
      mailing_city,
      mailing_state,
      mailing_zipcode,
      mailing_is_usa,
      mailing_country,
      mailing_address_is_my,
      has_mailing_address,
    } = registrant;

    setValue('address_1', address_1);
    setValue('address_2', address_2);
    setValue('address_2_type', address_2_type);
    setValue('address_2_number', address_2_number);
    setValue('city', city);
    setValue('zipcode', zipcode);
    setValue('county', county);
    setValue('municipality', municipality);
    setValue('mailing_address_1', mailing_address_1);
    setValue('mailing_address_2', mailing_address_2);
    setValue('mailing_unit_type', mailing_unit_type);
    setValue('mailing_unit_number', mailing_unit_number);
    setValue('mailing_city', mailing_city);
    setValue('mailing_state', mailing_state);
    setValue('mailing_zipcode', mailing_zipcode);
    setValue('mailing_is_usa', mailing_is_usa);
    setValue('mailing_country', mailing_country);
    setValue('mailing_address_is_my', mailing_address_is_my);
    setValue('has_mailing_address', has_mailing_address === 'yes');

    if (information.counties[county]) {
      setMunicipalities(information.counties[county].municipalities || []);
    }
  }, [registrant]);

  React.useEffect(() => {
    if (registrant.mailing_is_usa === mailing_is_usa) {
      setValue('mailing_state', registrant.mailing_state);
      setValue('mailing_zipcode', registrant.mailing_zipcode);
    } else {
      setValue('mailing_state', '');
      setValue('mailing_zipcode', '');
    }

    if (mailing_is_usa == 'yes') {
      setValue('mailing_country', 'United States');
    } else {
      setValue('mailing_country', registrant.mailing_country);
    }
  }, [mailing_is_usa]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-1 p-2">
        <label>{getContent('fields.street_address')}*</label>
        <Controller
          name="address_1"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.address_1 ? 'error' : ''}`} />}
        />
        {errors.address_1?.type === 'required' && <span className="error">{getContent('validation.no_address')}</span>}
      </div>

      <div className="flex-1 p-2">
        <label>{getContent('fields.street_address_2')}</label>
        <Controller
          name="address_2"
          control={control}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" />}
        />
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.unit_type')}</label>
          <Controller
            name="address_2_type"
            control={control}
            render={({ field }) => {
              return (
                <div className="select">
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.unitTypes).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.unitTypes[key][currentLanguage]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.unit_number')}</label>
          <Controller
            name="address_2_number"
            control={control}
            render={({ field }) => <input {...field} placeholder="40B" type="text" autoComplete="off" maxLength="15" />}
          />
        </div>
      </div>

      <div className="flex">

        <div className="flex-1 p-2">
          <label>{getContent('fields.city')}*</label>
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}

            render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="35" className={`${errors.city ? 'error' : ''}`} />}
          />
          {errors.city?.type === 'required' && <span className="error">{getContent('validation.city')}</span>}

        </div>

        <div className="flex-1 p-2">
          <label>{getContent('fields.zipcode')}*</label>
          <Controller
            name="zipcode"
            control={control}
            rules={{
              required: true,
              maxlength: 5,
              validate: {
                zipcode: value => isValidZip(value) || getContent('validation.no_address_zipcode')
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="12345" className={`input ${errors.zipcode ? 'error' : ''}`} {...field} mask="99999" />
              )
            }}
          />
          {errors.zipcode?.type === 'required' && <span className="error">{getContent('validation.no_address_zipcode')}</span>}
          {errors.zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.no_address_zipcode_valid')}</span>}
        </div>
      </div>


      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.county')}*</label>
          <Controller
            name="county"
            control={control}
            rules={{ required: true, }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.county ? 'error' : ''}`} >
                  <select {...field} onChange={(e) => { field.onChange(e.target.value); setMunicipalities(information.counties[e.target.value].municipalities || []); }}>
                    {Object.keys(information?.counties).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.counties[key].name}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.county?.type === 'required' && <span className="error">{getContent('validation.county')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.municipality')}</label>
          <Controller
            name="municipality"
            control={control}
            render={({ field }) => {
              return (
                <div className={`select ${errors.municipality ? 'error' : ''}`} >
                  <select {...field}>
                    {Object.keys(municipalities).map((key, i) => {
                      return (
                        <option key={i} value={key}>{municipalities[key]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
      </div>
      
      <div className="flex items-center justify-between p-2 px-4 mt-4">
        <Controller
          name="has_mailing_address"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.has_mailing_address == 'yes' ? true : false} /><span></span>{getContent('review.have_mailing_address')}</label>}
        />
      </div>

      {has_mailing_address &&
        <>
          <div className="flex-1 p-2">
            <label>{getContent("fields.mailing_address_in_usa")}*</label>
            <div className="flex items-center justify-between p-2 ">
              <Controller
                name="mailing_is_usa"
                control={control}
                render={({ field }) => <label className="radio">
                    <input {...field} type="radio" value="yes" defaultChecked={registrant.mailing_is_usa == 'yes' ? true : false} />
                    <span></span> <span className="pl-4">{getContent('buttons.yes')}</span>
                  </label>
                }
              />
            </div>
            <div className="flex items-center justify-between p-2 ">
              <Controller
                name="mailing_is_usa"
                control={control}
                render={({ field }) => <label className="radio">
                    <input {...field} type="radio" value="no" defaultChecked={registrant.mailing_is_usa == 'no' ? true : false} />
                    <span></span>  
                    <span className="pl-4">{getContent('buttons.no')}</span>
                  </label>
                }
              />
            </div>
          </div>

          <div className="flex-1 p-2">
            <label>{getContent('fields.mailing_address')}*</label>
            <Controller
              name="mailing_address_1"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.mailing_address_1 ? 'error' : ''}`} />}
            />
            {errors.mailing_address_1?.type === 'required' && <span className="error">{getContent('validation.mailing_address')}</span>}
          </div>

          {registrant.vbm_only && (
            <>
              <div className="flex-1 p-2">
                <label>{getContent('fields.mailing_address_2')}*</label>
                <Controller
                  name="mailing_address_2"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.mailing_address_2 ? 'error' : ''}`} />}
                />
              </div>

              <div className="flex">
                <div className="flex-1 p-2">
                  <label>{getContent('fields.unit_type')}</label>
                  <Controller
                    name="mailing_unit_type"
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className="select">
                          <select {...field}>
                            <option value="">{getContent('placeholders.dropdown')}</option>
                            {Object.keys(information?.unitTypes).map((key, i) => {
                              return (
                                <option key={i} value={key}>{information?.unitTypes[key][currentLanguage]}</option>
                              );
                            })}
                          </select>
                        </div>
                      );
                    }}
                  />
                </div>
                <div className="flex-1 p-2">
                  <label>{getContent('fields.unit_number')}</label>
                  <Controller
                    name="mailing_unit_number"
                    control={control}
                    render={({ field }) => <input {...field} placeholder="40B" type="text" maxLength="8" autoComplete="off" />}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex">
            <div className="flex-1 p-2">
              <label>{getContent('fields.mailing_city')}*</label>
              <Controller
                name="mailing_city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="35" className={`${errors.mailing_city ? 'error' : ''}`} />}
              />
              {errors.mailing_city?.type === 'required' && <span className="error">{getContent('validation.mailing_city')}</span>}

            </div>
            <div className="flex-1 p-2">
              <label>{getContent('fields.mailing_state')}*</label>
              {mailing_is_usa === 'yes' ? (
                <Controller
                  name="mailing_state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <div className={`select ${errors.mailing_state ? 'error' : ''}`} >
                        <select {...field}>
                          <option value="">{getContent('placeholders.dropdown')}</option>
                          {Object.keys(information?.states).map((key, i) => {
                            return (
                              <option key={i} value={key}>{information?.states[key]}</option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  }}
                />
              ) : (
                <Controller
                  name="mailing_state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <input {...field} placeholder="State" type="text" autoComplete="off" maxLength="20" className={`${errors.mailing_state ? 'error' : ''}`} />}
                />
              )}
              {errors.mailing_state?.type === 'required' && <span className="error">{getContent('validation.mailing_state')}</span>}
            </div>
          </div>

          <div className="flex-1 p-2">
            <label>{getContent('fields.mailing_zipcode')}*</label>
            {mailing_is_usa === 'yes' ? (
              <>
                <Controller
                  name="mailing_zipcode"
                  control={control}
                  rules={{
                    required: true,
                    maxlength: 10,
                    validate: {
                      zipcode: value => isValidZip(value) || getContent('validation.zipcode_valid')
                    }
                  }}
                  render={({ field }) => {
                    return (
                      <InputMask placeholder="12345" className={`input ${errors.mailing_zipcode ? 'error' : ''}`} {...field} mask="99999-????" formatChars={{ 9: '[0-9]', '?': '[0-9]' }} beforeMaskedStateChange={({ nextState }) => { return nextState.value.replace(/\_*$/g, ''); }} />
                    )
                  }}
                />
                {errors.mailing_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
                {errors.mailing_zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.zipcode_valid')}</span>}
              </>
            ) : ( 
              <>
                <Controller
                  name="mailing_zipcode"
                  control={control}
                  rules={{
                    required: true,
                    maxlength: 10,
                    validate: {
                      zipcode: value => true,
                    }
                  }}
                  render={({ field }) => {
                    return <input placeholder="" className={`input ${errors.mailing_zipcode ? 'error' : ''}`} {...field} autoComplete="off" maxLength="10" />
                  }}
                />
                {errors.mailing_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
              </>
            )}
          </div>

          {registrant.vbm_only && mailing_is_usa === 'no' && 
            <div className="flex">
              <div className="flex-1 p-2">
                <label>{getContent('fields.mailing_country')}*</label>
                <Controller
                  name="mailing_country"
                  control={control}
                  rules={{ required: (mailing_is_usa === 'no' ? true : false) }}
                  render={({ field }) => <input {...field} placeholder="Country" type="text" autoComplete="off" maxLength="40" className={`${errors.mailing_country ? 'error' : ''}`} />}
                />
                {errors.mailing_country?.type === 'required' && <span className="error">{getContent('validation.mailing_country')}</span>}

              </div>
            </div>
          }

          {registrant.vbm_only && (
            <div className="flex">
              <div className="flex-1 p-2">
                <label>{getContent('fields.mailing_address_is_my')}*</label>
                <Controller
                  name="mailing_address_is_my"
                  control={control}
                  rules={{ required: (mailing_is_usa === 'no' ? true : false) }}
                  render={({ field }) => <input {...field} placeholder={getContent('placeholders.mailing_address_is_my')} type="text" autoComplete="off" maxLength="30" className={`${errors.mailing_address_is_my ? 'error' : ''}`} />}
                />
                {errors.mailing_address_is_my?.type === 'required' && <span className="error">{getContent('validation.mailing_address_is_my')}</span>}

              </div>
            </div>
          )}
        </>

      }
      <div className="flex justify-end pt-8 pb-2">
        <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
        <PrimaryButton submit>{getContent('buttons.update_information')}</PrimaryButton>
      </div>
    </form>
  );
}

export default ResidentialInformationForm;