import React from "react";
import { useNavigate } from "react-router-dom";
import VBMMailingAddressForm from "../Forms/VBMMailingAddressForm";
import { useDispatch } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import BackButton from "../Components/BackButton";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { getContent } from "../content";
import { useSelector } from 'react-redux';

function VBMMailingAddress() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([4, 6]));
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        const {
            ballot_request,
            ballot_request_address_type,
        } = registrant;

        let ballotRequestData = {};
        if (ballot_request === 'yes' && ballot_request_address_type === 'M') {
            ballotRequestData = {
                ballot_request_address_1: `${data.mailing_address_1}${data.mailing_unit_type !== '' ? ', '+data.mailing_unit_type+' '+data.mailing_unit_number : ''}${data.mailing_address_2 !== '' ? ', '+data.mailing_address_2 : ''}`.substring(0, 40),
                ballot_request_city: data.mailing_city,
                ballot_request_state: data.mailing_state,
                ballot_request_zipcode: data.mailing_zipcode,
            }
        }

        dispatch(updateRegistrant({
            ...data,
            ...ballotRequestData
        }));

        navigate('/vbm-type');
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('mailing_address.heading')}</h2>
                <p>{getContent('mailing_address.content')}</p>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-8 border-4 rounded-lg border-pvr-darkblue">
                    <VBMMailingAddressForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default VBMMailingAddress;