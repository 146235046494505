import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    registered: null,
    vbm_only: false,
    check_is_citizen: false,
    check_is_valid_age: false,

    first_name: '',
    last_name: '',
    middle_name: '',
    suffix: '',
    birthdate: '',
    email_address: '',
    email_address_optin: false,
    phone_number: '',
    phone_type: 'cell', //  cell, home, work
    phone_number_optin: false,

    race: '',
    sex: '',

    pa_id_number: '',

    no_address: false,

    address_1: '',
    address_2: '',
    address_2_type: '',
    address_2_number: '',
    city: '',
    state: 'PA',
    zipcode: '',
    county: '',
    municipality: '',
    no_permanent_address: false,

    has_mailing_address: 'no',
    
    mailing_address_1: '',
    mailing_address_2: '',
    mailing_unit_type: '',
    mailing_unit_number: '',
    mailing_city: '',
    mailing_state: '',
    mailing_region: '',
    mailing_zipcode: '',
    mailing_country: 'United States',
    mailing_is_usa: 'yes',
    mailing_address_is_my: '',

    is_interested_poll_worker: '',
    is_interested_interpreter: '',
    interpreter_language: '',

    help_required: '',
    assistance_type: '',
    assistance_language: '',

    confirm_terms: false,
    confirm_terms_assistant: false,

    id_type: null,

    last_four_ssn: '',

    change_name: false,

    previous_first_name: '',
    previous_last_name: '',
    previous_middle_name: '',
    
    change_address: false,

    previous_address_1: '',
    previous_city: '',
    previous_state: '',
    previous_county: '',
    previous_zipcode: '',

    change_party: false,
    party_affiliation: '',
    party_affiliation_other: '',

    federal_state_employee: false,
    
    has_signature: false,
    signature_data: null,
    signature_data_hd: null,

    had_assistance: '',

    assistant_name: '',
    assistant_address_1: '',
    assistant_city: '',
    assistant_state: '',
    assistant_zipcode: '',
    assistant_phone_number: '',
    assistant_signature_data: null,
    assistant_signature_data_hd: null,
    has_proof_of_residence: false,
    proof_of_residence: null,

    ballot_request: '',
    ballot_request_address_type: '',
    ballot_request_address_1: '',
    ballot_request_city: '',
    ballot_request_state: '',
    ballot_request_zipcode: '',
    ballot_request_ward: '',
    ballot_request_lived_since: '',
    ballot_request_annual_request: false,
    ballot_request_confirmed: false,

    survey_question_one: '',
    survey_question_one_answer: '',
    survey_question_two: '',
    survey_question_two_answer: '',
    survey_question_three: '',
    survey_question_three_answer: '',
};

export const registrantSlice = createSlice({
    name: 'registrant',
    initialState: initialState,
    reducers: {
        updateRegistrant: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        clearCurrentRegistrant: () =>  initialState,
    },
});

export const {
    updateRegistrant,
    clearCurrentRegistrant,
} = registrantSlice.actions;

export default registrantSlice.reducer;
