import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    logo_image: '',
    logo_image_dark: '',
    can_register: false,
    can_register_online: false,
    can_register_offline: false,
    no_register_online_message: '',
    no_register_offline_message: '',
    no_register_message: '',
    no_register_online_message_es: '',
    no_register_offline_message_es: '',
    no_register_message_es: '',
    phone_optin_en: '',
    phone_optin_es: '',
    phone_optin_zh: '',
    phone_optin_vi: '',
    email_optin_en: '',
    email_optin_es: '',
    email_optin_zh: '',
    email_optin_vi: '',
    enable_mailin_ballot: false,
    enable_vbm: false,
    survey_question_01: null,
    survey_question_02: null,
    survey_question_03: null,
    status: null,
    urlID: null,
    suburlID: null,
};


export const partnerSlice = createSlice({
    name: 'partner',
    initialState: initialState,
    reducers: {
        updatePartner: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
    },
});

export const {
    updatePartner,
} = partnerSlice.actions;

export default partnerSlice.reducer;
