import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import PrimaryButton from "../Components/PrimaryButton";
import BackButton from "../Components/BackButton";
import edit from '../img/edit.svg';
import Modal from 'react-modal';
import CurrentPersonalInformationForm from "../Forms/CurrentPersonalInformationForm";
import ResidentialInformationForm from "../Forms/ResidentialInformationForm";
import IDForm from "../Forms/IDForm";
import AdditionalInformationForm from "../Forms/AdditionalInformationForm";
import PreviousAddressForm from "../Forms/PreviousAddressForm";
import PreviousNameForm from "../Forms/PreviousNameForm";
import CurrentAddressForm from "../Forms/CurrentAddressForm";
import VBMOnlyForm from "../Forms/VBMOnlyForm";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { getLabel, getCountyName, getMunicipalityName } from '../helpers';
import LastFourEntryForm from "../Forms/LastFourEntryForm";
import { getContent } from "../content";
import VBMTypeForm from "../Forms/VBMTypeForm";

const Section = ({ children }) => {
    return (
        <div className="grid grid-cols-2 gap-8 mb-4">
            {children}
        </div>
    );
};

const Label = ({ children }) => {
    return <div className="tracking-wide uppercase font-GilroyExtraBold">{children}</div>
};

const Value = ({ children }) => {
    return <div className="overflow-hidden text-lg overflow-ellipsis">{children}</div>
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        height: "80%",
    },
};

function ReviewInformation() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);
    const information = useSelector((state) => state.information);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    const [showCurrentPersonalForm, setShowCurrentPersonalForm] = React.useState(false);
    const [showResidentialInformationForm, setShowResidentialInformationForm] = React.useState(false);
    const [showIDForm, setShowIDForm] = React.useState(false);
    const [showSSNForm, setShowSSNForm] = React.useState(false);
    const [showAdditionalInformationForm, setShowAdditionalInformationForm] = React.useState(false);
    const [showPreviousAddressForm, setShowPreviousAddressForm] = React.useState(false);
    const [showPreviousNameForm, setShowPreviousNameForm] = React.useState(false);
    const [showCurrentAddressForm, setShowCurrentAddressForm] = React.useState(false);
    const [showBallotRequestForm, setShowBallotRequestForm] = React.useState(false);

    const [showVBMOnlyForm, setShowVBMOnlyForm] = React.useState(false);

    React.useEffect(() => {
        dispatch(changeStep([5, 6]));
    }, []);

    const nextStep = () => {
        if (registrant.vbm_only) {
            navigate('/ballot-request-confirmation');   
        } else {
            navigate('/add-signature');
        }
    };

    const updateData = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        const {
            ballot_request,
            ballot_request_address_type,
            has_mailing_address
        } = registrant;

        if (data.help_required === 'no') {
            data.assistance_type = '';
            data.assistance_language = '';
        }

        let ballotRequestData = {};
        if (ballot_request === 'yes' && ballot_request_address_type === 'R' && data.address_1) {
            ballotRequestData = {
                ballot_request_address_1: `${data.address_1}${data.address_2 !== '' ? ', '+data.address_2 : ''}${data.address_2_type !== '' ? ', '+data.address_2_type+' '+data.address_2_number : ''}`.substring(0, 40),
                ballot_request_city: data.city,
                ballot_request_state: 'PA',
                ballot_request_zipcode: data.zipcode,
            }
        }

        if (ballot_request === 'yes' && ballot_request_address_type === 'M' && data.mailing_address_1 && has_mailing_address === 'yes') {
            ballotRequestData = {
                ballot_request_address_1: `${data.mailing_address_1}${data.mailing_unit_type !== '' ? ', '+data.mailing_unit_type+' '+data.mailing_unit_number : ''}${data.mailing_address_2 !== '' ? ', '+data.mailing_address_2 : ''}`.substring(0, 40),
                ballot_request_city: data.mailing_city,
                ballot_request_state: data.mailing_state,
                ballot_request_zipcode: data.mailing_zipcode,
            }
        }

        dispatch(updateRegistrant({
            ...data,
            ...ballotRequestData
        }));

        setShowCurrentPersonalForm(false);
        setShowResidentialInformationForm(false);
        setShowIDForm(false);
        setShowSSNForm(false);
        setShowAdditionalInformationForm(false)
        setShowPreviousAddressForm(false)
        setShowPreviousNameForm(false)
        setShowCurrentAddressForm(false)
        setShowBallotRequestForm(false)

        setShowVBMOnlyForm(false);
    };

    const showModal = () => {
        return showCurrentPersonalForm ||
            showResidentialInformationForm ||
            showIDForm ||
            showSSNForm || 
            showAdditionalInformationForm ||
            showPreviousAddressForm ||
            showPreviousNameForm ||
            showCurrentAddressForm || 
            showBallotRequestForm ||
            showVBMOnlyForm;
    };

    return (
        <>
            <section className="grid-cols-12 gap-4 lg:grid">
                <div className="col-span-4 col-start-1">
                    <BackButton onClick={() => navigate(-1)} />
                    <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('review.heading')}</h2>
                    <p>{getContent('review.content')}</p>
                </div>
                <div className="col-span-7 col-start-6">

                    {/* Current Personal Information */}
                    {registrant.vbm_only &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowVBMOnlyForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>
                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.personal_info_heading')}</h4>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.first_name')}</Label>
                                    <Value>{registrant?.first_name || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.last_name')}</Label>
                                    <Value>{registrant?.last_name || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.middle_name')}</Label>
                                    <Value>{registrant?.middle_name !== '' ? registrant?.middle_name : getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.suffix')}</Label>
                                    <Value>{getLabel(registrant?.suffix, information?.suffixes, currentLanguage)}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.birth_date')}</Label>
                                    <Value>{registrant?.birthdate || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.phone_number')}</Label>
                                    <Value>{registrant?.phone_number !== '' ? registrant?.phone_number : getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.email_address')}</Label>
                                    <Value>{registrant?.email_address !== '' ? registrant?.email_address : getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('review.phone_optin')}</Label>
                                    <Value>{registrant?.phone_number_optin ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('review.email_optin')}</Label>
                                    <Value>{registrant?.email_address_optin ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                            </Section>

                        </div>
                    }
                    {!registrant.vbm_only &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowCurrentPersonalForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>
                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.personal_info_heading')}</h4>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.first_name')}</Label>
                                    <Value>{registrant?.first_name || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.last_name')}</Label>
                                    <Value>{registrant?.last_name || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.middle_name')}</Label>
                                    <Value>{registrant?.middle_name !== '' ? registrant?.middle_name : getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.suffix')}</Label>
                                    <Value>{getLabel(registrant?.suffix, information?.suffixes, currentLanguage)}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.birth_date')}</Label>
                                    <Value>{registrant?.birthdate || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.sex')}</Label>
                                    <Value>{getLabel(registrant?.sex, information?.genders, currentLanguage)}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.race')}</Label>
                                    <Value>{getLabel(registrant?.race, information?.races, currentLanguage)}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.political_party')}</Label>
                                    <Value>{getLabel(registrant?.party_affiliation, information?.politicalParties, currentLanguage)} {registrant.party_affiliation === 'OTH' ? ' - ' + registrant.party_affiliation_other : ''}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.phone_number')}</Label>
                                    <Value>{registrant?.phone_number !== '' ? registrant?.phone_number : getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.email_address')}</Label>
                                    <Value>{registrant?.email_address !== '' ? registrant?.email_address : getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('review.phone_optin')}</Label>
                                    <Value>{registrant?.phone_number_optin ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('review.email_optin')}</Label>
                                    <Value>{registrant?.email_address_optin ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                            </Section>

                        </div>
                    }

                    {/* Residental Information */}
                    <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                        <button type="button" onClick={() => setShowResidentialInformationForm(true)} className="absolute block top-8 right-8">
                            <img src={edit} className="w-6 h-6" alt="edit" />
                        </button>

                        <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.residential_heading')}</h4>

                        <Section>
                            <div>
                                <Label>{getContent('fields.street_address')}</Label>
                                <Value>{registrant?.address_1 || getContent('fields.na')}</Value>
                            </div>
                            <div>
                                <Label>{getContent('fields.street_address_2')}</Label>
                                <Value>{registrant?.address_2 || getContent('fields.na')}</Value>
                            </div>
                        </Section>

                        <Section>
                            <div>
                                <Label>{getContent('fields.unit_type')}</Label>
                                <Value>{getLabel(registrant?.address_2_type, information?.unitTypes, currentLanguage)}</Value>
                            </div>
                            <div>
                                <Label>{getContent('fields.unit_number')}</Label>
                                <Value>{registrant?.address_2_number || getContent('fields.na')}</Value>
                            </div>
                        </Section>

                        <Section>
                            <div>
                                <Label>{getContent('fields.city')}</Label>
                                <Value>{registrant?.city || getContent('fields.na')}</Value>
                            </div>
                            <div>
                                <Label>{getContent('fields.zipcode')}</Label>
                                <Value>{registrant?.zipcode || getContent('fields.na')}</Value>
                            </div>
                        </Section>

                        <Section>
                            <div>
                                <Label>{getContent('fields.county')}</Label>
                                <Value>{getCountyName(registrant?.county, information?.counties)}</Value>
                            </div>
                            <div>
                                <Label>{getContent('fields.municipality')}</Label>
                                <Value>{getMunicipalityName(registrant?.municipality, registrant?.county, information?.counties)}</Value>
                            </div>
                        </Section>
                        {registrant.has_mailing_address == 'yes' &&
                            <>
                                <Section>
                                    <div>
                                        <Label>{getContent('fields.mailing_address')}</Label>
                                        <Value>{registrant?.mailing_address_1 || getContent('fields.na')}</Value>
                                    </div>
                                    {registrant.vbm_only && (
                                        <div>
                                            <Label>{getContent('fields.mailing_address_2')}</Label>
                                            <Value>{registrant?.mailing_address_2 || getContent('fields.na')}</Value>
                                        </div>
                                    )}
                                    {!registrant.vbm_only && (
                                        <div></div>
                                    )}
                                </Section>

                                {registrant.vbm_only && (
                                    <Section>
                                        <div>
                                            <Label>{getContent('fields.unit_type')}</Label>
                                            <Value>{getLabel(registrant?.mailing_unit_type, information?.unitTypes, currentLanguage)}</Value>
                                        </div>
                                        <div>
                                            <Label>{getContent('fields.unit_number')}</Label>
                                            <Value>{registrant?.mailing_unit_number || getContent('fields.na')}</Value>
                                        </div>
                                    </Section>
                                )}

                                <Section>
                                    <div>
                                        <Label>{getContent('fields.mailing_city')}</Label>
                                        <Value>{registrant?.mailing_city || getContent('fields.na')}</Value>
                                    </div>
                                    <div>
                                        <Label>{getContent('fields.mailing_state')}</Label>
                                        <Value>{registrant?.mailing_state || getContent('fields.na')}</Value>
                                    </div>
                                </Section>

                                <div className="mb-4">
                                    <div>
                                        <Label>{getContent('fields.mailing_zipcode')}</Label>
                                        <Value>{registrant?.mailing_zipcode || getContent('fields.na')}</Value>
                                    </div>
                                </div>

                                {registrant.vbm_only && (
                                    <>
                                        <div className="mb-4">
                                            <div>
                                                <Label>{getContent('fields.mailing_country')}</Label>
                                                <Value>{registrant?.mailing_country || getContent('fields.na')}</Value>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div>
                                                <Label>{getContent('fields.mailing_address_is_my')}</Label>
                                                <Value>{registrant?.mailing_address_is_my || getContent('fields.na')}</Value>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        }
                    </div>

                    {/* Previous Name */}
                    {registrant.change_name &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowPreviousNameForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>
                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.prev_name_heading')}</h4>


                            <Section>
                                <div>
                                    <Label>{getContent('fields.first_name')}</Label>
                                    <Value>{registrant?.previous_first_name || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.last_name')}</Label>
                                    <Value>{registrant?.previous_last_name || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <label>{getContent('fields.middle_name')}</label>
                                    <Value>{registrant?.previous_middle_name || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                        </div>
                    }

                    {/* Previous Address */}
                    {registrant.change_address &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowPreviousAddressForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>

                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.prev_address_heading')}</h4>

                            <div className="mb-4">
                                <Label>{getContent('fields.street_address')}</Label>
                                <Value>{registrant?.previous_address_1 || getContent('fields.na')}</Value>
                            </div>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.city')}</Label>
                                    <Value>{registrant?.previous_city || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.state')}</Label>
                                    <Value>{registrant?.previous_state || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.county')}</Label>
                                    <Value>{getCountyName(registrant?.previous_county, information?.counties, currentLanguage)}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.zipcode')}</Label>
                                    <Value>{registrant?.previous_zipcode || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                        </div>
                    }

                    {/* PA Driver's License or Pennot ID */}
                    {registrant.id_type === 'on_person' &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowIDForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>

                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.license_heading')}</h4>
                            <div className="mb-4">
                                <Label>{getContent('fields.license_id')}</Label>
                                <Value>{registrant?.pa_id_number || getContent('fields.na')}</Value>
                            </div>

                        </div>
                    }

                    {/* SSN */}
                    {(registrant.id_type === 'on_person' || registrant.id_type === 'have_ssn') && !registrant.vbm_only &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowSSNForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>

                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.ssn_heading')}</h4>
                            <div className="mb-4">
                                <Label>{getContent('fields.last_four_ssn')}</Label>
                                <Value>{`XXX-XX-${registrant?.last_four_ssn}` || getContent('fields.na')}</Value>
                            </div>

                        </div>
                    }

                    {/* Additional Information */}
                    {!registrant.vbm_only && (
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowAdditionalInformationForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>

                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.additional_info_heading')}</h4>

                            <Section>
                                <div>
                                    <Label>{getContent('review.poll_worker')}</Label>
                                    <Value>{registrant?.is_interested_poll_worker == 'yes' ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('review.bilingual_interpreter')}</Label>
                                    <Value>{registrant?.is_interested_interpreter == 'yes' ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                            </Section>


                            <Section>
                                <div>
                                    <Label>{getContent('review.languages')}</Label>
                                    <Value>{registrant?.interpreter_language || getContent('fields.na')}</Value>
                                </div>
                                <div></div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('review.requires_help_to_vote')}</Label>
                                    <Value>{registrant?.help_required == 'yes' ? getContent('buttons.yes') : getContent('buttons.no')}</Value>
                                </div>
                                <div></div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('review.assistance_needed')}</Label>
                                    <Value>{getLabel(registrant?.assistance_type, information?.assistanceTypes, currentLanguage)}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('review.preferred_language')}</Label>
                                    <Value>{registrant?.assistance_language || getContent('fields.na')}</Value>
                                </div>
                            </Section>


                        </div>
                    )}

                    {/* Mail-in Ballot request */}
                    {registrant.vbm_only &&
                        <div className="relative p-8 mb-10 border-4 rounded-lg border-pvr-darkblue">
                            <button type="button" onClick={() => setShowBallotRequestForm(true)} className="absolute block top-8 right-8">
                                <img src={edit} className="w-6 h-6" alt="edit" />
                            </button>

                            <h4 className="mb-8 tracking-wide uppercase text-pvr-orange">{getContent('review.ballot_request')}</h4>

                            <div className="mb-4">
                                <Label>{getContent('fields.ballot_mailing_address')}</Label>
                                <Value>{registrant?.ballot_request_address_1 || getContent('fields.na')}</Value>
                            </div>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.ballot_mailing_city')}</Label>
                                    <Value>{registrant?.ballot_request_city || getContent('fields.na')}</Value>
                                </div>
                                <div>
                                    <Label>{getContent('fields.ballot_mailing_state')}</Label>
                                    <Value>{registrant?.ballot_request_state || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.ballot_mailing_zipcode_short')}</Label>
                                    <Value>{registrant?.ballot_request_zipcode || getContent('fields.na')}</Value>
                                </div>
                                <div className="mb-4">
                                    <Label>{getContent('fields.ballot_mailing_country')}</Label>
                                    <Value>{registrant?.ballot_request_country || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                            <Section>
                                <div>
                                    <Label>{getContent('fields.ballot_mailing_lived_since_short')}</Label>
                                    <Value>{registrant?.ballot_request_lived_since || getContent('fields.na')}</Value>
                                </div>
                            </Section>

                        </div>
                    }

                    <div className="flex justify-end py-2">
                        <PrimaryButton onClick={nextStep}>{getContent('buttons.next_step')}</PrimaryButton>
                    </div>
                </div>
            </section>

            <Modal
                isOpen={showModal()}
                ariaHideApp={false}
                style={customStyles}
            >
                {showCurrentPersonalForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.personal_info_heading')}</h4>
                        <CurrentPersonalInformationForm submit={updateData} cancel={() => setShowCurrentPersonalForm(false)} />
                    </div>
                }
                {showResidentialInformationForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.residential_heading')}</h4>
                        <ResidentialInformationForm submit={updateData} cancel={() => setShowResidentialInformationForm(false)} />
                    </div>
                }
                {showIDForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.license_heading')}</h4>
                        <IDForm submit={updateData} cancel={() => setShowIDForm(false)} />
                    </div>
                }
                {showSSNForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.ssn_heading')}</h4>
                        <LastFourEntryForm submit={updateData} submitText={getContent('buttons.update_information')} cancel={() => setShowSSNForm(false)} />
                    </div>
                }
                {showAdditionalInformationForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.additional_info_heading')}</h4>
                        <AdditionalInformationForm submit={updateData} cancel={() => setShowAdditionalInformationForm(false)} />
                    </div>
                }
                {showPreviousNameForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.prev_name_heading')}</h4>
                        <PreviousNameForm submitText={getContent('buttons.update_information')} submit={updateData} cancel={() => setShowPreviousNameForm(false)} />
                    </div>
                }
                {showPreviousAddressForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.prev_address_heading')}</h4>
                        <PreviousAddressForm submitText={getContent('buttons.update_information')} submit={updateData} cancel={() => setShowPreviousAddressForm(false)} />
                    </div>
                }
                {showBallotRequestForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.ballot_request')}</h4>
                        <VBMTypeForm isUpdateForm={true} hideYesNo={true} submitText={getContent('buttons.update_information')} submit={updateData} cancel={() => setShowBallotRequestForm(false)} />
                    </div>
                }


                {showVBMOnlyForm &&
                    <div className="mt-6">
                        <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('review.personal_info_heading')}</h4>
                        <VBMOnlyForm isUpdateForm={true} submit={updateData} cancel={() => setShowVBMOnlyForm(false)} />
                    </div>
                }
            </Modal>
        </>

    );
}

export default ReviewInformation;