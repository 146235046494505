import React from "react";
import Modal from 'react-modal';
import PrimaryButton from "../Components/PrimaryButton";
import SecondaryButton from "../Components/SecondaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { updateTracking } from "../store/tracking/trackingSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";

function Home() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const partner = useSelector((state) => state.partner);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showIDRequiredModal, setShowIDRequiredModal] = React.useState(false);

  React.useEffect(() => {
    dispatch(changeStep([1, 6]));
  }, []);

  const nextStep = (registered) => {
    const source = searchParams.get('source') ?? null;
    const value = searchParams.get('tracking') ?? null;
    const referrer = document.referrer;

    dispatch(updateTracking({
      ...(source !== null ? {source: source} : {}),
      ...(value !== null ? {value: value} : {}),
      ...(referrer !== null ? {referrer: referrer} : {}),
    }));

    if (!registered) {
      dispatch(updateRegistrant({
        registered: false,
        vbm_only: false,
      }));

      navigate("/new-registration");
    } else {
      dispatch(updateRegistrant({
        registered: true,
        vbm_only: false,
      }));

      navigate("/update-registration");
    }
  };

  const vbmOnly = () => {
    const source = searchParams.get('source') ?? null;
    const value = searchParams.get('tracking') ?? null;
    const referrer = document.referrer;

    dispatch(updateTracking({
      ...(source !== null ? {source: source} : {}),
      ...(value !== null ? {value: value} : {}),
      ...(referrer !== null ? {referrer: referrer} : {}),
    }));

    setShowIDRequiredModal(true);
  };

  const vbmNextStep = () => {
    dispatch(updateRegistrant({
      vbm_only: true,
    }));

    navigate("/vbm-only");
  };

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
    },
  };


  return (
    <section className="flex flex-col justify-center h-full">
      {partner.status !== null ? 
        partner.can_register === true ?
          partner.status === 'error' ? 
            <div className="w-full text-center">
              <h2 className="max-w-xl mx-auto my-4">An error has occurred. Please check the link provided or <a href="https://pavoteforchange.freshdesk.com/support/tickets/new">contact support</a>.</h2>
            </div>
          :
            <>
              <div className="w-full text-center">
                <h2 className="max-w-xl mx-auto my-4">{getContent('home.heading')}</h2>
                <h2 className="max-w-xl mx-auto my-4">{getContent('home.content')}</h2>
                <PrimaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => nextStep(false)} large>{getContent('buttons.new_registrant')}</PrimaryButton>
                <PrimaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => nextStep(true)} large>{getContent('buttons.update_registration')}</PrimaryButton>
                <br />
                {partner['enable_vbm'] && (
                  <PrimaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => vbmOnly()} large>{getContent('buttons.vbm_only')}</PrimaryButton>
                )}
              </div>


              <Modal
                isOpen={showIDRequiredModal}
                ariaHideApp={false}
                style={customStyles}
              >
                <div className="mt-6">
                    <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('home.id_required_heading')}</h4>
                    <p>{getContent('home.id_required_body')}</p>
                    <div className="w-full my-6 text-center md:flex md:flex-row-reverse md:justify-center">
                      <SecondaryButton className="max-w-xs my-4 md:m-4" onClick={() => setShowIDRequiredModal(false)}>{getContent('buttons.cancel')}</SecondaryButton>
                      <PrimaryButton submit className="max-w-xs my-4 md:m-4" onClick={() => vbmNextStep()}>{getContent('buttons.continue')}</PrimaryButton>
                    </div>
                </div>
              </Modal>
            </>
          :
          <div className="w-full text-center">
            <h2 className="max-w-xl mx-auto my-4">Registration is not currently available.</h2>
          </div>
        :
        <></>
      }
    </section>
  );
}

export default Home;
