import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import BallotRequestConfirmationForm from "../Forms/BallotRequestConfirmationForm";
import BackButton from "../Components/BackButton";
import { generateRegistrationID, createFormPayload, createImagePayload, sendSurveyData, getPublicKey } from '../services/api/voter';
import Bugsnag from '@bugsnag/js';
import { getContent } from "../content";

function BallotRequestConfirmation() {
    const partner = useSelector((state) => state.partner);
    const registrant = useSelector((state) => state.registrant);
    const information = useSelector((state) => state.information);
    const tracking = useSelector((state) => state.tracking);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([5, 6]));
    }, []);

    const onSubmit = async (data) => {
        dispatch(updateRegistrant({
            ballot_request_confirmed: data.ballot_request_confirmed
        }));

        let formPayload = {
            ...registrant,
            ballot_request_confirmed: data.ballot_request_confirmed,
        };

        if (registrant.vbm_only) {
            setIsSubmitting(true);

            try {
                const responseID = generateRegistrationID();
                const pubKey = await getPublicKey();
          
                const encryptedResult = createFormPayload(formPayload, tracking, "vbm_only", currentLanguage, pubKey.public_key);

                let formRequest = sendSurveyData(responseID, null, partner.urlID, partner.suburlID, "form", encryptedResult.key, encryptedResult.payload).then(() => {
                    return true;
                }).catch((error) => {
                    return false;
                });
          
                const result = await Promise.all([formRequest]);
                setIsSubmitting(false);

                if (result.includes(false)) {
                    alert('An error occurred while saving your registration. Please try again.');
                } else {
                    navigate('/vbm-confirmation');
                }
            } catch (e) {
                Bugsnag.notify(e);
                alert("An error occurred while submitting the form.  Please try again.");
                
                setIsSubmitting(false);
            }   
        } else {
            navigate('/had-assistance');
        }
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('ballot_request.confirmation_heading')}</h2>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-8 border-4 rounded-lg border-pvr-darkblue" dangerouslySetInnerHTML={{ __html: information.mailinDeclaration[currentLanguage] }}></div>
                <BallotRequestConfirmationForm submit={onSubmit} disabled={isSubmitting} />
            </div>
        </section>
    );
}

export default BallotRequestConfirmation;