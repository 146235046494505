import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../Components/BackButton";
import VBMIDEntryForm from "../Forms/VBMIDEntryForm";
import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function VBMIDEntry() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([3, 6]));
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        if (data.pa_id_number === '' || data.pa_id_number === null) {
            data['id_type'] = 'no_id_ssn';
        } else {
            data['id_type'] = 'on_person';
        }

        dispatch(updateRegistrant({
            ...data
        }));

        //navigate("/party-affiliation");
        navigate('/vbm-mailing-address');
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:my-8 lg:mt-16">{getContent('id_entry.heading')}</h2>
                <p>{getContent('id_entry.content')}</p>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-8 border-4 rounded-lg border-pvr-darkblue">
                    <VBMIDEntryForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default VBMIDEntry;