import React from "react";
import Modal from 'react-modal';
import SecondaryButton from "../Components/SecondaryButton";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidName, isValidAge, isValidDate, isValidZip, isValidIDNumber } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function VBMIDEntryForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [municipalities, setMunicipalities] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      middle_name: '',
      suffix: '',
      birthdate: '',
      address_1: '',
      address_2: '',
      address_2_type: '',
      address_2_number: '',
      city: '',
      zipcode: '',
      county: '',
      municipality: '',
      pa_id_number: '',
    }
  });

  React.useEffect(() => {
    const {
        first_name,
        last_name,
        middle_name,
        suffix,
        birthdate,
        address_1,
        address_2,
        address_2_type,
        address_2_number,
        city,
        zipcode,
        county,
        municipality,
        pa_id_number,
    } = registrant;

    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('middle_name', middle_name);
    setValue('suffix', suffix);
    setValue('birthdate', birthdate);
    setValue('address_1', address_1);
    setValue('address_2', address_2);
    setValue('address_2_type', address_2_type);
    setValue('address_2_number', address_2_number);
    setValue('city', city);
    setValue('zipcode', zipcode);
    setValue('county', county);
    setValue('municipality', municipality);
    setValue('pa_id_number', pa_id_number);

    if (information.counties[county]) {
      setMunicipalities(information.counties[county].municipalities || []);
    }
}, [registrant]);

  const onSubmit = (data) => {
    submit(data);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex ">
          <div className="flex-1 p-2">
            <label>{getContent('fields.first_name')}*</label>
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: true,
                validate: {
                  name: value => !value || (value && isValidName(value) || getContent('validation.first_name'))
                }
              }}
              render={({ field }) => <input {...field} placeholder="Jane" type="text" autoComplete="off" maxLength="30" className={`${errors.first_name ? 'error' : ''}`} />}
            />
            {errors.first_name?.type === 'required' && <span className="error">{getContent('validation.first_name')}</span>}
            {errors.first_name?.type === 'name' && <span className="error">{getContent('validation.first_name')}</span>}
          </div>
          <div className="flex-1 p-2">
            <label>{getContent('fields.last_name')}*</label>
            <Controller
              name="last_name"
              control={control}
              rules={{
                required: true,
                validate: {
                  name: value => !value || (value && isValidName(value) || getContent('validation.last_name'))
                }
              }}
              render={({ field }) => <input {...field} placeholder="Doe" type="text" autoComplete="off" maxLength="30" className={`${errors.last_name ? 'error' : ''}`} />}
            />
            {errors.last_name?.type === 'required' && <span className="error">{getContent('validation.last_name')}</span>}
            {errors.last_name?.type === 'name' && <span className="error">{getContent('validation.last_name')}</span>}
          </div>
        </div>

        <div className="flex ">
          <div className="flex-1 p-2">
            <label>{getContent('fields.middle_name')}</label>
            <Controller
              name="middle_name"
              control={control}
              rules={{
                validate: {
                  name: value => !value || (value && isValidName(value) || getContent('validation.middle_name'))
                }
              }}
              render={({ field }) => <input {...field} placeholder="Henry" maxLength="30" type="text" autoComplete="off" className={`${errors.middle_name ? 'error' : ''}`} />}
            />
            {errors.middle_name?.type === 'name' && <span className="error">{getContent('validation.middle_name')}</span>}
          </div>
          <div className="flex-1 p-2">
            <label>{getContent('fields.suffix')}</label>
            <Controller
              name="suffix"
              control={control}
              render={({ field }) => {
                return (
                  <div className="select">
                    <select {...field}>
                      <option value="">{getContent('placeholders.dropdown')}</option>
                      {Object.keys(information?.suffixes).map((key, i) => {
                        return (
                          <option key={i} value={key}>{information?.suffixes[key][currentLanguage]}</option>
                        );
                      })}
                    </select>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex-1 p-2">
            <label>{getContent('fields.birth_date')}*</label>
            <Controller
              name="birthdate"
              control={control}
              rules={{
                required: true,
                validate: {
                  dob: value => (isValidDate(value) && isValidAge(value)) || getContent('validation.birth_date_valid')
                }
              }}
              render={({ field }) => {
                return (
                  <InputMask placeholder="mm/dd/yyyy" className={`input ${errors.birthdate ? 'error' : ''}`} {...field} mask="99/99/9999" />
                );
              }}
            />
            {errors.birthdate?.type === 'required' && <span className="error">{getContent('validation.birth_date')}</span>}
            {errors.birthdate?.type === 'dob' && <span className="error">{getContent('validation.birth_date_valid')}</span>}
          </div>
        </div>

        <div className="flex-1 p-2">
          <label>{getContent('fields.license_id')}*</label>
          <Controller
            name="pa_id_number"
            control={control}
            rules={{
              required: true,
              validate: {
                license: value => isValidIDNumber(value) || getContent('validation.license_id_valid')
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="12 345 678" className={`input ${errors.pa_id_number ? 'error' : ''}`} {...field} mask="99 999 999" />
              )
            }}
          />
          {errors.pa_id_number?.type === 'required' && <span className="error">{getContent('validation.id')}</span>}
          {errors.pa_id_number?.type === 'license' && <span className="error">{getContent('validation.license_id_valid')}</span>}
        </div>

        <div className="flex-1 p-2">
          <label>{getContent('fields.street_address')}*</label>
          <Controller
            name="address_1"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" maxLength="50" autoComplete="off" className={`${errors.address_1 ? 'error' : ''}`} />}
          />
          {errors.address_1?.type === 'required' && <span className="error">{getContent('validation.street_address')}</span>}
        </div>

        <div className="flex-1 p-2">
          <label>{getContent('fields.street_address_2')}</label>
          <Controller
            name="address_2"
            control={control}
            render={({ field }) => <input {...field} placeholder="555 Oak St." maxLength="25" type="text" autoComplete="off" />}
          />
        </div>

        <div className="flex">
          <div className="flex-1 p-2">
            <label>{getContent('fields.unit_type')}</label>
            <Controller
              name="address_2_type"
              control={control}
              render={({ field }) => {
                return (
                  <div className="select">
                    <select {...field}>
                      <option value="">{getContent('placeholders.dropdown')}</option>
                      {Object.keys(information?.unitTypes).map((key, i) => {
                        return (
                          <option key={i} value={key}>{information?.unitTypes[key][currentLanguage]}</option>
                        );
                      })}
                    </select>
                  </div>
                );
              }}
            />
          </div>
          <div className="flex-1 p-2">
            <label>{getContent('fields.unit_number')}</label>
            <Controller
              name="address_2_number"
              control={control}
              render={({ field }) => <input {...field} placeholder="40B" type="text" maxLength="8" autoComplete="off" />}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex-1 p-2">
            <label>{getContent('fields.city')}*</label>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <input {...field} placeholder="City" type="text" maxLength="18" autoComplete="off" className={`${errors.city ? 'error' : ''}`} />}
            />
            {errors.city?.type === 'required' && <span className="error">{getContent('validation.city')}</span>}
          </div>

          <div className="flex-1 p-2">
            <label>{getContent('fields.zipcode')}*</label>
            <Controller
              name="zipcode"
              control={control}
              rules={{
                required: true,
                maxlength: 5,
                validate: {
                  zipcode: value => isValidZip(value) || getContent('validation.zipcode_valid')
                }
              }}
              render={({ field }) => {
                return (
                  <InputMask placeholder="12345" className={`input ${errors.zipcode ? 'error' : ''}`} {...field} mask="99999" />
                )
              }}
            />
            {errors.zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
            {errors.zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.zipcode_valid')}</span>}
          </div>
        </div>

        <div className="flex">
          <div className="flex-1 p-2">
            <label>{getContent('fields.county')}*</label>
            <Controller
              name="county"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <div className={`select ${errors.county ? 'error' : ''}`} >
                    <select {...field} onChange={(e) => { field.onChange(e.target.value); setMunicipalities(information.counties[e.target.value].municipalities || []); }}>
                      {Object.keys(information?.counties).map((key, i) => {
                        return (
                          <option key={i} value={key}>{information?.counties[key].name}</option>
                        );
                      })}
                    </select>
                  </div>
                );
              }}
            />
            {errors.county?.type === 'required' && <span className="error">{getContent('validation.county')}</span>}
          </div>
          <div className="flex-1 p-2">
            <label>{getContent('fields.municipality')}</label>
            <Controller
              name="municipality"
              control={control}
              render={({ field }) => {
                return (
                  <div className={`select ${errors.municipality ? 'error' : ''}`} >
                    <select {...field}>
                      {Object.keys(municipalities).map((key, i) => {
                        return (
                          <option key={i} value={key}>{municipalities[key]}</option>
                        );
                      })}
                    </select>
                  </div>
                );
              }}
            />
          </div>
        </div>
        
        <div className="flex justify-end pt-8 pb-2">
          <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
        </div>
      </form>
    </>
  );
}

export default VBMIDEntryForm;