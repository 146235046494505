import base64 from 'base-64';
import forge from 'node-forge'
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from '@bugsnag/js';

export const getPublicKey = () => {
    return fetch(`${process.env.REACT_APP_API_BASE}voter/registration/key?canvasser_id=${process.env.REACT_APP_CANVASSER_ID}`).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json();
    }).catch((error) => {
        console.log(error);
    });
}

export const createFormPayload = (formData, trackingData, registrationType, language, pubkey) => {
    let registrationFormData = JSON.parse(JSON.stringify(formData));

    delete registrationFormData.signature_data;
    delete registrationFormData.signature_data_hd;
    delete registrationFormData.assistant_signature_data;
    delete registrationFormData.assistant_signature_data_hd;
    delete registrationFormData.proof_of_residence;

    let encrypted_result = {
        key: '',
        payload: '',
    };
    let result = {
        type: registrationType,
        version: '0.0.1',
        language: language,
        latitude: '',
        longitude: '',
        platform: 'website',
        date_collected: new Date().toISOString(),
        registration_form: {
            profile: {
                first_name: registrationFormData.first_name,
                last_name: registrationFormData.last_name,
                middle_name: registrationFormData.middle_name,
                suffix: registrationFormData.suffix,
                dob_month: registrationFormData.birthdate.substring(0, 2),
                dob_day: registrationFormData.birthdate.substring(3, 5),
                dob_year: registrationFormData.birthdate.substring(6),
                race: registrationFormData.race,
                sex: registrationFormData.sex,
                last_four_ssn: registrationFormData.last_four_ssn,
                license_id: registrationFormData.pa_id_number,
                political_party: registrationFormData.party_affiliation,
                political_party_other: registrationFormData.party_affiliation_other,
                signature_confirmed: registrationFormData.confirm_terms,
            },
            questions: {
                poll_worker_interest: (registrationFormData.is_interested_poll_worker === 'yes' ? true : false),
                bilingual_interest: (registrationFormData.is_interested_interpreter === 'yes' ? true : false),
                bilingual_language: registrationFormData.interpreter_language,
                require_assistance: (registrationFormData.help_required === 'yes' ? true : false),
                assistance_type: registrationFormData.assistance_type,
                assistance_language: registrationFormData.assistance_language,
            },
            contact: {
                email: registrationFormData.email_address,
                email_optin: registrationFormData.email_address_optin,
                phone: registrationFormData.phone_number,
                phone_type: registrationFormData.phone_type,
                phone_optin: registrationFormData.phone_number_optin,
            },
            registration_details: {
                change_name: registrationFormData.change_name,
                change_address: registrationFormData.change_address,
                change_party: registrationFormData.change_party,
                is_federal_employee: registrationFormData.federal_state_employee,
                vbm_only: registrationFormData.vbm_only,
                is_us_citizen: registrationFormData.check_is_citizen,
                is_valid_age: registrationFormData.check_is_valid_age,
                has_license: registrationFormData.id_type,
                voter_registration_number: null,
                voter_previous_year: null,
            },
            current_address: {
                address_1: registrationFormData.address_1,
                address_2: registrationFormData.address_2,
                unit_number: registrationFormData.address_2_number,
                unit_type: registrationFormData.address_2_type,
                city: registrationFormData.city,
                state: registrationFormData.state,
                zipcode: registrationFormData.zipcode,
                county: registrationFormData.county,
                municipality: registrationFormData.municipality,
                no_permanent_address: registrationFormData.no_permanent_address,
            },
            mailing_address: {
                has_mailing_address: (registrationFormData.has_mailing_address === 'yes' ? true : false),
                address_1: registrationFormData.mailing_address_1,
                address_2: registrationFormData.mailing_address_2,
                unit_number: registrationFormData.mailing_unit_number,
                unit_type: registrationFormData.mailing_unit_type,
                city: registrationFormData.mailing_city,
                state: registrationFormData.mailing_state,
                zipcode: registrationFormData.mailing_zipcode,
                county: '',
                municipality: '',
                is_usa: (registrationFormData.mailing_is_usa === 'yes' ? true : false),
                country: registrationFormData.mailing_country,
                mailing_address_is_my: registrationFormData.mailing_address_is_my,
            },
            assistance: {
                had_assistance: (registrationFormData.had_assistance === 'yes' ? true : false),
                assistant_name: registrationFormData.assistant_name,
                assistant_phone: registrationFormData.assistant_phone_number,
                address_1: registrationFormData.assistant_address_1,
                address_2: '',
                unit_number: '',
                unit_type: '',
                city: registrationFormData.assistant_city,
                state: registrationFormData.assistant_state,
                zipcode: registrationFormData.assistant_zipcode,
                county: '',
                municipality: '',
                signature_confirmed: registrationFormData.confirm_terms_assistant,
            },
            previous_name: {
                first_name: registrationFormData.previous_first_name,
                last_name: registrationFormData.previous_last_name,
                middle_name: registrationFormData.previous_middle_name,
            },
            previous_address: {
                address_1: registrationFormData.previous_address_1,
                address_2: '',
                unit_number: '',
                unit_type: '',
                city: registrationFormData.previous_city,
                state: registrationFormData.previous_state,
                zipcode: registrationFormData.previous_zipcode,
                county: registrationFormData.previous_county,
                municipality: '',
            },
            mailin_ballot_request: {
                is_mailin_request: registrationFormData.ballot_request.toLowerCase() === 'yes' ? true : false,
                mailin_address_type: registrationFormData.ballot_request_address_type,
                address_1: registrationFormData.ballot_request_address_1,
                city: registrationFormData.ballot_request_city,
                state: registrationFormData.ballot_request_state,
                zipcode: registrationFormData.ballot_request_zipcode,
                ward: registrationFormData.ballot_request_ward,
                live_since: registrationFormData.ballot_request_lived_since,
                annual_request: registrationFormData.ballot_request_annual_request,
                confirmed: registrationFormData.ballot_request_confirmed,
            },
            survey_questions: {
                question_one: registrationFormData.survey_question_one,
                question_one_answer: registrationFormData.survey_question_one_answer,
                question_two: registrationFormData.survey_question_two,
                question_two_answer: registrationFormData.survey_question_two_answer,
                question_three: registrationFormData.survey_question_three,
                question_three_answer: registrationFormData.survey_question_three_answer,
            }
        },
        tracking: {
            source: trackingData.source,
            value: trackingData.value,
            referrer: trackingData.referrer,
        }
    };

    // AES Encryption Setup
    // Step 1: Get a key/IV, use the AES-CBC method, and encrypt the payload
    // Step 2: Get the base64 encoded payload out
    // Step 3: Generate the HMAC for integrity?  Build using the IV and payload output
    let key = forge.random.getBytesSync(32);
    let iv = forge.random.getBytesSync(16);
    let hmac_key = forge.random.getBytesSync(32);

    let cipher = forge.cipher.createCipher('AES-CBC', key);
    //cipher.mode.pad = false;
    //cipher.mode.unpad = false;
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(JSON.stringify(result), 'utf8'));
    cipher.finish();
    let cipher_output = cipher.output.getBytes();

    // Generate HMAC
    let iv_cipher = iv + cipher_output;
    let hmac = forge.hmac.create();
    hmac.start('sha256', hmac_key);
    hmac.update(iv_cipher);

    //console.log('Key: '+String.fromCharCode.apply(String, key));
    //console.log('HMAC: '+String.fromCharCode.apply(String, hmac_key));
    //console.log('IV: '+String.fromCharCode.apply(String, iv));

    // RSA Encryption
    // Step 1: Build the public key
    // Step 2: Build the AES key string from the IV and HMAC
    // Step 3: Encrypt using public key encryption
    let pem = '-----BEGIN PUBLIC KEY-----\n' + pubkey + '\n-----END PUBLIC KEY-----'
    let publicKey = forge.pki.publicKeyFromPem(pem);
    let buffer = forge.util.createBuffer(forge.util.encode64(key) + ':' + forge.util.encode64(hmac_key) + ':' + forge.util.encode64(process.env.REACT_APP_CANVASSER_ID) + ':' + forge.util.encode64('form'), 'utf8');
    let bytes = buffer.getBytes();
    let encrypted = publicKey.encrypt(bytes, 'RSA-OAEP');
    let digest = hmac.digest().bytes();

    // Complete - Set the payload/key
    let payload = forge.util.encode64(iv) + ':' + forge.util.encode64(digest) + ':' + forge.util.encode64(cipher_output);

    encrypted_result.payload = payload;
    encrypted_result.key = forge.util.encode64(encrypted);

    return encrypted_result;
}

export const createImagePayload = (imageData, imageType, pubkey) => {
    let encrypted_result = {
        key: '',
        payload: '',
    };

    // AES Encryption Setup
    // Step 1: Get a key/IV, use the AES-CBC method, and encrypt the payload
    // Step 2: Get the base64 encoded payload out
    // Step 3: Generate the HMAC for integrity?  Build using the IV and payload output
    let key = forge.random.getBytesSync(32);
    let iv = forge.random.getBytesSync(16);
    let hmac_key = forge.random.getBytesSync(32);

    let cipher = forge.cipher.createCipher('AES-CBC', key);
    //cipher.mode.pad = false;
    //cipher.mode.unpad = false;
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(imageData));
    cipher.finish();
    let cipher_output = cipher.output.getBytes();

    // Generate HMAC
    let iv_cipher = iv + cipher_output;
    let hmac = forge.hmac.create();
    hmac.start('sha256', hmac_key);
    hmac.update(iv_cipher);

    //console.log('Key: '+String.fromCharCode.apply(String, key));
    //console.log('HMAC: '+String.fromCharCode.apply(String, hmac_key));
    //console.log('IV: '+String.fromCharCode.apply(String, iv));

    // RSA Encryption
    // Step 1: Build the public key
    // Step 2: Build the AES key string from the IV and HMAC
    // Step 3: Encrypt using public key encryption
    let pem = '-----BEGIN PUBLIC KEY-----\n' + pubkey + '\n-----END PUBLIC KEY-----'
    let publicKey = forge.pki.publicKeyFromPem(pem);
    let buffer = forge.util.createBuffer(forge.util.encode64(key) + ':' + forge.util.encode64(hmac_key) + ':' + forge.util.encode64(process.env.REACT_APP_CANVASSER_ID) + ':' + forge.util.encode64(imageType), 'utf8');
    let bytes = buffer.getBytes();
    let encrypted = publicKey.encrypt(bytes, 'RSA-OAEP');
    let digest = hmac.digest().bytes();

    // Complete - Set the payload/key
    let payload = forge.util.encode64(iv) + ':' + forge.util.encode64(digest) + ':' + forge.util.encode64(cipher_output);

    encrypted_result.payload = payload;
    encrypted_result.key = forge.util.encode64(encrypted);

    return encrypted_result;
}

export const sendSurveyData = (responseID, shiftID, urlID, suburlID, type, key, payload) => {
    let post_body = {
        canvasser_id: process.env.REACT_APP_CANVASSER_ID,
        response_id: responseID,
        shift_id: shiftID,
        id: urlID,
        sid: suburlID,
        type: type,
        key: key,
        payload: payload,
    };

    return fetch(`${process.env.REACT_APP_API_BASE}voter/registration`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(post_body),
    }).then((response) => {
        if (!response.ok) {
            Bugsnag.notify(new Error('Error response from API'), function (event) {
                event.severity = 'error'
                event.addMetadata('survey', {
                    canvasser_id: process.env.REACT_APP_CANVASSER_ID,
                    response_id: responseID,
                    shift_id: shiftID,
                    id: urlID,
                    sid: suburlID,
                    type: type,
                });
                event.addMetadata('response', response);
            });

            throw Error(response.statusText);
        }

        return response.text();
    });
}

export const generateRegistrationID = () => {
    return uuidv4();
}

export const encodeKey = (rsaPubKey) => {
    return base64.encode(rsaPubKey);
}
