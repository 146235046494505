import { store } from "./store";
import { Link } from "react-router-dom";

const english = {
    "general": {
        "settings": "Settings",
        "shift_time": "Shift Time",
        "registrations": "Registrations",
        "waiting_to_upload": "Waiting to Upload",
        "upload_data": "Upload Data",
        "end_shift": "End Shift",
        "privacy_policy": "Privacy Policy",
        "terms_conditions": "Terms & Conditions",
        "knowledge_base": "Knowledge Base",
        "saving_data": "Saving registration data...",
        "new_registration_heading": "Are you sure you want to start a new registration?",
        "new_registration_body": "If you start a new registration, the current registration information will be cleared.",
        "powered_by": "Powered by ",
    },
    "fields": {
        "first_name": "First Name",
        "last_name": "Last Name",
        "middle_name": "Middle Name/Initial",
        "suffix": "Suffix",
        "birth_date": "Birth Date (mm/dd/yyyy)",
        "race": "Race (optional)",
        "sex": "Sex",
        "email_address": "Email Address",
        "phone_number": "Phone Number",
        "phone_type_select_label": "Select phone type...",
        "phone_type_select": "- Select Type -",
        "phone_type": "Phone Type",
        "cell_phone": "Cell Phone",
        "home_phone": "Home Phone",
        "work_phone": "Work Phone",
        "license_id": "PA Driver's License or PennDOT ID Card #",
        "last_four_ssn": "Last Four Digits of SSN",
        "street_address": "Street Address",
        "street_address_2": "Street Address 2",
        "unit_type": "Unit Type (if applicable)",
        "unit_number": "Unit Number (if applicable)",
        "city": "City",
        "state": "State",
        "zipcode": "ZIP Code",
        "county": "County Where You Live",
        "municipality": "Municipality Where You Live",
        "assistant_name": "Name of person who assisted in the completion of this application",
        "previous_first_name": "Previous First Name",
        "previous_last_name": "Previous Last Name",
        "previous_middle_name": "Previous Middle Name/Initial",
        "is_interested_poll_worker": "I would like to be a poll worker on election day.",
        "is_interested_interpreter": "I would like to be a bilingual interpreter on Election Day",
        "interpreter_language": "I speak this language",
        "no_address": "I do not have a street address or permanent residence.",
        "yes_assistance": "Yes, someone assisted me in signing.",
        "no_assistance": "No, I signed myself.",
        "last_four": "Please enter the LAST FOUR digits of your Social Security number.",
        "mailing_address": "Mailing Address",
        "mailing_address_2": "Mailing Address 2",
        "mailing_city": "Mailing City",
        "mailing_state": "Mailing State",
        "mailing_zipcode": "Mailing ZIP Code (Ex. 12345 or 12345-6789)",
        "mailing_country": "Mailing Country",
        "mailing_address_in_usa": "Is the address in the United States?",
        "mailing_address_is_my": "Mailing Address Is My … (e.g. vacation home, temporary residence, etc.)",
        "political_party": "Political Party",
        "party_other": "If Other, Please Specify",
        "previous_address": "Previous Street Address",
        "previous_city": "Previous City",
        "previous_state": "Previous State",
        "previous_county": "Previous County",
        "previous_zipcode": "Previous ZIP Code",
        "na": "n/a",

        "ballot_mailing_address": "Mail ballot to address",
        "ballot_mailing_city": "Mail ballot to city",
        "ballot_mailing_state": "Mail ballot to state is",
        "ballot_mailing_zipcode": "Mail ballot to ZIP Code (Ex. 12345 or 12345-6789)",
        "ballot_mailing_zipcode_short": "Mail ballot to ZIP Code",
        "ballot_mailing_country": "Mail ballot to country",
        "ballot_mailing_ward_short": "Ward or Voting District",
        "ballot_mailing_lived_since_short": "Lived at address since",




    },
    "placeholders": {
        "dropdown": "- Select -",
        "first_name": "Jane",
        "last_name": "Doe",
        "middle_name": "Henry",
        "suffix": "Jr., II, etc",
        "birth_date": "mm/dd/yyyy",
        "email_address": "you@example.com",
        "phone_number": "555-555-5555",
        "select_an_item": "Select an item...",
        "select_phone_type": "Select phone type...",
        "license_id": "12 345 678",
        "street_address": "5555 Oak St.",
        "street_address_2": "5555 Oak St.",
        "unit_number": "40B",
        "city": "City",
        "zipcode": "12345",
        "municipality": "Select a county first...",
        "political_party": "Political Party",
        "mailing_address_is_my": "vacation home, temporary residence, etc.",
    },
    "validation": {
        "id": "The PA driver's license or PennDOT ID card information you entered is not valid. Please confirm your PA driver's license or PennDOT ID card information",
        "first_name": "Your first name is required.",
        "last_name": "Your last name is required.",
        "middle_name": "Your middle name is not valid.",
        "birth_date": "Your birth date is required.",
        "birth_date_valid": "Please input a valid birth date.",
        "email_address_format": "The format of the email address is incorrect. Please correct and try again.",
        "phone_number_required": "Your phone number is required.",
        "phone_number_valid": "The phone number provided is not valid. Please enter a valid phone number.",
        "license_id": "Please provide a value.",
        "license_id_valid": "Please enter a valid license number.",
        "ssn": "",
        "street_address": "Your street address is required.",
        "city": "Your city is required.",
        "state": "Please select a state.",
        "zipcode": "Your ZIP code is required.",
        "zipcode_valid": "Please enter a valid 5 digit ZIP code.",
        "no_address_zipcode": "Your ZIP code is required or click the check box indicating that you do not have one.",
        "no_address_zipcode_valid": "Please enter a valid 5 digit zip code or click the check box indicating that you do not have one.",
        "county": "Your county of residence is required.",
        "signature_confirmation_title": "Confirmation Error",
        "signature_confirmation_message": "Please confirm that you have read and agree to the terms.",
        "assistant_confirmation_title": "Confirmation Error",
        "assistant_confirmation_message": "Please indicate assistance was provided with the completion of this form.",
        "previous_first_name": "First Name is required for a Name Change application",
        "previous_last_name": "Previous Last Name is required for a Name Change application",
        "previous_middle_name": "Previous middle name is not valid.",
        "select_option": "Please select an option.",
        "provide_value": "Please provide a value.",
        "no_address": "Your street address is required or click the check box indicating that you do not have one",
        "mailing_address": "Your mailing address is required",
        "mailing_city": "Your mailing city is required",
        "mailing_state": "Your mailing state is required",
        "mailing_zipcode": "The ZIP code must be 5 digits or 9 digits (zip code + 4)",
        "mailing_address_is_my": "Please provide a value for this field",
        "party": "Please select a political party.",
        "party_other": "Warning - Party is not selected. If Other is selected, the Other party text box should be completed.",
        "previous_address": "Address of Previous Registration is required for an Address Change application",
        "previous_city": "City of Previous Registration is required for an Address change application",
        "previous_state": <>Previous state must be Pennsylvania. If you have recently moved to Pennsylvania from another state, you must <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>submit a new registration</Link> rather than a change of address.</>,
        "previous_county": "Previous County Registration is required for an Address Change application.",
        "previous_zipcode": "The ZIP code must be 5 digits or 9 digits (zip code + 4)",
        "previous_zipcode_required": "ZIP of Previous Registration is required for an Address Change application.",
        "phone_type": "Please select an option.",
        "valid_value": "Please enter a valid value.",
        'na': 'n/a',
    },
    "buttons": {
        "lets_get_started": "Let's Get Started",
        "new_registrant": "I am a new registrant",
        "vbm_only": "I only want to request a mail-in ballot",
        "update_registration": "I need to update registration",
        "no_changes": "I have no changes to make",
        "proceed": "Proceed",
        "continue": "Continue",
        "cancel": "Cancel",
        "yes": "Yes",
        "no": "No",
        "no_ssn": "I don't have my SSN with me",
        "next_step": "Next Step",
        "update_information": "Update Information",
        "add_signature": "Add Signature",
        "clear_signature": "Clear Signature",
        "accept_signature": "Accept Signature",
        "complete_registration": "Complete Registration",
        "complete_vbm": "Complete Vote By Mail Application",
        "start_new_registration": "Start New Registration",
        "continue_registration": "Continue Registration",
        "sign_up": "Sign Up",
        "stay_in_touch": "Stay In Touch",
        "back": "Back",
        "continue_without_ssn": "Proceed Without SSN",
        "continue_without_id": "Proceed Without PennDot ID",
    },

    "home": {
        heading: "Welcome to PA Vote For Change.",
        content: "Please select one of the options below to update or begin your voter registration.",
        id_required_heading: "PennDOT ID Required",
        id_required_body: "In order to submit a mail-in ballot request, a PennDot ID number is required. Do you want to proceed?",
    },
    "comingsoon": {
        heading: "Welcome to PA Vote For Change.",
        content: "Online voter registration coming soon.",
    },
    "additional_questions": {
        heading: "Would you like to be a poll worker on Election Day?",
        content: "Please indicate your preference below.",
    },
    "address_information": {
        heading: "Please provide us the information, below.",
    },
    "add_signature": {
        heading: "Review the statement and sign to verify your registration.",
        review_your_signature: "Review Your Signature:",
        review_signature_terms_confirm: "I confirm that I have read and agree to the terms above.",
        review_signature_sign: "Sign here using your mouse or finger if on a mobile device:",
    },
    "assistance_add_signature": {
        heading: "Assistant: Please review the statement below and sign to confirm agreement to the terms.",
        review_your_signature: "Review Your Signature:",
        review_signature_terms_confirm: "I confirm that I have read and agree to the terms above.",
        review_signature_sign: "Sign here using your mouse or finger if on a mobile device:",
    },
    "assistance_information": {
        heading: "Please have the assistant provide their address and signature.",
        content: "Please add your name and address below.",
    },
    "confirmation": {
        heading: "Registration Processing!",
        content: "Thank you for submitting a voter registration application!",
        content_with_email: "We'll send you a confirmation email and keep in touch about other services coming up on voting day.",
        support: <>
            <p>The decision on your voter registration is ultimately made by your county voter registration office. If you have any questions about your application, please reach out to your county for clarification. For further support, kindly email us at <a href="mailto:help@pavoteforchange.com" className="underline">help@pavoteforchange.com</a>.</p>
        </>,
    },
    "vbm_confirmation": {
        heading: "Vote By Mail Application Processing",
        content: "Thank you for submitting a Vote By Mail application!",
        content_with_email: "We'll send you a confirmation email and keep in touch about other services coming up on voting day.",
        support: <>
            <p>The decision on your Vote By Mail Application is ultimately made by your county voter registration office. If you have any questions about your application, please reach out to your county for clarification. For further support, kindly email us at <a href="mailto:help@pavoteforchange.com" className="underline">help@pavoteforchange.com</a>.</p>
        </>,
    },
    "current_address": {
        heading: "Please enter your current address of residence.",
    },
    "eligibility_age": {
        valid_age_question: "Will you be 18 years or older on or before election day?",
    },

    "eligibility_citizenship": {
        us_citizen_question: "Are you a citizen of the United States of America?",
    },
    "eligibility_notice": {
        heading: "To register you must:",
        content_1: <>Be <u>a United States citizen</u> at least 30 days before the next election</>,
        content_2: <>Be <u>a resident of Pennsylvania</u> and your election district for at least 30 days before the next election</>,
        content_3: <>Be <u>at least 18 years of age</u> on the day of the next election</>,
    },
    "had_assistance": {
        heading: "Did someone assist you in signing this?",
        content: "If you were unable to sign this form due to a physical disability, you may have had an assistant do so on your behalf. That assistant must provide his or her signature and address in the space provided. By signing, the assistant certifies that he or she signed the form at your request.",
    },
    "has_mailing_address": {
        heading: "Is your mailing address the same as your residential address?",
        mailing_ask_yes: "Yes, my mailing and residential addresses are the same.",
        mailing_ask_no: "No, I have a different mailing address.",
    },
    "id_entry": {
        heading: "Enter the information on your PennDOT ID.",
        content: "Please fill out the form below based on your current information. Please note: this is not where you update your name or address.",
        no_id_heading: "The PennDot ID option has been selected, but no number has been entered.",
        no_id_body: "Do you want to proceed without entering a PennDot ID?",
    },
    "id_verification": {
        heading: "Do you have a PA driver's license or PennDOT ID card number?",
        content: "The easiest way for us to register you is with your Driver License or PennDOT ID, but we can also do so if you have a social security number.",
        license_ask_have_id: "I have my PA driver's license or PennDOT ID card number with me right now.",
        license_ask_no_id: "I have a PA driver's license or PennDOT ID card number, but I don't have it with me right now.",
        license_ask_have_ssn: "I do not have a PA driver's license or PennDOT ID card number, but I have a Social Security Number.",
        license_ask_no_id_ssn: "I do not have a PA driver's license or PennDOT ID card or a Social Security Number.",
        hava_disclaimer: <em><span className="text-xs">Please note if you do not provide a PennDOT ID number, or if the number you provide is not correct, your county will contact you to complete your application.</span></em>,
        no_id_ssn_checkbox: "Lorem Ipsum",
    },
    "last_four_entry": {
        heading: "Since you do not have a PennDot number please enter the last 4 digits of your Social Security number.",
        with_id_heading: "Please enter the last 4 digits of your Social Security number.",
        no_ssn_heading: "The Social Security Number (SSN) option has been selected, but no number has been entered.",
        no_ssn_body: "Do you want to proceed without entering a Social Security Number?",
    },
    "mailing_address": {
        heading: "Please provide us with your mailing address.",
        content: "We'll need your current mailing address to get you registered.",
    },
    "new_address": {
        heading: "Please enter your new address.",
    },
    "new_name": {
        heading: "Please enter your new name.",
    },
    "not_eligible": {
        heading: "We won't be able to register you today.",
        content: "We'll keep in touch about other services coming on voting day!",
    },
    "page_not_found": {
        heading: "Page Not Found",
    },
    "paper_application": {
        heading: "Please ask your canvasser for a paper application.",
        content: "Since you do not have a permanent address, we'll need you to fill out a paper application. Don't worry, we can still help you get registered!",
    },
    "party_affiliation": {
        heading: "What is your political party affiliation?",
    },
    "previous_address": {
        heading: "Please enter your address of previous registration.",
        content: <>If you have recently moved to Pennsylvania from another state, you must <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>submit a new registration</Link> rather than a change of address.,</>
    },
    "previous_name": {
        heading: "Please enter your previous name.",
    },
    "privacy": {
        heading: "Privacy Policy",
        content: <>
            <p>This Privacy Policy and the Terms of Use apply to all users of the PA Vote For Change voter registration mobile application (the "App"), including Registrants, Canvassers, and Organizations, as those terms are defined below ("users" or "you").  </p>
            <p>The App is being made available by PA Vote for Change LLC ("PA Vote for Change," "we," or "us"), which is authorized to permit other organizations ("Organizations"), through their employees, contractors, and/or volunteers ("Canvassers"), to use the App to register people to vote in the Commonwealth of Pennsylvania ("Registrants").   Before you use or submit any information in connection with the App, carefully review this Privacy Policy and Terms of Use. By using the App, you consent to the collection, use, and disclosure of your information, and the terms and conditions of use, as outlined below. </p>
            <p>We will continue to review these policies and we may make changes to them from time to time. The date at the bottom of this page indicates the last time changes were made. You should check this page periodically for updates. Your continued use of the App will signify that you have read, understood, and accepted the terms of the updated Privacy Policy and Terms of Use.</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>PRIVACY POLICY</strong></p>
            <p><strong>For Registrants</strong></p>
            <p><strong>You may only enter information in the App to register to vote if you are a U.S. citizen and otherwise eligible to register, as indicated by prompts in the App.</strong></p>
            <p>The App will ask you to enter information about yourself that is ultimately submitted to your County Commissioner's Office/Board of Elections to register you to vote.  This information may include:</p>
            <ul>
                <li>Full Name; </li>
                <li>Date; </li>
                <li>Residence address; </li>
                <li>Mailing address;</li>
                <li>Address of previous residence immediately before moving to current residence;</li>
                <li>Date of birth; </li>
                <li>Race;</li>
                <li>Party;</li>
                <li>Sex;</li>
                <li>Phone number;</li>
                <li>Email address;</li>
                <li>Penndot ID Number and/or the last 4 digits of your Social Security Number;</li>
                <li>Whether you have resided within the Commonwealth of Pennsylvania for at least 30 days prior to the next Election Day;</li>
                <li>Whether you are a citizen of the United States of America for at least 30 days prior to the next Election Day;</li>
                <li>Whether you will be 18 years of age or older on the next Election Day; </li>
                <li>Whether you need assistance voting;</li>
                <li>Whether you are interested in being a poll worker;</li>
                <li>Whether you want to apply to vote by mail or, if you have previously voted by mail, whether you want to request instead that the information be transferred from last year's request to receive a mail in ballot again;</li>
                <li>Your driver's license number; and</li>
                <li>An image of your signature</li>
            </ul>
            <p>The Organization who collects this information from you will send this information to your County Commissioner's Office/Board of Elections to register you to vote. The Organization on whose behalf the Canvasser collects your information may retain the above information about you, except for your driver's license number and the last four digits of your Social Security Number. The Organization may use this information for the following purposes:</p>
            <ul>
                <li>To contact you with information about how, where and when to vote;</li>
                <li>To contact you to encourage you to vote in elections;</li>
                <li>To contact you with other information Organization believes may be of interest to you;</li>
                <li>For research purposes, when combined with information from other Registrants and Canvassers, including, by way of example, to analyze and study patterns in voter registration, the effectiveness of various voter registration methods and approaches, and characteristics of new registrants; </li>
                <li>To maintain and/or improve the App; and</li>
                <li>For business operations, including for security, fraud prevention, and legal compliance.</li>
            </ul>
            <p>The Organization will not use your information for any commercial purpose, including soliciting you to purchase goods or services, or for any purpose prohibited by applicable law. </p>
            <p>The Organization may share and/or disclose your information (except for your driver's license number and the last four digits of your Social Security Number) under the following circumstances:</p>
            <ul>
                <li>To state, local or federal government agencies if and when required by applicable law;</li>
                <li>To academic or research institutions or providers for non-commercial purposes; </li>
                <li>To other nonpartisan organizations engaged in voter registration and mobilization efforts for non-commercial purposes;</li>
                <li>To PA Vote for Change and its family of organizations, as well as the funders of the application </li>
                <li>If the Organization acquires, merges or partners with other organizations; and</li>
                <li>If the information is aggregated and/or de-identified information.</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>For Canvassers</strong></p>
            <p>Canvassers must use the App only in accordance with the instructions and training they have been provided.</p>
            <p>Canvassers may not personally retain any information of any kind collected from Registrants using the App, and may not share any such information with any other person or entity other than the Organization on whose behalf Canvasser is using the App. </p>
            <p>Canvassers will be asked to enter certain information about themselves before each shift trying to register voters using the App.  This may include your name, email address, username and password, , Partner Code, and the  date, time, and location of each shift trying to register voters using the App.</p>
            <p>The information you submit  will be used to confirm that you are a Canvasser entitled to access the App. In addition, the information you submit and information about the voter registrations you complete may be used by for the following purposes:    </p>
            <ul>
                <li>To track the progress of Organization's voter registration programs;</li>
                <li>To contact you for more information about any particular registrations you collected;</li>
                <li>To contact you with information Organization believes may be of interest to you;</li>
                <li>For research purposes, when combined with information from other Canvassers and Registrants, including, by way of example, to study and analyze patterns in voter registrations, the effectiveness of voter registration methods and approaches, and characteristics of new registrants;</li>
                <li>To maintain and/or improve the App; and</li>
                <li>For business operations, including for security, fraud prevention, and legal compliance.</li>
            </ul>
            <p>The Organization will not use your information for any commercial purpose, including soliciting you to purchase goods or services, or for any purpose prohibited by applicable law. </p>
            <p>The Organization may share and/or disclose your information under the following circumstances:</p>
            <ul>
                <li>To state, local or federal government agencies if and when required by applicable law;</li>
                <li>To academic or research institutions or providers for non-commercial purposes; </li>
                <li>To other nonpartisan organizations engaged in voter registration and mobilization efforts for non-commercial purposes;</li>
                <li>To PA Vote for Change and its family of organizations, as well as the funders of the application </li>
                <li>If the Organization acquires, merges or partners with other organizations; and</li>
                <li>If the information is aggregated and/or de-identified information.</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>For Organizations </strong></p>
            <p>The following information collected from Registrants may only be conveyed to the election authorities and CANNOT be retained by your organization: </p>
            <ul>
                <li>Driver's license number</li>
                <li>Last four digits of a Registrant's Social Security Number</li>
            </ul>
            <p>Other information collected through the App from Registrants and Canvassers may be retained by the Organization. The Organization may use this information for the following purposes: </p>
            <ul>
                <li>To contact Registrants with information about how, where and when to vote;</li>
                <li>To contact Registrants to encourage them to vote in elections;</li>
                <li>To track the progress of Organization's voter registration programs;</li>
                <li>To contact Canvassers for more information if needed about any particular registrations collected;</li>
                <li>To contact Registrants and Canvassers with information Organization believes may be of interest to them;</li>
                <li>For research purposes, when combined with information from other Registrants and Canvassers, including, by way of example, to analyze and study patterns in voter registration, the effectiveness of various voter registration methods and approaches, and characteristics of new registrants; </li>
                <li>To maintain and/or improve the App; and</li>
                <li>For business operations, including for security, fraud prevention, and legal compliance.</li>
            </ul>
            <p>The Organization cannot use such information for any commercial purpose, including soliciting Registrants or Canvassers to purchase goods or services, or for any purpose prohibited by applicable law. </p>
            <p>The Organization may share and/or disclose information obtained from Registrants and Canvassers (except for Registrants' driver's license numbers and the last four digits of their Social Security Numbers) under the following circumstances:</p>
            <ul>
                <li>To state, local or federal government agencies if and when required by applicable law;</li>
                <li>To academic or research institutions or providers for non-commercial purposes; </li>
                <li>To other nonpartisan organizations engaged in voter registration and mobilization efforts for non-commercial purposes;</li>
                <li>To PA Vote for Change and its family of organizations, as well as the funders of the application </li>
                <li>If the Organization acquires, merges or partners with other organizations; and</li>
                <li>If the information is aggregated and/or de-identified information.</li>
            </ul>
            <p>&nbsp;</p>
            <p>If you have any questions about this Privacy Policy or Terms of Use, you can contact us by emailing us at help@pavoteforchange.com.</p>
            <p>&nbsp;</p>
            <p>Last Updated: 9/21/2022</p>
        </>,
    },
    "registration": {
        heading_1: "It looks like you're not registered to vote - let's get you registered!",
        heading_2: "It looks like you're registered to vote!",
        heading_vmb_only: "Request a Mail-In Ballot",
        content: "Please confirm or provide the following information",
    },
    "registration_address": {
        heading: "Please enter your address for registration.",
    },
    "review": {
        heading: "Please confirm the information you provided.",
        content: "Please confirm that the information below is accurate. If information is incorrect, click on the edit icon at the top right corner of each section.",
        personal_info_heading: "Current Personal Information",
        residential_heading: "Residential Information",
        license_heading: "PA Driver's License or PennDOT ID",
        ssn_heading: "Social Security #",
        prev_name_heading: "Previous Name",
        prev_address_heading: "Previous Address",
        additional_info_heading: "Additional Information",
        email_optin: "Email Opt-In",
        phone_optin: "Phone Opt-In",
        county: "County",
        municipality: "Municipality",
        poll_worker: "Poll Worker",
        bilingual_interpreter: "Bilingual Interpreter",
        languages: "Language(s)",
        requires_help_to_vote: "Requires Help to Vote?",
        assistance_needed: "Assistance Needed",
        preferred_language: "Preferred Language",
        have_mailing_address: "I have a mailing address",
        ballot_request: "Annual Mail-in Ballot Application",
    },
    "required_help": {
        heading: "Do you require help to vote?",
        content: "Please indicate your preference below.",
        label: "I require help to vote. I need this kind of assistance.",
        language_label: "I require language help. My preferred language is:",
        type_error: "Please select the type of assistance required",
    },
    "stay_in_touch": {
        heading: "Let's Stay In Touch!",
        content: "We'll keep in touch about our organization",
    },
    "thank_you": {
        heading: "Thank You!",
        content: "We'll keep in touch about other services coming up on voting day!",
    },
    "terms": {
        heading: "Terms & Conditions",
        content: <>
            <p>This Privacy Policy and the Terms of Use apply to all users of the PA Vote For Change voter registration mobile application (the "App"), including Registrants, Canvassers, and Organizations, as those terms are defined below ("users" or "you").  </p>
            <p>The App is being made available by PA Vote for Change LLC ("PA Vote for Change," "we," or "us"), which is authorized to permit other organizations ("Organizations"), through their employees, contractors, and/or volunteers ("Canvassers"), to use the App to register people to vote in the Commonwealth of Pennsylvania ("Registrants").   Before you use or submit any information in connection with the App, carefully review this Privacy Policy and Terms of Use. By using the App, you consent to the collection, use, and disclosure of your information, and the terms and conditions of use, as outlined below. </p>
            <p>We will continue to review these policies and we may make changes to them from time to time. The date at the bottom of this page indicates the last time changes were made. You should check this page periodically for updates. Your continued use of the App will signify that you have read, understood, and accepted the terms of the updated Privacy Policy and Terms of Use.</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>TERMS OF USE</strong></p>
            <ol className="list-decimal">
                <li className="pb-8"><strong>Access to App.</strong> Each Canvasser accessing the App warrants and represents to PA Vote for Change LLC (“PA Vote for Change”, “we”, or “us”) that the Canvasser is authorized to use the App by an organization that is authorized by PA Vote for Change to use the App.   Each Canvasser represents that the information it provides in the App is accurate, complete and current. Each Registrant using the App warrants and represents and they are eligible to vote in the United States and that the information they provide is accurate, complete, and current.<br /><br />
                    Each Canvasser is responsible for safeguarding the Partner Code and password used to access the App and agrees that Canvasser will not disclose the Partner Code or password to any other person or entity.  Canvasser must notify the Organization for which Canvasser is an employee, contractor or volunteer immediately upon becoming aware of any breach of security or unauthorized use of Canvasser’s account.  Organization must then immediately notify PA Vote for Change.</li>
                <li className="pb-8"><strong>Intellectual Property.</strong>  Each user of the App (including each Registrant, Canvasser, and Organization) agrees that, as between such user and PA Vote for Change, all copyright, trademark, and other intellectual property rights in and to the software through which the App operates, in any form, object code or source code, and the architecture, features and functionality of the App, are and will remain the sole and exclusive property of PA Vote for Change, Each user also agrees that it obtains no ownership rights in and to the App through its use.  </li>
                <li className="pb-8"><strong>Termination.</strong> PA Vote for Change may suspend or terminate the account of any Canvasser or suspend or terminate access of Organization to the App, without prior notice or liability, in the event Canvasser or Organization, as applicable, breaches the Privacy Policy or Terms of Use.</li>
                <li className="pb-8"><strong>Limitation of Liability.</strong>  In no event will PA Vote for Change be liable for any damages, costs, fines or penalties incurred by Organization or any Canvasser or any Registrant or any other person or entity as a result of use of the App.  To the maximum extent permitted by applicable law, in no event shall PA Vote for Change be liable for any special, incidental, indirect or consequential damages whatsoever (including but not limited to, damages for loss of profits, loss of data or other information, for business interruptions, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the App, third party software and or third party hardware used with the App or on  which the App is operated, even if PA Vote for Change has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.<br /><br />
                    Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages which means that some of the above limitations may not apply.  In these states, each party’s liability will be limited to the greatest extent permitted by law.<br /><br />
                    As between PA Vote for Change and each Organization and Canvasser and Registrant, the App is provided “as is” and “as available” and with all faults and effects without warranty of any kind.  To the maximum extent permitted under applicable law, PA Vote for Change, on its own behalf and on behalf of PA Vote for Change's family of organizations, as well as funders of the App and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the App, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, PA Vote for Change provides no warranty or undertaking, and makes no representation of any kind that the App will meet the requirements of any Organization, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.<br /><br />
                    Without limiting the foregoing, PA Vote for Change makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the App, or the information, content, and materials or products included thereon; (ii) that the App will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the App; or (iv) that the App, its servers, the content, or e-mails sent from or on behalf of PA Vote for Change are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.<br /><br />
                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</li>
                <li className="pb-8"><strong>Governing Law.</strong>  These Terms will be governed by and construed in accordance with the laws of the Commonwealth of Pennsylvania.</li>
                <li className="pb-8"><strong>Severability.</strong> If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</li>
                <li className="pb-8"><strong>Waiver.</strong> Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</li>
            </ol>
            <p>If you have any questions about this Privacy Policy or Terms of Use, you can contact us by emailing us at help@pavoteforchange.com.</p>
            <p>&nbsp;</p>
            <p>Last Updated: 9/21/2022</p>
        </>,
    },
    "update_information": {
        heading: "It looks like you're registered to vote! Do you need to make any changes to your registration?",
        content: "Please select all that apply",
        error: "Please check at least one box.",
        change_name: "I need to change my name on voter registration.",
        change_address: <>I need to change the address linked to my voter registration.<br /><em><span className="text-xs">If you have recently moved to Pennsylvania from another state, you must <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>submit a new registration</Link> rather than a change of address. </span></em></>,
        change_party: "I need to change my political party affiliation.",
        federal_employee: "I am a Federal or State employee registering in my county of last residence.",
    },

    "ballot_request": {
        heading: "Annual Mail-In Ballot Application",
        content: "If you would like to receive mail-in ballots for the remainder of this year and if you would like to automatically receive an annual application for mail-in ballots each year, please indicate below.",
        content2: "Your name, address, county, date of birth, and PA driver's license or PennDOT ID card number provided on the previous screens will be used for your Mail-In Ballot Application.",
        label: "Would you like to receive mail-in ballots for any election you qualify for?",
        address_type_label: "Mail ballot to me at the following address:",
        address_type_1: "Residential Address",
        address_type_2: "Mailing Address",
        address_type_3: "Alternate Address",
        ballot_request_type_error: "Please select the address type.",
        ballot_ward_label: "Please indicate your Ward or Voting District (if known)",
        ballot_lived_since_label: "I have lived at this address since:",
        ballot_lived_since_error: "Please provide the year you moved to this address.",
        ballot_lived_since_error_2: "Please provide a valid year.",
        id_text: "Identification: Your PA driver's license or PennDOT ID card number provided above will be used for your Mail-In Ballot Application. If this information is not correct please make any necessary edits in ID Info.",
        id_field: "PA Driver's License of PennDOT ID card number: ",
        annual_request: "If you would like to apply to receive mail-in ballots for the remainder of this year and if you would like to automatically receive an annual application for mail-in ballots each year, please indicate below. If you update your voter registration due to relocation out of county after you submit an annual mail-in ballot request, please ensure your annual status is transferred when updating your address",
        annual_request_field: "I would like to receive mail-in ballots this year and receive annual applications for mail-in ballots each year.",
        confirmation_heading: "Please review the statement below and sign to confirm agreement to the terms.",
        review_terms_confirm: "I confirm that I have read and agree to the terms above.",
        
    },




}

const spanish = {
    "general": {
        "settings": "Ajustes",
        "shift_time": "Horario del turno",
        "registrations": "Registros",
        "waiting_to_upload": "En espera de la carga",
        "upload_data": "Cargar datos",
        "end_shift": "Fin del turno",
        "privacy_policy": "Política de privacidad",
        "terms_conditions": "Términos y condiciones",
        "knowledge_base": "Base de conocimientos",
        "saving_data": "Guardar los datos de registro...",
        "new_registration_heading": "¿Está seguro de que quiere iniciar un nuevo registro?",
        "new_registration_body": "Si inicia un nuevo registro, la información de registro actual se eliminará.",
        "powered_by": "Powered by ",
    },
    "fields": {
        "first_name": "Nombre",
        "last_name": "Apellido",
        "middle_name": "Inicial de su segundo nombre",
        "suffix": "Sufijo",
        "birth_date": "Fecha de nacimiento (mm/dd/aaaa)",
        "race": "Raza (opcional)",
        "sex": "Sexo",
        "email_address": "Correo electrónico",
        "phone_number": "Teléfono",
        "phone_type_select_label": "Seleccione el tipo de teléfono...",
        "phone_type_select": "- Seleccione el tipo - ",
        "phone_type": "Tipo de teléfono",
        "cell_phone": "Teléfono móvil",
        "home_phone": "Teléfono residencial",
        "work_phone": "Teléfono del trabajo",
        "license_id": "Núm. de licencia de conducir de PA o de ID de PennDOT",
        "last_four_ssn": "Últimos cuatro dígitos de su Núm. de SS",
        "street_address": "Dirección",
        "street_address_2": "Dirección 2",
        "unit_type": "Tipo de unidad (si es necesario)",
        "unit_number": "N.º de unidad (si es necesario)",
        "city": "Ciudad",
        "state": "Estado",
        "zipcode": "Código postal",
        "county": "Condado donde vive",
        "municipality": "Municipalidad donde vive",
        "assistant_name": "Nombre de la persona que le ayudó a llenar esta solicitud",
        "previous_first_name": "Nombre anterior",
        "previous_last_name": "Apellido anterior",
        "previous_middle_name": "Segundo nombre anterior",
        "is_interested_poll_worker": "Me gustaría ser trabajador electoral el día de las elecciones. ",
        "is_interested_interpreter": "Me gustaría ser intérprete bilingüe el día de las elecciones.",
        "interpreter_language": "Hablo este idioma:",
        "no_address": "No tengo dirección normal ni dirección permanente.",
        "yes_assistance": "Sí, alguien me ayudó a firmar.",
        "no_assistance": "No, firmé yo mismo.",
        "last_four": "Ingrese los ÚLTIMOS CUATRO dígitos de su número de seguro social.",
        "mailing_address": "Dirección postal",
        "mailing_address_2": "Dirección postal 2",
        "mailing_city": "Ciudad",
        "mailing_state": "Estado",
        "mailing_zipcode": "Código Postal",
        "mailing_country": "País de envío por correo",
        "mailing_address_in_usa": "¿Es esta dirección en los Estados Unidos?",
        "mailing_address_is_my": "La dirección postal es mi... (por ej. casa de vacaciones, residencia temporaria, etc.)",
        "political_party": "Partido político",
        "party_other": "Por favor especifique si es otro (OTHER):",
        "previous_address": "Dirección",
        "previous_city": "Ciudad",
        "previous_state": "Estado",
        "previous_county": "Condado",
        "previous_zipcode": "Código Postal",
        'na': 'n/a',
        "ballot_mailing_address": "Enviar boleta a la dirección",
        "ballot_mailing_city": "Enviar boleta a la ciudad",
        "ballot_mailing_state": "Enviar boleta al estado",
        "ballot_mailing_zipcode": "Enviar boleta al código postal (Ej. 12345 o 12345-6789)",
        "ballot_mailing_zipcode_short": "Enviar boleta al código postal",
        "ballot_mailing_country": "Enviar la papeleta a país",
        "ballot_mailing_ward_short": "Distrito electoral o de votación",
        "ballot_mailing_lived_since_short": "Vivió en el domicilio desde",
    },
    "placeholders": {
        "dropdown": "- Seleccione -",
        "first_name": "Jane",
        "last_name": "Doe",
        "middle_name": "Henry",
        "suffix": "Jr., II, etc",
        "birth_date": "mm/dd/aaaa",
        "email_address": "you@example.com",
        "phone_number": "555-555-5555",
        "select_an_item": "Seleccione un elemento...",
        "select_phone_type": "Seleccione el tipo de teléfono...",
        "license_id": "12 345 678",
        "street_address": "5555 Oak St.",
        "street_address_2": "5555 Oak St.",
        "unit_number": "40B",
        "city": "Ciudad",
        "zipcode": "12345",
        "municipality": "Seleccione primero un condado...",
        "political_party": "Partido político",
        "mailing_address_is_my": "casa de vacaciones, residencia temporaria, etc.",
    },
    "validation": {
        "id": "La información de licencia de conducir de PA o tarjeta de identificación PennDOT que ingresó no es válida. Confirme su número de licencia de conducir o tarjeta de identificación PennDOT.",
        "first_name": "Su nombre es obligatorio.",
        "last_name": "Su apellido es obligatorio.",
        "middle_name": "Su segundo nombre no es válido.",
        "birth_date": "Su fecha de nacimiento es obligatoria.",
        "birth_date_valid": "Ingrese una fecha de nacimiento válida.",
        "email_address_format": "El formato de dirección de email no es correcto.  Corrija e ingrese de nuevo.",
        "phone_number_required": "Es obligatorio ingresar su número de teléfono",
        "phone_number_valid": "El número de teléfono ingresado no es válido.  Ingrese número de teléfono válido.",
        "license_id": "Ingrese un valor.",
        "license_id_valid": "La información de licencia de conducir de PA o tarjeta de identificación PennDOT que ingresó no es válida. Confirme su número de licencia de conducir o tarjeta de identificación PennDOT.",
        "ssn": "Ingrese los ÚLTIMOS CUATRO dígitos de su número de seguro social.",
        "street_address": "Es obligatorio ingresar su dirección.",
        "city": "Es obligatorio ingresar ciudad.",
        "state": "Seleccione un estado.",
        "zipcode": "Es obligatorio ingresar el código postal.",
        "zipcode_valid": "Es obligatorio ingresar el código postal.",
        "no_address_zipcode": "Es obligatorio ingresar el código postal.",
        "no_address_zipcode_valid": "Ingrese código postal válido de 5 dígitos.",
        "county": "Es obligatorio ingresar su condado.",
        "signature_confirmation_title": "Error de confirmación",
        "signature_confirmation_message": "Por favor, confirme que leyó y acepta los términos.",
        "assistant_confirmation_title": "Error de confirmación",
        "assistant_confirmation_message": "Indique que se proporcionó ayuda para llenar este formulario.",
        "previous_first_name": "El nombre anterior es obligatorio para solicitar cambio de nombre.",
        "previous_last_name": "El apellido anterior es obligatorio para solicitar cambio de nombre.",
        "previous_middle_name": "Su segundo nombre no es válido.",
        "select_option": "Seleccione una opción.",
        "provide_value": "Ingrese un valor.",
        "no_address": "Es obligatorio ingresar su dirección.",
        "mailing_address": "Es obligatorio ingresar su dirección.",
        "mailing_city": "Es obligatorio ingresar ciudad.",
        "mailing_state": "Seleccione un estado.",
        "mailing_zipcode": "Es obligatorio ingresar el código postal.",
        "mailing_address_is_my": "Por favor proporcione un valor para esate campo",
        "party": "Por favor seleccione un partido político.",
        "party_other": "Advertencia: No se ha seleccionado partido. Si seleccionó Otro, debe completar la casilla Otro partido.",
        "previous_address": "La dirección de inscripción anterior es obligatoria para solicitar cambio de dirección.",
        "previous_city": "La ciudad de inscripción anterior es obligatoria para solicitar cambio de dirección.",
        "previous_state": <>El estado anterior debe ser Pennsylvania. Si se mudó hace poco a Pennsylvania desde otro estado, debe <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>resentar una nueva inscripción</Link> en lugar de un cambio de dirección.</>,
        "previous_county": "El condado de inscripción anterior es obligatoria para solicitar cambio de dirección",
        "previous_zipcode": "El código postal de inscripción anterior es obligatorio para solicitar cambio de dirección.",
        "previous_zipcode_required": "El código postal de inscripción anterior es obligatorio para solicitar cambio de dirección.",
        "phone_type": "Seleccione una opción.",
    },
    "buttons": {
        "lets_get_started": "Comencemos",
        "new_registrant": "Soy un nuevo inscrito",
        "vbm_only": "Solo deseo solicitar una papeleta por correo.",
        "update_registration": "Necesito actualizar la inscripción",
        "no_changes": "No tengo cambios que hacer",
        "proceed": "Continuar",
        "continue": "Continuar",
        "cancel": "Cancelar",
        "yes": "Sí",
        "no": "No",
        "no_ssn": "No tengo mi SSN conmigo",
        "next_step": "Paso siguiente",
        "update_information": "Actualizar la información",
        "add_signature": "Añadir firma",
        "clear_signature": "Borrar firma",
        "accept_signature": "Aceptar firma",
        "complete_registration": "Completar inscripción",
        "complete_vbm": "Completar la solicitud de Voto por correo",
        "start_new_registration": "Iniciar nueva inscripción",
        "continue_registration": "Continuar inscripción",
        "sign_up": "Inscribirse",
        "stay_in_touch": "Seguir en contacto",
        "back": "atrás",
        "continue_without_ssn": "Continuar sin SSN",
        "continue_without_id": "Continuar sin identificación",
    },

    "home": {
        heading: "Bienvenido/a a PA Vote For Change.",
        content: "Seleccione una de las opciones a continuación para actualizar o comenzar la inscripción de votante.",
        id_required_heading: "Se requiere ID de PennDOT",
        id_required_body: "Para poder enviar una solicitud de papeleta por correo, se requiere un número de ID de PennDot.",
    },
    "comingsoon": {
        heading: "Welcome to PA Vote For Change.",
        content: "Online voter registration coming soon.",
    },
    "additional_questions": {
        heading: "¿Le gustaría ser trabajador electoral el día de las elecciones?",
        content: "Indique su preferencia a continuación.",
    },
    "address_information": {
        heading: "Facilítenos sus datos a continuación.",
    },
    "add_signature": {
        heading: "Revise la declaración y firme para verificar su inscripción.",
        review_your_signature: "Revise su firma:",
        review_signature_terms_confirm: "CONFIRMO QUE LEÍ Y QUE ACEPTO LOS TÉRMINOS ANTERIORES.",
        review_signature_sign: "Firme aquí utilizando el ratón o el dedo si está usando un dispositivo móvil:",
    },
    "assistance_add_signature": {
        heading: "Asistente: revise la declaración a continuación y firme para confirmar que está de acuerdo con los términos.",
        review_your_signature: "Revise su firma:",
        review_signature_terms_confirm: "CONFIRMO QUE LEÍ Y QUE ACEPTO LOS TÉRMINOS ANTERIORES.",
        review_signature_sign: "Firme aquí utilizando el ratón o el dedo si está usando un dispositivo móvil:",
    },
    "assistance_information": {
        heading: "Pídale al asistente que proporcione su dirección y firma.",
        content: "Añada su nombre y su dirección a continuación.",
    },
    "confirmation": {
        heading: "¡Inscripción en curso!",
        content: "¡Gracias por presentar su solicitud de inscripción electoral!",
        content_with_email: "Le enviaremos un correo electrónico de confirmación y nos mantendremos en contacto con usted para informarle de otros servicios el día de la votación.",
        support: <>
            <p>La decisión final sobre su registro del votante la toma la oficina de registros del votante de su condado. Si tiene alguna pregunta sobre su solicitud, comuníquese con su condado y recibirá una explicación. Para obtener más ayuda, envíenos un correo electrónico a <a className="underline" href="mailto:help@pavoteforchange.com">help@pavoteforchange.com</a>.</p>
        </>,
    },
    "vbm_confirmation": {
        heading: "Procesamiento de la solicitud de Voto por correo",
        content: "¡Gracias por enviar su solicitud de Voto por correo!",
        content_with_email: "Le enviaremos un correo electrónico de confirmación y nos mantendremos en contacto acerca de otros servicios cercanos al día de la votación.",
        support: <>
            <p>La decisión sobre su solicitud de Voto por correo la realiza en última instancia la oficina de registro de votantes de su condado. Si tiene alguna pregunta sobre su solicitud, por favor comuníquese con su condado para aclaración. Para más soporte, por favor envíenos un correo electrónico a <a href="mailto:help@pavoteforchange.com" className="underline">help@pavoteforchange.com</a>.</p>
        </>,
    },
    "current_address": {
        heading: "Ingrese su dirección residencial actual.",
    },
    "eligibility_age": {
        valid_age_question: "¿Tendrá 18 años cumplidos antes o el día de las elecciones?",
    },

    "eligibility_citizenship": {
        us_citizen_question: "¿Es usted ciudadano de los EE.UU.?",
    },
    "eligibility_notice": {
        heading: "Para inscribirse debe: ",
        content:
            <ul>
                <li>Ser un <u>ciudadano de los Estados Unidos</u> por lo menos 30 días antes de la próxima elección</li>
                <li>Ser un <u>residente de Pennsylvania</u> y de su distrito electoral por lo menos 30 días antes de la próxima elección</li>
                <li>Tener <u>18 años cumplidos</u> en la fecha de las próximas elecciones ##DATE##</li>
            </ul>,
    },
    "had_assistance": {
        heading: "¿Alguien le ayudó a firmar esto?",
        content: "Si no puede firmar este formulario debido a una discapacidad física, es posible que un asistente lo haga en su nombre. Ese asistente debe aportar su firma y su dirección en el espacio previsto para ello. Al firmar, el asistente certifica que ha firmado el formulario a petición suya.",
    },
    "has_mailing_address": {
        heading: "¿Su dirección postal es la misma que su dirección residencial?",
        mailing_ask_yes: "Sí, mi dirección postal y residencial son las mismas.",
        mailing_ask_no: "No, tengo una dirección postal diferente. ",
    },
    "id_entry": {
        heading: "Ingrese la información de su identificación de PennDot.",
        content: "Rellene el formulario a continuación en función de sus datos actuales. Tenga en cuenta que no es aquí donde se actualiza su nombre o dirección.",
        no_id_heading: "Se ha seleccionado la opción de identificación de PennDot, pero no se ha ingresado ningún número.",
        no_id_body: "¿Desea continuar sin ingresar un número de identificación de PennDot?",
    },
    "id_verification": {
        heading: "¿Tiene un número de licencia de conducir de PA o de tarjeta de identificación de PennDOT?",
        content: "La forma más fácil de inscribirlo/a es con su licencia de conducir o su identificación de PennDOT, pero también podemos hacerlo si tiene un número de Seguridad Social. ",
        license_ask_have_id: "Tengo mi número de licencia de conducir de PA o de tarjeta de identificación de PennDOT conmigo en este momento.",
        license_ask_no_id: "Tengo un número de licencia de conducir de PA o de tarjeta de identificación de PennDOT, pero no lo tengo conmigo en este momento.",
        license_ask_have_ssn: "No tengo un número de licencia de conducir de PA o de tarjeta de identificación de PennDOT, pero tengo un número de Seguridad Social.",
        license_ask_no_id_ssn: "No tengo una licencia de conducir de PA o una tarjeta de identificación de PennDOT ni un número de Seguridad Social.",
        hava_disclaimer: <em><span className="text-xs">Por favor tenga en cuenta que si no proporciona un número de identificación de PennDOT, o si el número que proporciona no es correcto, su condado se pondrá en contacto con usted para completar su solicitud.</span></em>,
    },
    "last_four_entry": {
        heading: "Dado que no tiene un número de pennDOT, ingrese los últimos 4 dígitos de su número de Seguridad Social.",
        with_id_heading: "Ingrese los últimos 4 dígitos de su número de Seguro Social.",
        no_ssn_heading: "Se ha seleccionado la opción de identificación de PennDot, pero no se ha ingresado ningún número.",
        no_ssn_body: "¿Desea continuar sin ingresar un número de identificación de PennDot?",
    },
    "mailing_address": {
        heading: "Indíquenos su dirección postal.",
        content: "Necesitaremos su dirección postal actual para inscribirlo/a.",
    },
    "new_address": {
        heading: "Ingrese su nueva dirección.",
    },
    "new_name": {
        heading: "Ingrese su nuevo nombre.",
    },
    "not_eligible": {
        heading: "No podremos inscribirlo/a hoy.",
        content: "¡Seguiremos en contacto para informar sobre otros servicios que se ofrecerán el día de la votación!",
    },
    "page_not_found": {
        heading: "No se encontró la página",
    },
    "paper_application": {
        heading: "Pídale a su encuestador una solicitud en papel.",
        content: "Como no tiene una dirección permanente, necesitaremos que rellene una solicitud en papel. No se preocupe, ¡aún así le ayudaremos a inscribirse!",
    },
    "party_affiliation": {
        heading: "¿Cuál es su afiliación política?",
    },
    "previous_address": {
        heading: "Ingrese su dirección de inscripción anterior.",
        content: <>Si se mudó hace poco a Pennsylvania desde otro estado, debe <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>presentar una nueva inscripción</Link> en lugar de un cambio de dirección.</>
    },
    "previous_name": {
        heading: "Ingrese su nombre anterior",
    },
    "privacy": {
        heading: "Política de privacidad",
        content: <>
            <p>Esta Política de privacidad y los términos de uso se aplican a todos los usuarios de la solicitud móvil de inscripción de votantes PA Vote For Change (la "Aplicación"), incluidos los solicitantes, encuestadores y organizaciones, según se definen esos términos a continuación ("usuarios" o "usted").</p>
            <p>La aplicación está disponible en PA Vote for Change LLC ("PA Vote for Change", "nosotros" o "nuestro"), que está autorizada para permitir a otras organizaciones ("Organizaciones"), a través de sus empleados, contratistas o voluntarios ("Encuestadores"), utilizar la aplicación para inscribirse a las personas para votar en el Estado de Pensilvania ("Registrantes").   Antes de utilizar o enviar cualquier información en relación con la aplicación, revise cuidadosamente esta Política de Privacidad y los términos de uso. Al utilizar la aplicación, usted acepta la recopilación, el uso y la divulgación de su información, así como los términos y condiciones de uso, como se indica a continuación. </p>
            <p>Continuaremos revisando estas políticas y es posible que hagamos cambios en ellas de vez en cuando. La fecha al final de esta página indica la última vez que se hicieron cambios. Debe consultar esta página periódicamente para ver si hay actualizaciones. Su uso continuado de la aplicación significará que ha leído, entendido y aceptado los términos de la Política de privacidad y los Términos de uso actualizados.</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>POLÍTICA DE PRIVACIDAD</strong></p>
            <p><strong>Para inscritos</strong></p>
            <p><strong>Solo puede ingresar información en la aplicación para inscribirse para votar si es ciudadano de los EE. UU. y reúne los requisitos para inscribirse, como se señala en las indicaciones de la aplicación.</strong></p>
            <p>La aplicación le pedirá que ingrese información sobre usted que finalmente se envía a la Oficina del Comisionado de su Condado/Junta Electoral para inscribirse para votar.  Esta información puede incluir:</p>
            <ul>
                <li>Nombre completo; </li>
                <li>Fecha; </li>
                <li>Dirección de residencia; </li>
                <li>Dirección de envío;</li>
                <li>Dirección de residencia anterior inmediatamente antes de mudarse a la residencia actual;</li>
                <li>Fecha de nacimiento; </li>
                <li>Raza;</li>
                <li>Partido;</li>
                <li>Sexo;</li>
                <li>Número de teléfono;</li>
                <li>Dirección de correo electrónico;</li>
                <li>Número de identificación de Penndot o los últimos 4 dígitos de su número de seguridad social;</li>
                <li>Si ha residido en el estado de Pensilvania durante al menos 30 días antes del próximo el día de las elecciones;</li>
                <li>Si es ciudadano de los Estados Unidos de América durante al menos 30 días antes del próximo el día de las elecciones;</li>
                <li>Si tendrá 18 años o más el próximo el día de las elecciones; </li>
                <li>Si necesita ayuda para votar;</li>
                <li>Si está interesado en ser un trabajador electoral;</li>
                <li>Si desea solicitar el voto por correo o, si ya votó por correo, si desea solicitar que se transfiera la información de la solicitud del año pasado para recibir una papeleta por correo nuevamente;</li>
                <li>Su número de licencia de conducir; y</li>
                <li>Una imagen de su firma</li>
            </ul>
            <p>La Organización que recoge esta información de usted la enviará a la Oficina del Comisionado de su Condado/Junta Electoral a fin de inscribirlo para votar. La organización en cuyo nombre el encuestador recopila su información puede conservar la información anterior sobre usted, a excepción de su número de licencia de conducir y los últimos cuatro dígitos de su número de seguro social. La Organización podrá utilizar esta información para las siguientes finalidades: </p>
            <ul>
                <li>Para contactarlo con información sobre cómo, dónde y cuándo votar;</li>
                <li>Para contactarlo con el fin alentarlo a votar en las elecciones;</li>
                <li>Para contactarlo con otra información que la Organización crea que puede ser de su interés;</li>
                <li>Para fines de investigación cuando se combinan con información de otros inscritos y encuestadores, incluyendo, a modo de ejemplo, para analizar y estudiar los patrones de inscripción de votantes, la eficacia de los diversos métodos y enfoques de inscripción de votantes, y las características de los nuevos inscritos; </li>
                <li> Para mantener o mejorar la aplicación; y</li>
                <li>Para operaciones comerciales, incluida la seguridad, la prevención del fraude y el cumplimiento legal.</li>
            </ul>
            <p>La Organización no utilizará su información para ningún propósito comercial, incluyendo solicitarle que compre bienes o servicios, o para cualquier propósito prohibido. </p>
            <p>La Organización podrá compartir o divulgar su información (excepto su número de licencia de conducir y los últimos cuatro dígitos de su Número de Seguro Social) bajo las siguientes circunstancias:</p>
            <ul>
                <li>A agencias gubernamentales estatales, locales o federales si y cuando así lo exija la ley aplicable;</li>
                <li>A instituciones académicas o de investigación o proveedores con fines no comerciales; </li>
                <li>A otras organizaciones no partidistas involucradas en la inscripción de votante y esfuerzos de movilización con fines no comerciales;</li>
                <li>A PA Vote for Change y su familia de organizaciones, así como a los financiadores de la solicitud </li>
                <li>Si la Organización adquiere, fusiona o se asocia con otras organizaciones; y</li>
                <li>Si la información es agregada o no identificada.</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>Para los encuestadores</strong></p>
            <p>Los encuestadores deben utilizar la aplicación solo de acuerdo con las instrucciones y la formación que se les ha proporcionado.</p>
            <p>Los encuestadores no pueden retener personalmente ninguna información de ningún tipo recopilada de los Registrantes que utilizan la aplicación, y no pueden compartir dicha información con ninguna otra persona o entidad que no sea la Organización en cuyo nombre el encuestador está utilizando la aplicación. </p>
            <p>Se les pedirá a los encuestadores que ingresen cierta información sobre ellos mismos antes de cada turno que intente inscribir los votantes usando la aplicación.  Esto puede incluir su nombre, dirección de correo electrónico, nombre de usuario y contraseña, código de socio y la fecha, hora y ubicación de cada turno que intenta inscribir votantes usando la aplicación.</p>
            <p>	La información que envíe se utilizará para confirmar que es un encuestador con derecho a acceder a la aplicación. Además, la información que envíe y la información sobre la inscripción de votantes que complete pueden ser utilizados para los siguientes propósitos:	</p>
            <ul>
                <li>Para seguir el progreso en los programas de inscripción de votantes de la Organización;</li>
                <li>Para ponernos en contacto con usted con el fin de obtener más información sobre algún registro concreto que haya recopilado;</li>
                <li>Para contactarle con información que la Organización cree que puede ser de su interés;</li>
                <li>Para fines investigativos cuando se combinan con información de otros encuestadores y solicitantes de registro, incluyendo, a modo de ejemplo, para estudiar y analizar los patrones de inscripción de votantes, la eficacia de los métodos y enfoques de inscripción de votantes, y las características de los nuevos solicitantes de registro;</li>
                <li>Para mantener o mejorar la aplicación; y</li>
                <li>Para operaciones comerciales, incluida la seguridad, la prevención del fraude y el cumplimiento legal.</li>
            </ul>
            <p>La Organización no utilizará su información para ningún propósito comercial, incluyendo solicitarle que compre bienes o servicios, o para cualquier propósito prohibido. </p>
            <p>La Organización puede compartir o divulgar su información en las siguientes circunstancias:</p>
            <ul>
                <li>A agencias gubernamentales estatales, locales o federales, siempre y cuando lo exija la legislación aplicable;</li>
                <li>A instituciones académicas o de investigación o proveedores con fines no comerciales; </li>
                <li>A otras organizaciones no partidistas involucradas en la inscripción de votante y esfuerzos de movilización con fines no comerciales;</li>
                <li>A PA Vote for Change y su familia de organizaciones, así como a los financiadores de la solicitud </li>
                <li>Si la Organización adquiere, fusiona o se asocia con otras organizaciones; y</li>
                <li>Si la información es agregada o no identificada.</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>Para Organizaciones </strong></p>
            <p>La siguiente información recopilada de los inscritos solo puede ser transmitida a las autoridades electorales y NO PUEDE ser retenida por su organización: </p>
            <ul>
                <li>Número de licencia de conducir</li>
                <li>Últimos cuatro dígitos del Número de Seguro Social de un Registrante</li>
            </ul>
            <p>Otra información recopilada a través de la Aplicación de los inscritos y los encuestadores puede ser conservada por la Organización. La Organización podrá utilizar esta información para las siguientes finalidades: </p>
            <ul>
                <li>Para contactar a los inscritos con información sobre cómo, dónde y cuándo votar;</li>
                <li>Contactar con los inscritos para animarlos a votar en las elecciones;</li>
                <li>Seguir el progreso de los programas de la inscripción de votantes de la Organización;</li>
                <li>Para contactar a los encuestadores a fin de obtener más información, si es necesario, sobre cualquier registro en particular recopilado;</li>
                <li>Para ponerse en contacto con los inscritos y los encuestadores para proporcionarles información que la Organización considere de su interés;</li>
                <li>Para fines de investigación cuando se combinan con información de otros inscritos y encuestadores, incluyendo, a modo de ejemplo, para analizar y estudiar los patrones de inscripción de votantes, la eficacia de los diversos métodos y enfoques de inscripción de votante, y las características de los nuevos inscritos; </li>
                <li>Para mantener o mejorar la aplicación; y</li>
                <li>Para operaciones comerciales, incluida la seguridad, la prevención del fraude y el cumplimiento legal.</li>
            </ul>
            <p>La Organización no puede utilizar dicha información para ningún propósito comercial, incluida la solicitud de compra de bienes o servicios de solicitantes de registro o encuestadores, o para cualquier propósito prohibido por la ley aplicable. </p>
            <p>La Organización puede compartir o divulgar información obtenida de los registrantes y encuestadores (excepto los números de licencia de conducir y los últimos cuatro dígitos de sus Números de Seguro Social) en las siguientes circunstancias:</p>
            <ul>
                <li>A agencias gubernamentales estatales, locales o federales, siempre y cuando lo exija la legislación aplicable;</li>
                <li>A instituciones académicas o de investigación o proveedores con fines no comerciales; </li>
                <li>A otras organizaciones no partidistas involucradas en la inscripción de votante y esfuerzos de movilización con fines no comerciales;</li>
                <li>A PA Vote for Change y su familia de organizaciones, así como a los financiadores de la solicitud </li>
                <li>Si la Organización adquiere, fusiona o se asocia con otras organizaciones; y</li>
                <li>Si la información es agregada o no identificada.</li>
            </ul>
            <p>&nbsp;</p>
            <p>Si tiene alguna pregunta sobre esta política de privacidad o los términos de uso, puede comunicarse con nosotros enviándonos un correo electrónico a help@pavoteforchange.com.</p>
            <p>&nbsp;</p>
            <p>Última actualización: 9/21/2022</p>
        </>,
    },
    "registration": {
        heading_1: "¡Comencemos con su registro!",
        heading_2: "Actualizar Registro",
        heading_vmb_only: "Solicitar una papeleta por correo",
        content: "Confirme o aporte la información siguiente.",
    },
    "registration_address": {
        heading: "Ingrese su dirección de inscripción.",
    },
    "review": {
        heading: "Confirme la información que aportó.",
        content: "Confirme que la información que aparece a continuación es correcta. Si la información es incorrecta, haga clic en el icono de edición en la esquina superior derecha de cada sección. ",
        personal_info_heading: "Información personal actual",
        residential_heading: "Información residencial",
        license_heading: "Licencia de conducir de PA o identificación de PennDOT",
        ssn_heading: "N.º de Seguridad Social",
        prev_name_heading: "Nombre anterior",
        prev_address_heading: "Dirección anterior",
        additional_info_heading: "Información adicional",
        email_optin: "Correo electrónico para el registro",
        phone_optin: "Teléfono para el registro",
        county: "Condado",
        municipality: "Municipalidad",
        poll_worker: "Trabajador electoral",
        bilingual_interpreter: "Intérprete bilingüe",
        languages: "Idioma(s)",
        requires_help_to_vote: "¿Necesita ayuda para votar?",
        assistance_needed: "Ayuda necesaria",
        preferred_language: "Idioma preferido",
        have_mailing_address: "Tengo una dirección postal",
        ballot_request: "SOLICITUD ANUAL DE BOLETA PARA VOTAR POR CORREO",
    
    },
    "required_help": {
        heading: "¿Necesita ayuda para votar?",
        content: "Indique su preferencia a continuación.",
        label: "Yo necesito ayuda para votar. Necesito este tipo de asistencia:",
        language_label: "Yo necesito ayuda con el idioma. Mi idioma preferido es:",
        type_error: "Seleccione el tipo de ayuda que necesita.",
    },
    "stay_in_touch": {
        heading: "¡Sigamos en contacto!",
        content: "Seguiremos en contacto sobre nuestra organización.",
    },
    "thank_you": {
        heading: "¡Gracias!",
        content: "¡Seguiremos en contacto para informar sobre otros servicios el día de la votación!",
    },
    "terms": {
        heading: "Términos y condiciones",
        content: <>
            <p>Esta Política de privacidad y los términos de uso se aplican a todos los usuarios de la solicitud móvil de inscripción de votantes PA Vote For Change (la "Aplicación"), incluidos los solicitantes, encuestadores y organizaciones, según se definen esos términos a continuación ("usuarios" o "usted").</p>
            <p>La aplicación está disponible en PA Vote for Change LLC ("PA Vote for Change", "nosotros" o "nuestro"), que está autorizada para permitir a otras organizaciones ("Organizaciones"), a través de sus empleados, contratistas o voluntarios ("Encuestadores"), utilizar la aplicación para inscribirse a las personas para votar en el Estado de Pensilvania ("Registrantes").   Antes de utilizar o enviar cualquier información en relación con la aplicación, revise cuidadosamente esta Política de Privacidad y los términos de uso. Al utilizar la aplicación, usted acepta la recopilación, el uso y la divulgación de su información, así como los términos y condiciones de uso, como se indica a continuación. </p>
            <p>Continuaremos revisando estas políticas y es posible que hagamos cambios en ellas de vez en cuando. La fecha al final de esta página indica la última vez que se hicieron cambios. Debe consultar esta página periódicamente para ver si hay actualizaciones. Su uso continuado de la aplicación significará que ha leído, entendido y aceptado los términos de la Política de privacidad y los Términos de uso actualizados.</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>TÉRMINOS DE USO</strong></p>
            <ol className="list-decimal">
                <li className="pb-8">
                    <strong>Acceso a la aplicación.</strong> Cada miembro del jurado que accede a la aplicación garantiza y representa a PA Vote for Change LLC ("PA Vote for Change", "nosotros" o "nuestro") que el encuestador está autorizado a usar la aplicación por una organización autorizada por PA Vote for Change.   Cada encuestador declara que la información que proporciona en la aplicación es precisa, completa y actual. Cada registrante que utiliza la aplicación garantiza y declara que tiene derecho a votar en los Estados Unidos y que la información que proporciona es precisa, completa y actual.<br /><br />
                    Cada encuestador es responsable de salvaguardar el código de socio y la contraseña utilizados para acceder a la aplicación y se compromete a no revelar el código de socio ni la contraseña a ninguna otra persona o entidad.  El encuestador debe notificar a la Organización de la que es empleado, contratista o voluntario, inmediatamente después de tener conocimiento de cualquier violación de la seguridad o del uso no autorizado de la cuenta del encuestador.  La organización debe notificar inmediatamente a PA Vote for Change.
                </li>
                <li className="pb-8">
                    <strong>Propiedad intelectual.</strong> Cada usuario de la aplicación (incluido cada registrante, solicitante y organización) acepta que, como entre dicho usuario y PA Vote for Change, todos los derechos de autor, marca registrada y otros derechos de propiedad intelectual en y para el software a través del cual opera la Aplicación en cualquier forma, el código objeto o el código fuente, y la arquitectura, las características y la funcionalidad de la aplicación son y seguirán siendo propiedad única y exclusiva de PA Vote for Change, cada usuario también acepta que no obtiene derechos de propiedad en y para la aplicación a través de su uso.
                </li>
                <li className="pb-8">
                    <strong>Terminación.</strong> PA Vote for Change puede suspender o cancelar la cuenta de cualquier encuestador o suspender o cancelar el acceso de la organización a la aplicación, sin previo aviso ni responsabilidad, en caso de que el encuestador o la organización, según corresponda, infrinja la Política de privacidad o los Términos de uso.
                </li>
                <li className="pb-8">
                    <strong>Limitación de responsabilidad.</strong> En ningún caso PA Vote for Change será responsable de los daños, costos, multas o sanciones incurridos por la organización o cualquier encuestador o registrante o cualquier otra persona o entidad como resultado del uso de la aplicación.  En la medida máxima permitida por la ley aplicable, en ningún caso PA Vote for Change será responsable de algún daño especial, incidental, indirecto o consecuente incluidos, entre otros, daños por pérdida de ganancias, pérdida de datos u otra información, por interrupciones comerciales, lesiones personales, pérdida de privacidad que surja o esté relacionada de alguna manera con el uso o la incapacidad de usar la aplicación, el software o el hardware de terceros utilizado con la aplicación o en el que se opera la aplicación, incluso si PA Vote for Change ha sido advertido de la posibilidad de tales daños e incluso si el remedio no cumple con su propósito esencial.<br /><br />
                    Algunos estados no permiten la exclusión de garantías implícitas o la limitación de responsabilidad por daños incidentales o consecuentes, lo que significa que algunas de las limitaciones anteriores pueden no aplicarse.  En estos estados, la responsabilidad de cada parte se limitará en la mayor medida permitida por la ley.<br /><br />
                    Entre PA Vote for Change y cada organización, encuestador y registrante, la aplicación se proporciona "tal cual" y "como está disponible" y con todas las fallas y efectos sin garantía de ningún tipo.  En la medida máxima permitida por la ley aplicable, PA Vote for Change, en su propio nombre y en nombre de la familia de organizaciones de PA Vote for Change, así como los patrocinadores de la aplicación y sus respectivos licenciadores y proveedores de servicios, renuncia expresamente a todas las garantías, ya sea expresa, implícita, estatutaria o de otro tipo, con respecto a la aplicación, incluidas todas las garantías implícitas de comerciabilidad, idoneidad para un propósito particular, título y no infracción, y garantías que puedan surgir del curso de la negociación, el curso del desempeño, el uso o práctica comercial. Sin limitación a lo anterior, PA Vote for Change no ofrece ninguna garantía o compromiso, y no expresa ninguna promesa de que la aplicación cumplirá con los requisitos de cualquier organización, logrará los resultados previstos, será compatible o funcionará con cualquier otro software, aplicaciones, sistemas o servicios, operará sin interrupción, cumplirá con cualquier estándar de rendimiento o confiabilidad, ni de que estará libre de errores o que cualquier error o defecto podrá ser o será corregido.<br /><br />
                    Sin limitar lo anterior, PA Vote for Change ofrece cualquier promesa o garantía de cualquier tipo, expresa o implícita: (i) en cuanto al funcionamiento o la disponibilidad de la aplicación, la información, el contenido y los materiales o productos incluidos en ella; (ii) que la aplicación permanecerá ininterrumpida o libre de errores; (iii) en cuanto a la exactitud, fiabilidad o actualidad de cualquier información o contenido proporcionado a través de la aplicación; o (iv) que la aplicación, sus servidores, el contenido o los correos electrónicos enviados desde o en nombre de PA Vote for Change estarán libres de virus, secuencias de comandos, troyanos, gusanos, malware, bombas de tiempo u otros componentes dañinos.<br /><br />
                    Algunas jurisdicciones no permiten la exclusión de ciertos tipos de garantías o limitaciones de los derechos legales aplicables de un consumidor, por lo que es posible que no se apliquen algunas o todas las exclusiones y limitaciones anteriores. Pero en tal caso, las exclusiones y limitaciones establecidas en esta sección se aplicarán en la mayor medida exigible según la ley aplicable.
                </li>
                <li className="pb-8">
                    <strong>Ley aplicable.</strong> Estos términos se regirán e interpretarán de conformidad con las leyes del estado de Pensilvania.
                </li>
                <li className="pb-8">
                    <strong>Divisibilidad. </strong> Si alguna disposición de estos términos se considera inaplicable o inválida, dicha disposición se modificará e interpretará para lograr los objetivos de la misma en la mayor medida posible, según la ley aplicable y las disposiciones restantes continuarán en pleno vigor y efecto.
                </li>
                <li className="pb-8">
                    <strong>Exención.</strong> Salvo lo dispuesto en el presente documento, la falta de ejercicio de un derecho o exigir el cumplimiento de una obligación en virtud de estos términos no afectará a la capacidad de una parte para ejercer dicho derecho o exigir dicho cumplimiento en cualquier momento posterior, ni la renuncia a un incumplimiento constituirá una renuncia a cualquier incumplimiento posterior.
                </li>
            </ol>
            <p>Si tiene alguna pregunta sobre esta política de privacidad o los términos de uso, puede comunicarse con nosotros enviándonos un correo electrónico a help@pavoteforchange.com.</p>
            <p>&nbsp;</p>
            <p>Última actualización: 9/21/2022</p>
        </>,
    },
    "update_information": {
        heading: "¿Necesitas hacer algún cambio en tu registro?",
        content: "Seleccione todas las opciones que correspondan:",
        error: "Marque al menos una casilla.",
        change_name: "Necesito cambiar mi nombre en mi inscripción de votante.",
        change_address: <>Necesito cambiar la dirección vinculada a mi inscripción de votante.<br /><em><span className="text-xs">Si usted se ha mudado recientemente a Pennsylvania de otro estado, debe <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>someter una nueva inscripción</Link> en vez de un cambio de dirección.</span></em></>,
        change_party: "Necesito cambiar mi afiliación de partido político.",
        federal_employee: "Soy un empleado federal o estatal y me estoy inscribiendo en mi condado de residencia más reciente.",

    },
    "ballot_request": {
        heading: "SOLICITUD ANUAL DE BOLETA PARA VOTAR POR CORREO",
        content: "Si le gustaría recibir boletas por correo para lo que queda de este año y si quisiera recibir automáticamente una solicitud anual de boleta para votar por correo cada año, indíquelo a continuación.",
        content2: "Su nombre, domicilio, condado, fecha de nacimiento y número de licencia de conducir de PA o tarjeta de identificación del PennDOT proporcionados en las pantallas anteriores se utilizarán para su solicitud de voto por correo.",
        label: "¿Le gustaría recibir boletas por correo para cualquier elección a la que usted clasifique para participar?",
        address_type_label: "Enviar por correo la boleta para votar a la siguiente dirección:",
        address_type_1: "Dirección residencial",
        address_type_2: "Dirección postal",
        address_type_3: "Dirección alternativa",
        ballot_request_type_error: "Debe seleccionar una dirección donde se debe enviar su boleta.",
        ballot_ward_label: "Por favor, indique su distrito o distrito de votación (si se conoce)",
        ballot_lived_since_label: "Vivo en esta dirección desde el:",
        ballot_lived_since_error: "Indique el año en que se mudó a esta dirección.",
        ballot_lived_since_error_2: "Proporcione un año válido.",
        id_text: "Identificación: El número de licencia de conducir de PA o tarjeta de identificación del PennDOT proporcionado anteriormente se utilizará para su solicitud de voto por correo. Si esta información no es correcta, realice las modificaciones necesarias en Información de identificación.",
        id_field: "Número de licencia de conducir de PA o tarjeta de identificación del PennDOT:",
        annual_request: "Indique a continuación si desea hacer su solicitud para recibir papeletas de voto por correo por el resto de este año y si desea recibir automáticamente una solicitud anual para recibir sus papeletas por correo cada año. Si usted actualiza su inscripción de votante porque se mudó fuera del condado después de presentar una solicitud anual para una papeleta de voto por correo, asegúrese de que su estado anual se transfiera al actualizar su dirección.",
        annual_request_field: "Me gustaría recibir papeletas por correo este año y recibir solicitudes anuales de papeletas por correo cada año.",
    
        confirmation_heading: "Asistente: revise la declaración a continuación y firme para confirmar que está de acuerdo con los términos.",

        review_terms_confirm: "CONFIRMO QUE LEÍ Y QUE ACEPTO LOS TÉRMINOS ANTERIORES.",
    },
}

const chinese = {
    "general": {
        "settings": "設置",
        "shift_time": "輪班時間",
        "registrations": "登記",
        "waiting_to_upload": "等候上傳",
        "upload_data": "上傳數據",
        "end_shift": "結束輪班",
        "privacy_policy": "隱私政策",
        "terms_conditions": "條款和條件",
        "knowledge_base": "知識庫",
        "saving_data": "正在保存登記數據……",
        "new_registration_heading": "您是否確定您希望開始新的登記？",
        "new_registration_body": "如果您開始新的登記，當前的登記資訊將被清除。",
        "powered_by": "Powered by ",
    },
    "fields": {
        "first_name": "名字",
        "last_name": "姓氏",
        "middle_name": "中間名/首字母",
        "suffix": "字尾",
        "birth_date": "出生日期 (月/日/年)",
        "race": "種族（選填）",
        "sex": "性別",
        "email_address": "電子郵箱",
        "phone_number": "電話",
        "phone_type_select_label": "選擇電話類型……",
        "phone_type_select": "- 選擇類型 - ",
        "phone_type": "電話類型",
        "cell_phone": "手機",
        "home_phone": "家庭電話",
        "work_phone": "工作電話",
        "license_id": "賓州駕照或賓州交通局身份證號",
        "last_four_ssn": "社會安全號碼",
        "street_address": "街道地址",
        "street_address_2": "街道地址 2",
        "unit_type": " 單位類型（如適用)",
        "unit_number": "單位編號（如適用)",
        "city": "城市",
        "state": "州",
        "zipcode": "郵遞區號",
        "county": "您居住的縣",
        "municipality": "您居住的市",
        "assistant_name": "協助此表格填寫完成者之姓名",
        "previous_first_name": "過去名字",
        "previous_last_name": "過去姓氏",
        "previous_middle_name": "過去中間名",
        "is_interested_poll_worker": "我想在選舉日成為選舉工作人員",
        "is_interested_interpreter": "我想在選舉日成為雙語口譯員",
        "interpreter_language": "我使用此語言：",
        "no_address": "我沒有街道地址或永久居住地。",
        "yes_assistance": "是，曾有人協助我簽署。 ",
        "no_assistance": "否，我曾代表自己簽署。",
        "last_four": "請輸入您社會安全號碼的最後四位數字。",
        "mailing_address": "郵寄地址",
        "mailing_address_2": "郵寄地址 2",
        "mailing_city": "郵寄城市",
        "mailing_state": "郵寄州",
        "mailing_zipcode": "郵遞區號",
        "mailing_country": "郵寄國家",
        "mailing_address_in_usa": "該地址是否在美國？",
        "mailing_address_is_my": "郵寄地址是我的……（例如度假屋、臨時住所等）",
        "political_party": "政黨",
        "party_other": "如果為其他，請說明：",
        "previous_address": "街道地址",
        "previous_city": "城市",
        "previous_state": "州",
        "previous_county": "縣",
        "previous_zipcode": "郵遞區號",
        'na': '不適用',

        "ballot_mailing_address": "郵寄選票地址",
        "ballot_mailing_city": "郵寄選票城市",
        "ballot_mailing_state": "郵寄選票州",
        "ballot_mailing_zipcode": "郵寄選票郵遞區號 (如12345 或 12345-6789)",
        "ballot_mailing_zipcode_short": "郵寄選票郵遞區號",
        "ballot_mailing_country": "將選票郵寄至國家",
        "ballot_mailing_ward_short": "選區或選舉區",
        "ballot_mailing_lived_since_short": "開始居住在該地址的時間",


    },
    "placeholders": {
        "dropdown": "- 選擇 -",
        "first_name": "Jane",
        "last_name": "Doe",
        "middle_name": "Henry",
        "suffix": "Jr.、II 等",
        "birth_date": "月/日/年",
        "email_address": "you@example.com",
        "phone_number": "555-555-5555",
        "select_an_item": "選擇一項……",
        "select_phone_type": "選擇電話類型……",
        "license_id": "12 345 678",
        "street_address": "5555 Oak St.",
        "street_address_2": "5555 Oak St.",
        "unit_number": "40B",
        "city": "城市",
        "zipcode": "12345",
        "municipality": "先選擇縣……",
        "political_party": "政黨",
        "mailing_address_is_my": "度假屋、臨時住所等",
    },
    "validation": {
        "id": "您輸入的賓州駕照或賓州交通局身份證資料無效。請確認您的賓州駕照或賓州交通局身份證資料。",
        "first_name": "名字必填。",
        "last_name": "姓氏必填。",
        "middle_name": "您的中間名無效。",
        "birth_date": "出生日期為必填。 ",
        "birth_date_valid": "請輸入一個有效的出生日期。",
        "email_address_format": "電子郵件格式不正確。",
        "phone_number_required": "電話號碼為必填。",
        "phone_number_valid": "提供的電話號碼無效。請輸入有效電話號碼。",
        "license_id": "請提供一個數值。",
        "license_id_valid": "請輸入一個有效的執照號",
        "ssn": "請輸入您社會安全號碼的最後四位數字。",
        "street_address": "街道地址為必填。",
        "city": "城市為必填。",
        "state": "請選擇州。",
        "zipcode": "郵政編碼為必填。",
        "zipcode_valid": "請輸入有效的 5 位數郵遞區號或點擊表示您未擁有郵遞區號的方框。",
        "no_address_zipcode": "郵政編碼為必填。",
        "no_address_zipcode_valid": "請輸入有效的 5 位數郵遞區號或點擊表示您未擁有郵遞區號的方框。",
        "county": "您居住的縣為必填。 ",
        "signature_confirmation_title": "確認錯誤",
        "signature_confirmation_message": "請確認您已閱讀並同意條款。",
        "assistant_confirmation_title": "確認錯誤",
        "assistant_confirmation_message": "請說明在填寫此表格時提供了幫助。",
        "previous_first_name": "申請變更姓名需填入過去名字",
        "previous_last_name": "申請變更姓名需填入過去姓氏",
        "previous_middle_name": "您的中間名無效。",
        "select_option": "請選擇一項。",
        "provide_value": "請提供一個數值。",
        "no_address": "街道地址為必填。",
        "mailing_address": "街道地址為必填。",
        "mailing_city": "城市為必填。",
        "mailing_state": "請選擇州。",
        "mailing_zipcode": "郵政編碼為必填。",
        "mailing_address_is_my": "請為這一欄提供一個數值",
        "party": "請選擇一個政黨。",
        "party_other": "警告－尚未選取政黨。如果選取其他，則應填寫其他政黨文字框。",
        "previous_address": "申請變更地址需填入過去登記地址",
        "previous_city": "申請變更地址需填入過去登記城市",
        "previous_state": <>過去州必須是賓西法尼亞州。如果您最近從其他州搬至賓夕法尼亞州，則必須 <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>提交新登記</Link> 而非地址變更。</>,
        "previous_county": "申請變更地址需填入過去登記縣",
        "previous_zipcode": "申請變更地址需填入過去登記郵遞區號",
        "previous_zipcode_required": "申請變更地址需填入過去登記郵遞區號",
        "phone_type": "請選擇一項。",
    },
    "buttons": {
        "lets_get_started": "現在就開始吧",
        "new_registrant": "我是一名新登記人",
        "vbm_only": "我只希望申請獲得郵寄選票",
        "update_registration": "我需要更新登記",
        "no_changes": "我無需進行任何更改",
        "proceed": "繼續",
        "continue": "繼續",
        "cancel": "取消",
        "yes": "是",
        "no": "否",
        "no_ssn": "我沒有帶我的社會安全號碼",
        "next_step": "下一步",
        "update_information": "更新資訊",
        "add_signature": "添加簽名",
        "clear_signature": "清除簽名",
        "accept_signature": "接受簽名",
        "complete_registration": "完成登記",
        "complete_vbm": "完成透過郵寄投票申請",
        "start_new_registration": "開始新的登記",
        "continue_registration": "繼續登記",
        "sign_up": "註冊",
        "stay_in_touch": "保持聯繫",
        "back": "後退",
        "continue_without_ssn": "繼續未輸入SSN",
        "continue_without_id": "繼續未輸入PennDot證件",
    },

    "home": {
        heading: "歡迎來到賓州 Vote For Change。",
        content: "請選擇以下選項之一，然後更新或開始您的選民登記。",
        id_required_heading: "必須提供 PennDOT ID",
        id_required_body: "如要提交郵寄選票請求，必須提供 PennDot ID 號碼。",
    },
    "comingsoon": {
        heading: "Welcome to PA Vote For Change.",
        content: "Online voter registration coming soon.",
    },
    "additional_questions": {
        heading: "您是否希望在選舉日擔任選舉工作人員？",
        content: "請在下方說明您的偏好。",
    },
    "address_information": {
        heading: "請在下方為我們提供您的資訊。",
    },
    "add_signature": {
        heading: "檢視聲明並簽名，方可確認您的登記。",
        review_your_signature: "複查您的簽名：",
        review_signature_terms_confirm: "本人確認本人已閱讀並同意上述條款。",
        review_signature_sign: "使用您的鼠標或手指（若在移動設備上）在此登錄：",
    },
    "assistance_add_signature": {
        heading: "助理：請審閱下方聲明後簽名，以確認對這些條款的同意。",
        review_your_signature: "複查您的簽名：",
        review_signature_terms_confirm: "本人確認本人已閱讀並同意上述條款。",
        review_signature_sign: "使用您的鼠標或手指（若在移動設備上）在此登錄：",
    },
    "assistance_information": {
        heading: "請讓助理提供他們的地址和簽名。",
        content: "請在下方添加您的姓名和地址。",
    },
    "confirmation": {
        heading: "登記處理中！",
        content: "感謝您提交選民登記申請！",
        content_with_email: "我們將發給您一封確認電子郵件，並會保持聯繫，提供關於即將在投票日提供的其他服務之資訊。",
        support: <>
            <p>您所在縣的選民登記辦公室最終做出關於您的選民登記的決定。如果您對申請有任何疑問，請聯絡您所在的縣進行明確說明。如需進一步支援，請寄電子郵件至 <a className="underline" href="mailto:help@pavoteforchange.com">help@pavoteforchange.com</a> 聯絡我們。</p>
        </>,
    },
    "vbm_confirmation": {
        heading: "透過郵寄投票申請處理中",
        content: "感謝您提交透過郵寄投票申請！",
        content_with_email: "我們將發給您一封確認電子郵件，並會保持聯繫，提供關於即將在投票日提供的其他服務之資訊。",
        support: <>
            <p>是否批准您的透過郵寄投票申請之決定最終由您所在縣選民登記辦公室做出。您若有任何關於您的申請的問題，請聯繫您所在縣獲得清楚解答。如需進一步支持，請發送電子郵件至 <a href="mailto:help@pavoteforchange.com" className="underline">help@pavoteforchange.com</a>。</p>
        </>,
    },
    "current_address": {
        heading: "請輸入您當前的居住地址。",
    },
    "eligibility_age": {
        valid_age_question: "您在選舉日當天或之前會年滿 18 歲嗎？",
    },

    "eligibility_citizenship": {
        us_citizen_question: "您是美國公民嗎？",
    },
    "eligibility_notice": {
        heading: "如要登記，您必須：",
        content:
            <ul>
                <li>下次選舉前至少 30 天成為美國公民</li>
                <li>下次選舉前至少 30 天成為賓夕法尼亞州居民及選區居民</li>
                <li>下次選舉之選舉日當天至少年滿 18 歲 ##DATE##</li>
            </ul>,
    },
    "had_assistance": {
        heading: "是否曾有人協助您簽署本表？",
        content: "如果您由於身體殘障無法簽署本表格，您可請助理代表您完成這一步。該助理必須在所提供的空白處提供他或她的簽名和地址，簽名即表明該助理證實他或她曾在您的請求下簽署該表格。",
    },
    "has_mailing_address": {
        heading: "您的郵寄地址是否與您的住宅地址相同？",
        mailing_ask_yes: "是，我的郵寄和住宅地址是相同的。",
        mailing_ask_no: "否，我有不同的郵寄地址。 ",
    },
    "id_entry": {
        heading: "輸入您身份PennDOT證上的資訊。",
        content: "請根據您當前的資訊填寫下方表格。請注意，這裡不是您更新您的姓名和地址之處。",
        no_id_heading: "已選擇PennDot證件選項，但尚未輸入號碼。",
        no_id_body: "是否希望在不輸入PennDot證件號碼的情況下繼續?",
    },
    "id_verification": {
        heading: "您是否有賓州駕照或賓州交通局身份證號？",
        content: "我們為您登記的最快方式是使用您的駕照或賓州交通局身份證，但我們也可以在您有社會安全號碼時為您登記。 ",
        license_ask_have_id: "我現在有帶賓州駕照或賓州交通局身份證號。",
        license_ask_no_id: "我有賓州駕照或賓州交通局身份證號，但這些證件現在不在我身上。",
        license_ask_have_ssn: "我沒有賓州駕照或賓州交通局身份證號，但我有社會安全號碼。",
        license_ask_no_id_ssn: "我沒有賓州駕照或賓州交通局身份證，也沒有社會安全號碼。",
        hava_disclaimer: <em><span className="text-xs">請注意，如果您沒有提供 PennDOT ID 號碼，或者您提供的號碼不正確，您所在縣的工作人員將與您聯絡，以便完成您的申請流程。</span></em>,
    },
    "last_four_entry": {
        heading: "由於您沒有賓州交通局身份證號，請輸入您的社會安全號碼最後4位數。",
        with_id_heading: "請輸入您的社會安全號碼的 4 位尾號。",
        no_ssn_heading: "已選擇社會安全號碼（SSN）選項，但尚未輸入號碼。",
        no_ssn_body: "是否希望在不輸入社會安全號碼的情況下繼續？",
    },
    "mailing_address": {
        heading: "請為我們提供您的郵寄地址。",
        content: "我們將需要您當前的郵寄地址，才可完成您的登記。",
    },
    "new_address": {
        heading: "請輸入您的新地址。",
    },
    "new_name": {
        heading: "請輸入您的新姓名。",
    },
    "not_eligible": {
        heading: "我們無法在今天為您登記。 ",
        content: "我們將保持聯繫，提供關於即將在投票日提供的其他服務之資訊！",
    },
    "page_not_found": {
        heading: "頁面未找到",
    },
    "paper_application": {
        heading: "請要求您的拉票員提供紙質申請表。",
        content: "由於您沒有固定地址，我們將需要您填寫紙質申請表。不用擔心，我們還是會幫助您完成登記！",
    },
    "party_affiliation": {
        heading: "您的政黨從屬關係是什麼？",
    },
    "previous_address": {
        heading: "請輸入您過去登記的地址。",
        content: <>如果您最近從其他州搬至賓夕法尼亞州，則必須 <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>提交新登記</Link> 而非地址變更。</>
    },
    "previous_name": {
        heading: "請輸入您的過去姓名",
    },
    "privacy": {
        heading: "隱私政策",
        content: <>
            <p>本隱私政策和使用條款適用於所有 PA Vote For Change 選民登記行動應用程式（以下稱「應用程式」）的用戶，包括登記人、拉票人和組織，這些術語的定義見下文（「用戶」或」您」）。</p>
            <p>本應用程式是 PA Vote for Change LLC（以下稱「PA Vote for Change」 或「我們」）提供，我們獲授權准許其他組織（以下稱「組織」）透過其員工、承包商和/或志願者（以下稱「拉票人」）使用本應用程式在賓夕法尼亞州聯邦登記選民（以下稱「登記人」）。   在使用或提交與本應用程式相關的任何資訊之前，請仔細閱讀本隱私政策和使用條款。使用本應用程式即表明您同意收集、使用和披露您的資訊，並同意如下所述的使用條款和條件。</p>
            <p>我們將持續審查這些政策，並可能不時作出修訂。本頁底部的日期表示上一次修訂的時間。您應定期查看此頁面所載的更新資訊。您繼續使用本應用程式將表示您已閱讀、理解並接受更新的隱私政策和使用條款的各項條款。</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>隱私政策</strong></p>
            <p><strong>登記人</strong></p>
            <p><strong>如應用程式中的提示所述，僅當您是美國公民且符合登記條件時，您才可以在應用程式中鍵入資訊以登記投票。</strong></p>
            <p>該應用程式將要求您鍵入您的個人資訊，這些資訊最終將提交給您所在縣專員辦公室/選舉委員會，以便爲您登記投票。  這些資訊可能包括：</p>
            <ul>
                <li>姓名； </li>
                <li>日期； </li>
                <li>居住地址； </li>
                <li>郵寄地址；</li>
                <li>遷往現居住地之前的居住地地址；</li>
                <li>出生日期； </li>
                <li>種族；</li>
                <li>政黨；</li>
                <li>性別；</li>
                <li>電話號碼；</li>
                <li>電子郵件位址；</li>
                <li>Penndot ID 號碼和/或您的社會安全號碼的最後四位數；</li>
                <li>在下個選舉日之前，您是否已在賓夕法尼亞州聯邦居住至少 30 天；</li>
                <li>在下個選舉日之前至少 30 天，您是否是美利堅合眾國公民；</li>
                <li>在下個選舉日，您是否年滿 18 歲； </li>
                <li>您是否需要協助投票；</li>
                <li>您是否有興趣成爲投票站工作人員；</li>
                <li>您是否想申請郵寄投票，或者（如果您先前透過郵寄方式投票）您是否想要求從去年的申請中轉移資訊，以便再次接收郵寄選票；</li>
                <li>您的駕駛執照號碼；以及</li>
                <li>您的簽名圖像</li>
            </ul>
            <p>從您那裏收集這些資訊的組織將把這些資訊發送到您所在的縣的專員辦公室/選舉委員會，以便您登記投票。由其拉票人作爲代表收集您資訊的組織可以保留您的上述資訊，但您的駕駛執照號碼和社會安全號碼的最後四位數除外。該組織可將這些資訊用於下列目的： </p>
            <ul>
                <li>與您聯絡，告知您投票的方式、地點和時間；</li>
                <li>與您聯絡，鼓勵您參與選舉投票；</li>
                <li>與您聯絡，提供該組織認爲您可能感興趣的其他資訊；</li>
                <li>出於研究之目的，結合來自其他登記人和拉票人的資訊時，包括例如，分析和研究選民登記的模式、各種選民登記方法和途徑的有效性以及新登記人的特徵； </li>
                <li> 維護和/或改進該應用程式；以及</li>
                <li>用於業務運營，包括安全保障、預防欺詐和法律合規。</li>
            </ul>
            <p>該組織不會將您的資訊用於任何商業目的，包括誘使您購買商品或服務，或用於相關法律禁止的任何目的。 </p>
            <p>在下列情況下，該組織可能會分享和/或披露您的資訊（您的駕駛執照號碼和您的社會安全號碼的最後四位數除外）：</p>
            <ul>
                <li>適用法律要求時，提供給州、地方或聯邦政府機構；</li>
                <li>出於非商業目的提供給學術或研究機構或供應商； </li>
                <li>出於非商業目的提供給從事選民登記和動員工作的其他無黨派組織；</li>
                <li>提供給 PA Vote for Change 及其組織體系，以及應用程式的資助方 </li>
                <li>如果該組織收購、合併或與其他組織合作；以及</li>
                <li>如果資訊被整合和/或作爲去識別資訊。</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>拉票人</strong></p>
            <p>拉票人必須僅遵照其接受的指導和培訓使用該應用程式。</p>
            <p>拉票人個人不得保留從使用本應用程式的登記人處收集的任何類型的資訊，也不得與拉票人所代表的組織（代表其使用本應用程式）以外的任何其他人士或實體共享任何此類資訊。 </p>
            <p>在每次轉換拉票人試圖使用本應用程式登記選民之前，將要求他們鍵入特定的個人資訊。  這可能包括您的姓名、電子郵件位址、用戶名和密碼、合作夥伴代碼，以及每次轉換拉票人試圖使用應用程式登記選民的日期、時間和地點。</p>
            <p>您提交的資訊將用於確認您是有權存取該應用程式的拉票人。此外，您提交的資訊和您填寫的選民登記資訊可能用於以下目的：	</p>
            <ul>
                <li>追蹤該組織選民登記計劃的進展情況；</li>
                <li>與您聯絡，獲取有關您所收集的任何特定登記的更多資訊；</li>
                <li>就組織認為您可能感興趣的資訊與您聯絡；</li>
                <li>出於研究之目的，結合來自其他拉票人和登記人的資訊時，包括例如，研究和分析選民登記的模式、選民登記方法和途徑的有效性以及新登記人的特徵；</li>
                <li>維護和/或改進該應用程式；以及</li>
                <li>用於業務運營，包括安全保障、預防欺詐和法律合規。</li>
            </ul>
            <p>該組織不會將您的資訊用於任何商業目的，包括誘使您購買商品或服務，或用於相關法律禁止的任何目的。 </p>
            <p>該組織可在下列情況下分享及/或披露您的資訊：</p>
            <ul>
                <li>適用法律要求時，提供給州、地方或聯邦政府機構；</li>
                <li>出於非商業目的提供給學術或研究機構或供應商； </li>
                <li>出於非商業目的提供給從事選民登記和動員工作的其他無黨派組織；</li>
                <li>提供給 PA Vote for Change 及其組織體系，以及應用程式的資助方 </li>
                <li>如果該組織收購、合併或與其他組織合作；以及</li>
                <li>如果資訊被整合和/或作爲去識別資訊。</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>組織 </strong></p>
            <p>從登記人處收集的下列資訊僅可轉交給選舉當局，您的組織不得保留： </p>
            <ul>
                <li>駕駛執照號碼</li>
                <li>登記人社會安全號碼的最後四位數</li>
            </ul>
            <p>該組織可保留透過應用程式從登記人和拉票人處收集的其他資訊。該組織可將這些資訊用於下列目的： </p>
            <ul>
                <li>與登記人聯絡，告知其投票的方式、地點和時間；</li>
                <li>與登記人聯絡，鼓勵其參與選舉投票；</li>
                <li>追蹤該組織選民登記計劃的進展情況；</li>
                <li>如有需要，聯絡拉票人以獲取關於所收集的任何特定登記的更多資訊；</li>
                <li>就組織認為其可能感興趣的資訊與登記人和拉票人聯絡；</li>
                <li>出於研究之目的，結合來自其他登記人和拉票人的資訊，包括例如，分析和研究選民登記的模式、各種選民登記方法和途徑的有效性以及新登記人的特徵； </li>
                <li>維護和/或改進應用程式；以及</li>
                <li>用於業務運營，包括安全保障、預防欺詐和法律合規。</li>
            </ul>
            <p>該組織不會將登記人和拉票人的該等資訊用於任何商業目的，包括誘使其購買商品或服務，或用於相關法律禁止的任何目的。 </p>
            <p>在以下情况下，該組織可分享和/或披露從登記人和拉票人處獲得的資訊（登記人的駕駛執照號碼及其社會安全號碼的最後四位數除外）：</p>
            <ul>
                <li>適用法律要求時，提供給州、地方或聯邦政府機構；</li>
                <li>出於非商業目的提供給學術或研究機構或供應商； </li>
                <li>出於非商業目的提供給從事選民登記和動員工作的其他無黨派組織；</li>
                <li>提供給 PA Vote for Change 及其組織體系，以及應用程式的資助方 </li>
                <li>如果該組織收購、合併或與其他組織合作；以及</li>
                <li>如果資訊被整合和/或作爲去識別資訊。</li>
            </ul>
            <p>&nbsp;</p>
            <p>如果您對本隱私政策或使用條款有任何疑問，可以發送電子郵件至 help@pavoteforchange.com 與我們聯絡。</p>
            <p>&nbsp;</p>
            <p>最近更新時間：9/21/2022</p>
        </>,
    },
    "registration": {
        heading_1: "讓我們開始您的註冊吧！",
        heading_2: "更新註冊",
        heading_vmb_only: "申請郵寄選票",
        content: "請確認或提供以下資訊。",
    },
    "registration_address": {
        heading: "請輸入您的地址以便進行登記。",
    },
    "review": {
        heading: "請確認您提供的資訊。",
        content: "請確認以下資訊是準確的。若有資訊不正確，點擊各個部分右上角的編輯圖標。 ",
        personal_info_heading: "當前的個人資訊",
        residential_heading: "住宅資訊",
        license_heading: "賓州駕照或賓州交通局身份證",
        ssn_heading: "社會安全號碼",
        prev_name_heading: "過去姓名",
        prev_address_heading: "過去地址",
        additional_info_heading: "更多資訊",
        email_optin: "電子郵件選擇加入",
        phone_optin: "電話選擇加入",
        county: "縣",
        municipality: "直轄市",
        poll_worker: "選舉工作人員",
        bilingual_interpreter: "雙語口譯員",
        languages: "語言",
        requires_help_to_vote: "需要投票幫助嗎？",
        assistance_needed: "需要的援助",
        preferred_language: "偏好的語言",
        have_mailing_address: "我有郵寄地址",
    
        ballot_request: "年度郵寄選票申請",
    },
    "required_help": {
        heading: "您投票時需要協助嗎？",
        content: "請在下方說明您的偏好。",
        label: "我投票時需要協助。我需要此類型協助",
        language_label: "我需要語言協助。我偏好的語言為：",
        type_error: "請選擇需要的協助種類。",
    },
    "stay_in_touch": {
        heading: "我們要保持聯繫！",
        content: "我們將保持聯繫，提供關於我們的組織的資訊。",
    },
    "thank_you": {
        heading: "謝謝！",
        content: "我們將保持聯繫，提供關於即將在投票日提供的其他服務之資訊！",
    },
    "terms": {
        heading: "條款和條件",
        content: <>
            <p>本隱私政策和使用條款適用於所有 PA Vote For Change 選民登記行動應用程式（以下稱「應用程式」）的用戶，包括登記人、拉票人和組織，這些術語的定義見下文（「用戶」或」您」）。</p>
            <p>本應用程式是 PA Vote for Change LLC（以下稱「PA Vote for Change」 或「我們」）提供，我們獲授權准許其他組織（以下稱「組織」）透過其員工、承包商和/或志願者（以下稱「拉票人」）使用本應用程式在賓夕法尼亞州聯邦登記選民（以下稱「登記人」）。   在使用或提交與本應用程式相關的任何資訊之前，請仔細閱讀本隱私政策和使用條款。使用本應用程式即表明您同意收集、使用和披露您的資訊，並同意如下所述的使用條款和條件。</p>
            <p>我們將持續審查這些政策，並可能不時作出修訂。本頁底部的日期表示上一次修訂的時間。您應定期查看此頁面所載的更新資訊。您繼續使用本應用程式將表示您已閱讀、理解並接受更新的隱私政策和使用條款的各項條款。</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>使用條款</strong></p>
            <ol className="list-decimal">
                <li className="pb-8">
                    <strong>存取應用程式。 </strong> 存取該應用程式的每所有拉票人向 PA Vote for Change LLC（以下稱「PA Vote for Change」或「我們」）保證並聲明，該等拉票人已獲組織授權使用該應用程式，該組織由 PA Vote for Change 授權使用該應用程式。   所有拉票人聲明其在應用程式中提供的資訊均準確、完整並有效。所有使用該應用程式的登記人保證並聲明，他們有資格在美國投票，且他們提供的資訊均準確、完整並有效。<br /><br />
                    所有拉票人負責保護用於存取應用程式的合作夥伴代碼和密碼，並同意其不會向任何其他個人士或實體披露該等合作夥伴代碼或密碼。  一旦發現任何違反安全規定或未經授權使用拉票人帳戶的行為，拉票人必須立即通知拉票人作爲其雇員、承包商或志願者所在的組織。  然後，該組織必須立即通知 PA Vote for Change。
                </li>
                <li className="pb-8">
                    智慧產權  應用程式的所有用戶（包括所有登記人、拉票人和組織）同意，就該用戶與 PA Vote for Change 之間而言，本應用程式（以任何形式、目標代碼或源代碼）運行所使用的軟體中的所有版權、商標和其他智慧產權，以及本應用程式的架構、特徵和功能，都是並將繼續是 PA Vote for Change 的唯一和專有財產。所有用戶還同意，其不會透過使用該應用程式獲得對應用程式的所有權。
                </li>
                <li className="pb-8">
                    終止。如果拉票人或組織（如適用）違反該隱私政策或使用條款，PA Vote for Change 可暫停或終止任何拉票人的帳戶，或暫停或終止組織對應用程式的存取，無需事先通知或承擔責任。
                </li>
                <li className="pb-8">
                    <strong>責任限制。</strong> 在任何情况下，PA Vote for Change 均不對組織或任何拉票人或任何登記人或任何其他人士或實體因使用該應用程式而導致的任何損害賠償、費用、罰款或處罰負責。  在適用法律允許的最大範圍內，PA Vote for Change 在任何情况下均不對任何特殊的、偶然的、間接的或後果性的損害負責（包括但不限於利潤損失、數據或其他資訊損失、業務中斷、人身傷害、因使用或無法使用該應用程式、與該應用程一起使用或運行該應用程式的第三方軟體和/或第三方硬體而引起的或以任何方式與之相關的隱私丟失的損害，即使 PA Vote for Change  已被告知此類損害的可能性，或者補救措施未能達到其基本目的。<br /><br />
                    某些州不允許排除默示擔保或對附帶或間接損害的責任限制，這意味著上述某些限制可能不適用。  在這些州，各方的責任將在法律允許的最大範圍內受到限制。<br /><br />
                    就 PA Vote for Change 與各組織、拉票人和注册人之間而言，該應用程式「按現狀」和「按可用情況」提供，對所有故障和影響均不做任何類型的擔保。  在適用法律允許的最大範圍內，PA Vote for Change 代表其自身、PA Vote for Change's 組織體系，以及應用程式的出資方及其各自的許可人和服務提供商，明確否認與應用程式有關的所有明示、暗示、法定或其他形式的保證，包括對適銷性、特定用途適用性、所有權和非侵權的所有默示保證，以及在交易過程、履行過程、使用或貿易做法中可能產生的保證。在不受上述內容限制的情況下，PA Vote for Change 不提供任何保證或承諾，也不作出任何形式的聲明，保證該應用程式將滿足任何組織的要求、實現任何預期結果、與任何其他軟體、應用程式、系統或服務兼容或合作、不中斷運行、滿足任何性能或可靠性標準、不出現錯誤，或任何錯誤或缺陷可以或將被糾正。<br /><br />
                    在不限制前述內容的情况下，PA Vote for Change 作出任何明示或暗示的陳述或保證：(i) 該應用程式的運行或可用性，或其中包含的資訊、內容、資料或產品；(ii) 該應用程式將不會中斷或沒有錯誤；(iii) 透過該應用程式提供的任何資訊或內容的準確性、可靠性或時效性；或 (iv) 從 PA Vote for Change 發出或代表 PA Vote for Change 發出的電子郵件不含病毒、指令碼、木馬、蠕蟲、惡意軟件、使用期限或其他有害組件。<br /><br />
                    某些司法管轄區不允許對消費者適用的法定權利排除某些類型的保證或限制，因此上述部分或全部排除和限制可能不適用。但在該等情況下，本節規定的排除和限制應在適用法律範圍内可執行的最大限度內適用。
                </li>
                <li className="pb-8">
                    <strong>適用法律。</strong> 本條款受賓夕法尼亞州聯邦法律管轄，並根據其進行解釋。
                </li>
                <li className="pb-8">
                    可分割性。如果這些條款的任何規定被認定為無法執行或無效，則將對該等條款進行修訂和解釋，以根據適用法律最大限度地實現該等條款之目標，其餘條款將繼續完全有效。
                </li>
                <li className="pb-8">
                    <strong>豁免。</strong> 除非本文另有規定，未能遵循這些條款行使權利或要求履行義務不應影響一方在此後任何時間行使該等權利或要求該等履行的能力，對一項違約行為的豁免也不構成對任何後續違約行為的豁免。
                </li>
            </ol>
            <p>如果您對本隱私政策或使用條款有任何疑問，可以發送電子郵件至 help@pavoteforchange.com 與我們聯絡。</p>
            <p>&nbsp;</p>
            <p>最近更新時間：9/21/2022</p>
        </>,
    },
    "update_information": {
        heading: "您需要對您的註冊進行任何更改嗎？",
        content: "請選擇所有適用項：",
        error: "請至少勾選一個方框。",
        change_name: "我需要更改我在選民登記表上的姓名。",
        change_address: <>我需要更改與我的選民登記關聯的地址。<br /><em><span className="text-xs">如果您最近從其他州搬至賓夕法尼亞州，則必須 <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>提交新登記</Link> 而非地址變更。 </span></em></>,
        change_party: "我需要更改我的政黨從屬關係。",
        federal_employee: "我是聯邦或州員工，在我最後居住地所在縣登記。",

    },

    "ballot_request": {
        heading: "年度郵寄選票申請",
        content: "如果您想在今年餘下的時間裡領取郵寄選票，並且如果您想每年自動收到郵寄選票的年度申請，請於下方註明",
        content2: "您在前面頁面上提供的姓名、地址、縣、出生日期和賓夕法尼亞州駕駛執照或 PennDOT 身份證號碼將用於您的郵寄選票申請。",
        label: "您想在任何符合資格的選舉中領取郵寄選票嗎",
        address_type_label: "將選票郵寄給我，地址如下",
        address_type_1: "住址",
        address_type_2: "郵寄地址",
        address_type_3: "備用地址",
        ballot_request_type_error: "您必須選擇選票要郵寄到的地址。",
        ballot_ward_label: "請指出您的選區或投票區（如已知）",
        ballot_lived_since_label: "我開始住在這個地址的時間",
        ballot_lived_since_error: "請提供您搬到這個地址的年份。",
        ballot_lived_since_error_2: "請提供有效年份。",
        id_text: "身份證明：上文提供的賓夕法尼亞州駕駛執照或 PennDOT 身份證號碼將用於您的郵寄選票申請。如果此資訊有誤，請在 ID 資訊中進行任何必要的編輯。",
        id_field: "賓夕法尼亞州駕駛執照或 PennDOT 身份證號碼：",
        annual_request: "如果您希望在今年剩餘時間內申請接收郵寄選票，並希望每年自動接收年度郵寄選票申請，請在下方注明。如果您在提交年度郵寄 選票申請後因搬遷而更新選民登記，請確保在更新地址時已轉換了您的年度選民身份。",
        annual_request_field: "我希望今年能收到郵寄選票，並且每年都能收到郵寄選票的年度申請表。",
        confirmation_heading: "助理：請審閱下方聲明後簽名，以確認對這些條款的同意。",
        review_terms_confirm: "本人確認本人已閱讀並同意上述條款。",
        
    }
}

const vietnamese = {
    "general": {
        "settings": "Cài đặt",
        "shift_time": "Thời gian Ca làm việc",
        "registrations": "Đăng ký",
        "waiting_to_upload": "Đang chờ Tải lên",
        "upload_data": "Tải Dữ liệu lên",
        "end_shift": "Kết thúc Ca làm việc",
        "privacy_policy": "Chính sách Quyền riêng tư",
        "terms_conditions": "Điều khoản và Điều kiện",
        "knowledge_base": "Kiến thức Cơ bản",
        "saving_data": "Đang Lưu Dữ liệu Đăng ký...",
        "new_registration_heading": "Bạn có chắc chắn muốn bắt đầu đăng ký mới không?",
        "new_registration_body": "Nếu bạn bắt đầu đăng ký mới, thông tin đăng ký hiện tại sẽ bị xóa.",
        "powered_by": "Powered by ",
    },
    "fields": {
        "first_name": "Tên",
        "last_name": "Họ",
        "middle_name": "Tên đệm/Chữ cái đầu",
        "suffix": "Hậu tố",
        "birth_date": "Ngày sinh (mm/dd/yyyy)",
        "race": "Chủng tộc (không bắt buộc điền)",
        "sex": "Giới tính",
        "email_address": "Địa chỉ Email",
        "phone_number": "Số Điện thoại",
        "phone_type_select_label": "Chọn loại điện thoại...",
        "phone_type_select": "- Lựa chọn Loại -",
        "phone_type": "Loại điện thoại",
        "cell_phone": "Điện thoại Di động",
        "home_phone": "Điện thoại Nhà",
        "work_phone": "Điện thoại Cơ quan",
        "license_id": "Giấy phép Lái xe PA hoặc Số Thẻ ID PennDOT",
        "last_four_ssn": "Bốn Chữ số Cuối của SSN",
        "street_address": "Địa chỉ Đường phố",
        "street_address_2": "Địa chỉ Đường phố 2",
        "unit_type": "Loại Đơn nguyên (nếu có)",
        "unit_number": "Số Đơn nguyên (nếu có)",
        "city": "Thành phố",
        "state": "Tiểu bang",
        "zipcode": "Mã ZIP",
        "county": "Quận Nơi Bạn Sống",
        "municipality": "Thành phố Nơi Bạn Sống",
        "assistant_name": "Tên của người hỗ trợ hoàn thành đơn đăng ký này",
        "previous_first_name": "Tên Trước đây",
        "previous_last_name": "Họ Trước đây",
        "previous_middle_name": "Tên đệm/Chữ cái đầu Trước đây",
        "is_interested_poll_worker": "Tôi muốn trở thành nhân viên phòng phiếu vào ngày bầu cử",
        "is_interested_interpreter": "Tôi muốn trở thành thông dịch viên song ngữ vào Ngày Bầu cử",
        "interpreter_language": "Tôi nói ngôn ngữ này: ",
        "no_address": "Tôi không có địa chỉ đường phố hoặc nơi thường trú.",
        "yes_assistance": "Có, có người hỗ trợ tôi ký biểu mẫu.",
        "no_assistance": "Không, tôi tự ký.",
        "last_four": "Vui lòng nhập BỐN chữ số CUỐI của số An sinh Xã hội của bạn.",
        "mailing_address": "Địa chỉ Gửi thư",
        "mailing_address_2": "Địa chỉ Gửi thư 2",
        "mailing_city": "Thành phố Gửi thư",
        "mailing_state": "Tiểu bang Gửi thư",
        "mailing_zipcode": "Mã ZIP Gửi thư",
        "mailing_country": "VI - Mailing Country",
        "mailing_address_in_usa": "VI - Is the address in the United States?",
        "mailing_address_is_my": "VI - Mailing Address Is My … (e.g. vacation home, temporary residence, etc.)",
        "political_party": "Đảng Chính trị",
        "party_other": "Nếu Đảng khác, vui lòng ghi rõ:",
        "previous_address": "Địa chỉ Đường phố",
        "previous_city": "Thành phố",
        "previous_state": "Tiểu bang",
        "previous_county": "Quận",
        "previous_zipcode": "Mã ZIP",
        'na': 'n/a',
        
        "ballot_mailing_address": "Mail ballot to address",
        "ballot_mailing_city": "Mail ballot to city",
        "ballot_mailing_state": "Mail ballot to state is",
        "ballot_mailing_zipcode": "Mail ballot to ZIP Code (Ex. 12345 or 12345-6789)",
        "ballot_mailing_zipcode_short": "Mail ballot to ZIP Code",
        "ballot_mailing_country": "VI - Mail ballot to country",
        "ballot_mailing_ward_short": "Ward or Voting District",
        "ballot_mailing_lived_since_short": "Lived at address since",


    },
    "placeholders": {
        "dropdown": "- Lựa chọn -",
        "first_name": "Jane",
        "last_name": "Doe",
        "middle_name": "Henry",
        "suffix": "Jr.,II, v.v.",
        "birth_date": "tt/nn/nnnn",
        "email_address": "you@example.com",
        "phone_number": "555-555-5555",
        "select_an_item": "Chọn một mục...",
        "select_phone_type": "Chọn loại điện thoại...",
        "license_id": "12 345 678",
        "street_address": "5555 Oak St.",
        "street_address_2": "5555 Oak St.",
        "unit_number": "40B",
        "city": "Thành phố",
        "zipcode": "12345",
        "municipality": "Trước tiên hãy chọn một quận...",
        "political_party": "Đảng Chính trị",
        "mailing_address_is_my": "VI - vacation home, temporary residence, etc.",
    },
    "validation": {
        "id": "Giấy phép lái xe PA hoặc thông tin thẻ ID PennDOT mà bạn đã nhập không hợp lệ. Vui lòng xác nhận thông tin giấy phép lái xe PA hoặc thẻ ID PennDOT của bạn.",
        "first_name": "Tên của bạn bắt buộc phải điền.",
        "last_name": "Họ của bạn bắt buộc phải điền.",
        "middle_name": "VI - Your middle name is not valid.",
        "birth_date": "Ngày sinh của bạn bắt buộc phải điền.",
        "birth_date_valid": "Vui lòng nhập ngày sinh hợp lệ.",
        "email_address_format": "Định dạng của địa chỉ email không đúng. Vui lòng sửa và thử lại.",
        "phone_number_required": "Bắt buộc phải điền số điện thoại của bạn.",
        "phone_number_valid": "Số điện thoại được cung cấp không hợp lệ. Vui lòng nhập một số điện thoại hợp lệ.",
        "license_id": "Vui lòng cung cấp một giá trị.",
        "license_id_valid": "Vui lòng nhập Số Giấy phép hợp lệ",
        "ssn": "Vui lòng nhập BỐN chữ số CUỐI của số An sinh Xã hội của bạn.",
        "street_address": "Bắt buộc phải điền địa chỉ đường phố của bạn.",
        "city": "Thành phố của bạn bắt buộc phải điền.",
        "state": "Vui lòng chọn tiểu bang.",
        "zipcode": "Bắt buộc phải điền mã ZIP của bạn.",
        "zipcode_valid": "Vui lòng nhập mã ZIP có 5 chữ số hợp lệ.",
        "no_address_zipcode": "Bắt buộc phải điền mã ZIP của bạn.",
        "no_address_zipcode_valid": "Vui lòng nhập mã ZIP có 5 chữ số hợp lệ.",
        "county": "Quận cư trú của bạn bắt buộc phải điền.",
        "signature_confirmation_title": "Lỗi Xác nhận",
        "signature_confirmation_message": "Vui lòng xác nhận rằng bạn đã đọc và đồng ý với các điều khoản.",
        "assistant_confirmation_title": "Lỗi Xác nhận",
        "assistant_confirmation_message": "Vui lòng cho biết sự hỗ trợ được cung cấp khi hoàn thành biểu mẫu này.",
        "previous_first_name": "Tên Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Tên",
        "previous_last_name": "Họ Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Tên",
        "previous_middle_name": "VI - Your middle name is not valid.",
        "select_option": "Vui lòng chọn một tùy chọn.",
        "provide_value": "Vui lòng cung cấp một giá trị.",
        "no_address": "Bắt buộc phải điền địa chỉ đường phố của bạn.",
        "mailing_address": "Bắt buộc phải điền địa chỉ đường phố của bạn.",
        "mailing_city": "Thành phố của bạn bắt buộc phải điền.",
        "mailing_state": "Vui lòng chọn tiểu bang.",
        "mailing_zipcode": "Vui lòng nhập mã ZIP có 5 chữ số hợp lệ.",
        "mailing_address_is_my": "VI - Please provide a value for this field",
        "party": "Vui lòng chọn một đảng chính trị.",
        "party_other": "Cảnh báo – Đảng chưa được chọn. Nếu Đảng khác được chọn, phải điền ô văn bản Đảng khác.",
        "previous_address": "Địa chỉ Đăng ký Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Địa chỉ",
        "previous_city": "Thành phố Đăng ký Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Địa chỉ",
        "previous_state": <>Tiểu bang trước đây phải là Pennsylvania. Nếu gần đây bạn đã chuyển đến Pennsylvania từ một tiểu bang khác, bạn phải <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>nộp đăng ký mới</Link> chứ không chỉ thay đổi địa chỉ.</>,
        "previous_county": "Quận Đăng ký Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Địa chỉ",
        "previous_zipcode": "Mã Zip Đăng ký Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Địa chỉ",
        "previous_zipcode_required": "Mã Zip Đăng ký Trước đây bắt buộc phải điền cho đơn đăng ký Đổi Địa chỉ",
        "phone_type": "Vui lòng chọn một tùy chọn.",
    },
    "buttons": {
        "lets_get_started": "Hãy Bắt đầu nào",
        "new_registrant": "Tôi là người đăng ký mới",
        "vbm_only": "VI - I only want to request a mail-in ballot",
        "update_registration": "Tôi cần cập nhật đăng ký",
        "no_changes": "Tôi không có thay đổi nào cần thực hiện",
        "proceed": "Tiếp tục",
        "continue": "VI - Continue",
        "cancel": "Hủy bỏ",
        "yes": "Có",
        "no": "Không",
        "no_ssn": "Tôi không mang theo số SSN của mình",
        "next_step": "Bước Tiếp theo",
        "update_information": "Cập nhật Thông tin",
        "add_signature": "Thêm Chữ ký",
        "clear_signature": "Xóa Chữ ký",
        "accept_signature": "Chấp nhận Chữ ký",
        "complete_registration": "Hoàn tất Đăng ký",
        "complete_vbm": "VI - Complete Mail-in Ballot Application",
        "start_new_registration": "Bắt đầu Đăng ký Mới",
        "continue_registration": "Tiếp tục Đăng ký",
        "sign_up": "Đăng ký",
        "stay_in_touch": "Giữ Liên lạc",
        "back": "Mặt sau",
        "continue_without_ssn": "VI - ",
        "continue_without_id": "VI - ",
    },

    "home": {
        heading: "Chào mừng bạn đến với PA Vote For Change.",
        content: "Vui lòng chọn một tùy chọn bên dưới để cập nhật đơn đăng ký hoặc bắt đầu đăng ký cử tri.",
        id_required_heading: "VI - PennDOT ID Required",
        id_required_body: "VI - In order to submit a mail-in ballot request, a PennDot ID number is required. Do you want to proceed?",
    },
    "comingsoon": {
        heading: "Welcome to PA Vote For Change.",
        content: "Online voter registration coming soon.",
    },
    "additional_questions": {
        heading: "Bạn có muốn trở thành nhân viên phòng phiếu ​​vào Ngày Bầu cử không?",
        content: "Vui lòng cho biết nguyện vọng của bạn bên dưới.",
    },
    "address_information": {
        heading: "Vui lòng cung cấp cho chúng tôi thông tin của bạn bên dưới.",
    },
    "add_signature": {
        heading: "Hãy xem xét tuyên bố và ký tên để xác nhận đơn đăng ký của bạn.",
        review_your_signature: "Xem lại Chữ ký của Bạn:",
        review_signature_terms_confirm: "TÔI XÁC NHẬN RẰNG TÔI ĐÃ ĐỌC VÀ ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN TRÊN.",
        review_signature_sign: "Ký ở đây bằng ngón tay hoặc bút máy tính bảng của bạn:",
    },
    "assistance_add_signature": {
        heading: "Người trợ lý: Vui lòng xem lại tuyên bố dưới đây và ký tên để xác nhận đồng ý với các điều khoản.",
        review_your_signature: "Xem lại Chữ ký của Bạn:",
        review_signature_terms_confirm: "TÔI XÁC NHẬN RẰNG TÔI ĐÃ ĐỌC VÀ ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN TRÊN.",
        review_signature_sign: "Ký ở đây bằng ngón tay hoặc bút máy tính bảng của bạn:",
    },
    "assistance_information": {
        heading: "Vui lòng yêu cầu trợ lý cung cấp địa chỉ và chữ ký của họ.",
        content: "Vui lòng thêm tên và địa chỉ của bạn bên dưới.",
    },
    "confirmation": {
        heading: "Đang Xử lý Đăng ký!",
        content: "VI - Thank you for submitting a voter registration application!",
        content_with_email: "VI - We'll send you a confirmation email and keep in touch about other services coming up on voting day.",
        support: <>
            <p>VI - The decision on your voter registration is ultimately made by your county voter registration office. If you have any questions about your application, please reach out to your county for clarification. For further support, kindly email us at <a className="underline" href="mailto:help@pavoteforchange.com">help@pavoteforchange.com</a>.</p>
        </>,
    },
    "vbm_confirmation": {
        heading: "VI - Vote By Mail Application Processing",
        content: "VI - Thank you for submitting a Vote By Mail application!",
        content_with_email: "VI - We'll send you a confirmation email and keep in touch about other services coming up on voting day.",
        support: <>
            <p>VI - The decision on your Vote By Mail Application is ultimately made by your county voter registration office. If you have any questions about your application, please reach out to your county for clarification. For further support, kindly email us at <a href="mailto:help@pavoteforchange.com" className="underline">help@pavoteforchange.com</a>.</p>
        </>,
    },
    "current_address": {
        heading: "Vui lòng điền địa chỉ cư trú hiện tại của bạn.",
    },
    "eligibility_age": {
        valid_age_question: "Có phải bạn sẽ được tối thiểu 18 tuổi vào hoặc trước ngày bầu cử không?",
    },

    "eligibility_citizenship": {
        us_citizen_question: "Bạn có phải là công dân Hoa Kỳ không?",
    },
    "eligibility_notice": {
        heading: "Để đăng ký, bạn phải:",
        content:
            <ul>
                <li>Là công dân Hoa Kỳ tối thiểu 30 ngày trước cuộc bầu cử tiếp theo</li>
                <li>Là cư dân của Pennsylvania và địa hạt bầu cử của bạn tối thiểu 30 ngày trước cuộc bầu cử tiếp theo</li>
                <li>Được tối thiểu 18 tuổi vào ngày bầu cử tiếp theo ##DATE##</li>
            </ul>,
    },
    "had_assistance": {
        heading: "Có ai hỗ trợ bạn ký biểu mẫu này không?",
        content: "Nếu bạn không có năng lực ký vào biểu mẫu này do khuyết tật thể chất, bạn có thể nhờ người trợ lý ký đại diện cho bạn. Người trợ lý đó phải cung cấp chữ ký và địa chỉ của họ trong chỗ trống chỉ định để ký tên, người trợ lý xác nhận rằng họ ký vào biểu mẫu theo yêu cầu của bạn.",
    },
    "has_mailing_address": {
        heading: "Địa chỉ gửi thư của bạn có giống với địa chỉ cư trú không?",
        mailing_ask_yes: "Có, địa chỉ gửi thư và địa chỉ cư trú của tôi giống nhau.",
        mailing_ask_no: "Không, tôi có địa chỉ gửi thư khác.",
    },
    "id_entry": {
        heading: "Nhập thông tin trên ID PennDOT của bạn.",
        content: "Vui lòng điền vào biểu mẫu dưới đây dựa trên thông tin hiện tại của bạn. Xin lưu ý rằng đây không phải là nơi bạn cập nhật tên hoặc địa chỉ.",
        no_id_heading: "VI - ",
        no_id_body: "VI - ",
    },
    "id_verification": {
        heading: "Bạn có giấy phép lái xe PA hoặc số thẻ ID PennDOT không?",
        content: "Cách dễ nhất để chúng tôi đăng ký cho bạn là bằng Giấy phép Lái ​​xe hoặc ID PennDOT của bạn, nhưng chúng tôi cũng có thể đăng ký nếu bạn có số An sinh xã hội.",
        license_ask_have_id: "Tôi đang có giấy phép lái xe PA hoặc số thẻ ID PennDOT.",
        license_ask_no_id: "Tôi có giấy phép lái xe PA hoặc số thẻ ID PennDOT, nhưng tôi không mang theo người.",
        license_ask_have_ssn: "Tôi không có giấy phép lái xe PA hoặc số thẻ ID PennDOT, nhưng tôi có Số An sinh Xã hội.",
        license_ask_no_id_ssn: "Tôi không có giấy phép lái xe PA hoặc thẻ ID PennDOT hoặc Số An sinh Xã hội.",
        hava_disclaimer: <em><span className="text-xs">VI - </span></em>,
    },
    "last_four_entry": {
        heading: "Vì bạn không có số pennDOT nên hãy nhập 4 chữ số cuối của số An sinh Xã hội của bạn.",
        with_id_heading: "Vui lòng nhập BỐN chữ số CUỐI của số An sinh Xã hội của bạn.",
        no_ssn_heading: "VI - ",
        no_ssn_body: "VI - ",
    },
    "mailing_address": {
        heading: "Vui lòng cung cấp cho chúng tôi địa chỉ gửi thư của bạn.",
        content: "Chúng tôi sẽ cần địa chỉ gửi thư hiện tại của bạn để đăng ký cho bạn.",
    },
    "new_address": {
        heading: "Vui lòng nhập địa chỉ mới của bạn.",
    },
    "new_name": {
        heading: "Vui lòng nhập tên mới của bạn.",
    },
    "not_eligible": {
        heading: "Chúng tôi sẽ không thể đăng ký cho bạn hôm nay.",
        content: "Chúng tôi sẽ giữ liên lạc về các dịch vụ khác sắp diễn ra vào ngày bỏ phiếu!",
    },
    "page_not_found": {
        heading: "Không tìm thấy trang",
    },
    "paper_application": {
        heading: "Vui lòng yêu cầu người vận động bỏ phiếu của bạn cung cấp đơn giấy.",
        content: "Vì bạn không có địa chỉ thường trú, chúng tôi sẽ cần bạn điền đơn giấy. Đừng lo, chúng tôi sẽ vẫn giúp bạn đăng ký!",
    },
    "party_affiliation": {
        heading: "Bạn ủng hộ đảng chính trị nào?",
    },
    "previous_address": {
        heading: "Vui lòng nhập địa chỉ đăng ký trước đây của bạn.",
        content: <>Nếu gần đây bạn đã chuyển đến Pennsylvania từ một tiểu bang khác, bạn phải <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>nộp đăng ký mới</Link> chứ không chỉ thay đổi địa chỉ.</>
    },
    "previous_name": {
        heading: "Vui lòng nhập tên trước đây của bạn",
    },
    "privacy": {
        heading: "Chính sách Quyền riêng tư",
        content: <>
            <p>Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư v&agrave; Điều khoản Sử dụng n&agrave;y &aacute;p dụng cho tất cả người d&ugrave;ng ứng dụng di động đăng k&yacute; cử tri PA Vote For Change (&ldquo;Ứng dụng&rdquo;), bao gồm Người đăng k&yacute;, Người vận động bỏ phiếu, v&agrave; Tổ chức, y như những điều khoản đ&oacute; được x&aacute;c định b&ecirc;n dưới (&ldquo;người d&ugrave;ng&rdquo; hoặc &ldquo;bạn&rdquo;).&nbsp;</p>
            <p>Ứng dụng được cung cấp bởi PA Vote for Change LLC (&ldquo;PA Vote for Change&rdquo;, hoặc &ldquo;ch&uacute;ng t&ocirc;i&rdquo;), được ủy quyền cho ph&eacute;p c&aacute;c tổ chức kh&aacute;c (&ldquo;Tổ chức&rdquo;), th&ocirc;ng qua nh&acirc;n vi&ecirc;n, nh&agrave; thầu v&agrave;/hoặc t&igrave;nh nguyện vi&ecirc;n của họ (&ldquo;Người vận động bỏ phiếu&rdquo;), d&ugrave;ng Ứng dụng để đăng k&yacute; cho mọi người bỏ phiếu trong Khối thịnh vượng chung Pennsylvania (&ldquo;Người đăng k&yacute;&rdquo;).&nbsp; Trước khi bạn sử dụng hoặc nộp bất kỳ th&ocirc;ng tin n&agrave;o li&ecirc;n quan đến Ứng dụng, h&atilde;y xem kỹ Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư v&agrave; Điều khoản Sử dụng n&agrave;y. Khi sử dụng Ứng dụng, bạn chấp thuận cho ph&eacute;p thu thập, sử dụng v&agrave; tiết lộ th&ocirc;ng tin của bạn, cũng như điều khoản v&agrave; điều kiện sử dụng, như được n&ecirc;u b&ecirc;n dưới.</p>
            <p>Ch&uacute;ng t&ocirc;i sẽ li&ecirc;n tục r&agrave; so&aacute;t c&aacute;c ch&iacute;nh s&aacute;ch n&agrave;y v&agrave; đ&ocirc;i khi ch&uacute;ng t&ocirc;i c&oacute; thể thực hiện thay đổi đối với ch&iacute;nh s&aacute;ch. Ng&agrave;y ở cuối trang n&agrave;y cho biết lần gần nhất thay đổi được thực hiện. Bạn n&ecirc;n định kỳ kiểm tra xem trang n&agrave;y c&oacute; cập nhật kh&ocirc;ng. Nếu bạn tiếp tục d&ugrave;ng Ứng dụng th&igrave; tức l&agrave; bạn đ&atilde; đọc, hiểu, v&agrave; chấp nhận điều khoản của Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư v&agrave; Điều khoản Sử dụng đ&atilde; cập nhật.</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>CH&Iacute;NH S&Aacute;CH QUYỀN RI&Ecirc;NG TƯ</strong></p>
            <p><strong>Với Người đăng k&yacute;</strong></p>
            <p><strong>Bạn chỉ c&oacute; thể nhập th&ocirc;ng tin v&agrave;o Ứng dụng để đăng k&yacute; bỏ phiếu nếu bạn l&agrave; c&ocirc;ng d&acirc;n Hoa Kỳ v&agrave; mặt kh&aacute;c th&igrave; đủ điều kiện để đăng k&yacute;, như được thể hiện trong lời nhắc của Ứng dụng.</strong></p>
            <p>Ứng dụng sẽ y&ecirc;u cầu bạn nhập th&ocirc;ng tin về bản th&acirc;n, th&ocirc;ng tin n&agrave;y cuối c&ugrave;ng sẽ được nộp cho Văn ph&ograve;ng Ủy vi&ecirc;n/Hội đồng Bầu cử Quận của bạn để đăng k&yacute; cho bạn bỏ phiếu.&nbsp; Th&ocirc;ng tin n&agrave;y c&oacute; thể bao gồm:</p>
            <ul>
                <li>T&ecirc;n đầy đủ;</li>
                <li>Ng&agrave;y;</li>
                <li>Địa chỉ cư tr&uacute;;</li>
                <li>Địa chỉ gửi thư;</li>
                <li>Địa chỉ nơi cư tr&uacute; trước đ&acirc;y ngay trước khi chuyển đến nơi cư tr&uacute; hiện tại;</li>
                <li>Ng&agrave;y sinh;</li>
                <li>Chủng tộc;</li>
                <li>Đảng;</li>
                <li>Giới t&iacute;nh;</li>
                <li>Số điện thoại;</li>
                <li>Địa chỉ email;</li>
                <li>Số ID Penndot v&agrave;/hoặc 4 chữ số cuối của Số An sinh X&atilde; hội của bạn;</li>
                <li>Việc bạn đ&atilde; cư tr&uacute; trong Khối thịnh vượng chung Pennsylvania tối thiểu 30 ng&agrave;y trước Ng&agrave;y Bầu cử tiếp theo hay kh&ocirc;ng;</li>
                <li>Việc bạn l&agrave; c&ocirc;ng d&acirc;n của Hợp chủng quốc Hoa Kỳ trong tối thiểu 30 ng&agrave;y trước Ng&agrave;y Bầu cử tiếp theo hay kh&ocirc;ng;</li>
                <li>Việc bạn sẽ được tối thiểu 18 tuổi v&agrave;o Ng&agrave;y Bầu cử tiếp theo hay kh&ocirc;ng;</li>
                <li>Việc bạn cần hỗ trợ bỏ phiếu hay kh&ocirc;ng;</li>
                <li>Việc bạn muốn trở th&agrave;nh nh&acirc;n vi&ecirc;n ph&ograve;ng phiếu hay kh&ocirc;ng;</li>
                <li>Việc bạn muốn đăng k&yacute; bỏ phiếu qua thư hay kh&ocirc;ng hoặc nếu trước đ&oacute; bạn đ&atilde; bỏ phiếu qua thư, bạn c&oacute; muốn thay v&agrave;o đ&oacute; th&ocirc;ng tin được chuyển từ y&ecirc;u cầu của năm ngo&aacute;i để vẫn nhận l&aacute; phiếu bầu qua thư hay kh&ocirc;ng;</li>
                <li>Số giấy ph&eacute;p l&aacute;i xe của bạn; v&agrave;</li>
                <li>H&igrave;nh ảnh chữ k&yacute; của bạn</li>
            </ul>
            <p>Tổ chức thu thập th&ocirc;ng tin n&agrave;y từ bạn sẽ gửi th&ocirc;ng tin đến Văn ph&ograve;ng Ủy vi&ecirc;n/Hội đồng Bầu cử Quận của bạn để đăng k&yacute; bầu cử cho bạn. Tổ chức m&agrave; Người vận động bỏ phiếu thay mặt họ thu thập th&ocirc;ng tin của bạn c&oacute; thể giữ lại th&ocirc;ng tin tr&ecirc;n về bạn, ngoại trừ số giấy ph&eacute;p l&aacute;i xe v&agrave; bốn chữ số cuối của Số An sinh X&atilde; hội. Tổ chức c&oacute; thể sử dụng th&ocirc;ng tin n&agrave;y cho c&aacute;c mục đ&iacute;ch sau:</p>
            <ul>
                <li>Li&ecirc;n hệ với bạn để cung cấp th&ocirc;ng tin về c&aacute;ch thức, địa điểm v&agrave; thời gian bỏ phiếu;</li>
                <li>Li&ecirc;n hệ với bạn để khuyến kh&iacute;ch bạn bỏ phiếu trong c&aacute;c cuộc bầu cử;</li>
                <li>Li&ecirc;n hệ với bạn để cung cấp th&ocirc;ng tin kh&aacute;c m&agrave; Tổ chức tin rằng bạn c&oacute; thể quan t&acirc;m;</li>
                <li>Cho mục đ&iacute;ch nghi&ecirc;n cứu, khi được kết hợp với th&ocirc;ng tin từ những Người đăng k&yacute; v&agrave; Người vận động bỏ phiếu kh&aacute;c, bao gồm chẳng hạn như để ph&acirc;n t&iacute;ch v&agrave; nghi&ecirc;n cứu c&aacute;c m&ocirc; h&igrave;nh đăng k&yacute; cử tri, t&iacute;nh hiệu quả của c&aacute;c phương ph&aacute;p v&agrave; c&aacute;ch tiếp cận đăng k&yacute; cử tri kh&aacute;c nhau, v&agrave; đặc điểm của những người đăng k&yacute; mới;</li>
                <li>Bảo tr&igrave; v&agrave;/hoặc cải tiến Ứng dụng; v&agrave;</li>
                <li>Cho hoạt động kinh doanh, bao gồm bảo mật, ph&ograve;ng chống gian lận, v&agrave; tu&acirc;n thủ ph&aacute;p luật.</li>
            </ul>
            <p>Tổ chức sẽ kh&ocirc;ng sử dụng th&ocirc;ng tin của bạn cho bất kỳ mục đ&iacute;ch thương mại n&agrave;o, bao gồm ch&agrave;o mời bạn mua h&agrave;ng hoặc dịch vụ, hoặc cho bất kỳ mục đ&iacute;ch n&agrave;o bị cấm theo luật hiện h&agrave;nh.</p>
            <p>Tổ chức c&oacute; thể chia sẻ v&agrave;/hoặc tiết lộ th&ocirc;ng tin của bạn (ngoại trừ số giấy ph&eacute;p l&aacute;i xe v&agrave; bốn chữ số cuối của Số An sinh X&atilde; hội) trong c&aacute;c trường hợp sau:</p>
            <ul>
                <li>Cho c&aacute;c cơ quan ch&iacute;nh phủ tiểu bang, địa phương hoặc li&ecirc;n bang nếu v&agrave; khi luật hiện h&agrave;nh y&ecirc;u cầu;</li>
                <li>Cho c&aacute;c tổ chức hoặc nh&agrave; cung cấp học thuật hoặc nghi&ecirc;n cứu v&igrave; mục đ&iacute;ch phi thương mại;</li>
                <li>Cho c&aacute;c tổ chức kh&ocirc;ng đảng ph&aacute;i kh&aacute;c tham gia v&agrave;o c&aacute;c hoạt động đăng k&yacute; v&agrave; vận động cử tri v&igrave; mục đ&iacute;ch phi thương mại;</li>
                <li>Cho PA Vote for Change v&agrave; c&aacute;c tổ chức li&ecirc;n kết của họ, cũng như c&aacute;c nh&agrave; t&agrave;i trợ của ứng dụng n&agrave;y</li>
                <li>Nếu Tổ chức mua lại, s&aacute;p nhập hoặc hợp t&aacute;c với c&aacute;c tổ chức kh&aacute;c; v&agrave;</li>
                <li>Nếu th&ocirc;ng tin được tổng hợp v&agrave;/hoặc th&ocirc;ng tin được khử nhận dạng.</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>Với Người vận động bỏ phiếu </strong></p>
            <p>Người vận động bỏ phiếu chỉ được d&ugrave;ng Ứng dụng theo hướng dẫn v&agrave; đ&agrave;o tạo m&agrave; họ đ&atilde; được cung cấp.</p>
            <p>Người vận động bỏ phiếu kh&ocirc;ng được lưu giữ ri&ecirc;ng bất kỳ th&ocirc;ng tin n&agrave;o dưới bất kỳ h&igrave;nh thức n&agrave;o được thu thập từ Người đăng k&yacute; bằng Ứng dụng, v&agrave; kh&ocirc;ng được chia sẻ bất kỳ th&ocirc;ng tin n&agrave;o như vậy với bất kỳ c&aacute; nh&acirc;n hoặc tổ chức n&agrave;o kh&aacute;c ngo&agrave;i Tổ chức m&agrave; Người vận động bỏ phiếu thay mặt d&ugrave;ng Ứng dụng.</p>
            <p>Người vận động bỏ phiếu sẽ được y&ecirc;u cầu nhập một số th&ocirc;ng tin nhất định về bản th&acirc;n trước mỗi ca l&agrave;m việc đăng k&yacute; cử tri bằng Ứng dụng.&nbsp; Th&ocirc;ng tin n&agrave;y c&oacute; thể bao gồm t&ecirc;n, địa chỉ email, t&ecirc;n người d&ugrave;ng v&agrave; mật khẩu của bạn, M&atilde; Đối t&aacute;c v&agrave; ng&agrave;y, thời gian v&agrave; địa điểm của mỗi ca l&agrave;m việc đăng k&yacute; cử tri bằng Ứng dụng.</p>
            <p>Th&ocirc;ng tin bạn nộp sẽ được sử dụng để x&aacute;c nhận rằng bạn l&agrave; Người vận động bỏ phiếu c&oacute; quyền truy cập Ứng dụng. Ngo&agrave;i ra, th&ocirc;ng tin bạn nộp v&agrave; th&ocirc;ng tin về đăng k&yacute; cử tri m&agrave; bạn ho&agrave;n th&agrave;nh c&oacute; thể được sử dụng cho c&aacute;c mục đ&iacute;ch sau:</p>
            <ul>
                <li>Theo d&otilde;i tiến tr&igrave;nh của c&aacute;c chương tr&igrave;nh đăng k&yacute; cử tri của Tổ chức;</li>
                <li>Li&ecirc;n hệ với bạn để hỏi th&ecirc;m th&ocirc;ng tin về bất kỳ đăng k&yacute; cụ thể n&agrave;o bạn thu thập;</li>
                <li>Li&ecirc;n hệ với bạn để cung cấp th&ocirc;ng tin m&agrave; Tổ chức tin rằng bạn c&oacute; thể quan t&acirc;m;</li>
                <li>Cho mục đ&iacute;ch nghi&ecirc;n cứu, khi được kết hợp với th&ocirc;ng tin từ những Người vận động bỏ phiếu v&agrave; Người đăng k&yacute; kh&aacute;c, bao gồm chẳng hạn như để nghi&ecirc;n cứu v&agrave; ph&acirc;n t&iacute;ch c&aacute;c m&ocirc; h&igrave;nh trong đăng k&yacute; cử tri, t&iacute;nh hiệu quả của c&aacute;c phương ph&aacute;p v&agrave; c&aacute;ch tiếp cận đăng k&yacute; cử tri, v&agrave; đặc điểm của những người đăng k&yacute; mới;</li>
                <li>Bảo tr&igrave; v&agrave;/hoặc cải tiến Ứng dụng; v&agrave;</li>
                <li>Cho hoạt động kinh doanh, bao gồm bảo mật, ph&ograve;ng chống gian lận, v&agrave; tu&acirc;n thủ ph&aacute;p luật.</li>
            </ul>
            <p>Tổ chức sẽ kh&ocirc;ng sử dụng th&ocirc;ng tin của bạn cho bất kỳ mục đ&iacute;ch thương mại n&agrave;o, bao gồm ch&agrave;o mời bạn mua h&agrave;ng hoặc dịch vụ, hoặc cho bất kỳ mục đ&iacute;ch n&agrave;o bị cấm theo luật hiện h&agrave;nh.</p>
            <p>Tổ chức c&oacute; thể chia sẻ v&agrave;/hoặc tiết lộ th&ocirc;ng tin của bạn trong c&aacute;c trường hợp sau:</p>
            <ul>
                <li>Cho c&aacute;c cơ quan ch&iacute;nh phủ tiểu bang, địa phương hoặc li&ecirc;n bang nếu v&agrave; khi luật hiện h&agrave;nh y&ecirc;u cầu;</li>
                <li>Cho c&aacute;c tổ chức hoặc nh&agrave; cung cấp học thuật hoặc nghi&ecirc;n cứu v&igrave; mục đ&iacute;ch phi thương mại;</li>
                <li>Cho c&aacute;c tổ chức kh&ocirc;ng đảng ph&aacute;i kh&aacute;c tham gia v&agrave;o c&aacute;c hoạt động đăng k&yacute; v&agrave; vận động cử tri v&igrave; mục đ&iacute;ch phi thương mại;</li>
                <li>Cho PA Vote for Change v&agrave; c&aacute;c tổ chức li&ecirc;n kết của họ, cũng như c&aacute;c nh&agrave; t&agrave;i trợ của ứng dụng n&agrave;y</li>
                <li>Nếu Tổ chức mua lại, s&aacute;p nhập hoặc hợp t&aacute;c với c&aacute;c tổ chức kh&aacute;c; v&agrave;</li>
                <li>Nếu th&ocirc;ng tin được tổng hợp v&agrave;/hoặc th&ocirc;ng tin được khử nhận dạng.</li>
            </ul>
            <p>&nbsp;</p>

            <p><strong>Với Tổ chức </strong></p>
            <p>Th&ocirc;ng tin sau đ&acirc;y được thu thập từ Người đăng k&yacute; chỉ c&oacute; thể được chuyển đến cơ quan chức năng bầu cử v&agrave; KH&Ocirc;NG THỂ được tổ chức của bạn lưu giữ:</p>
            <ul>
                <li>Số giấy ph&eacute;p l&aacute;i xe</li>
                <li>Bốn chữ số cuối của Số An sinh X&atilde; hội của Người đăng k&yacute;</li>
            </ul>
            <p>Th&ocirc;ng tin kh&aacute;c được thu thập th&ocirc;ng qua Ứng dụng từ Người đăng k&yacute; v&agrave; Người vận động bỏ phiếu c&oacute; thể được Tổ chức lưu giữ. Tổ chức c&oacute; thể sử dụng th&ocirc;ng tin n&agrave;y cho c&aacute;c mục đ&iacute;ch sau:</p>
            <ul>
                <li>Li&ecirc;n hệ với Người đăng k&yacute; để cung cấp th&ocirc;ng tin về c&aacute;ch thức, địa điểm v&agrave; thời gian bỏ phiếu;</li>
                <li>Li&ecirc;n hệ với Người đăng k&yacute; để khuyến kh&iacute;ch họ bỏ phiếu trong c&aacute;c cuộc bầu cử;</li>
                <li>Theo d&otilde;i tiến tr&igrave;nh của c&aacute;c chương tr&igrave;nh đăng k&yacute; cử tri của Tổ chức;</li>
                <li>Li&ecirc;n hệ với Người vận động bỏ phiếu để hỏi th&ecirc;m th&ocirc;ng tin nếu cần về bất kỳ đăng k&yacute; cụ thể n&agrave;o được thu thập;</li>
                <li>Li&ecirc;n hệ với Người đăng k&yacute; v&agrave; Người vận động bỏ phiếu để cung cấp th&ocirc;ng tin m&agrave; Tổ chức tin rằng họ c&oacute; thể quan t&acirc;m;</li>
                <li>Cho mục đ&iacute;ch nghi&ecirc;n cứu, khi được kết hợp với th&ocirc;ng tin từ những Người đăng k&yacute; v&agrave; Người vận động bỏ phiếu kh&aacute;c, bao gồm chẳng hạn như để ph&acirc;n t&iacute;ch v&agrave; nghi&ecirc;n cứu c&aacute;c m&ocirc; h&igrave;nh đăng k&yacute; cử tri, t&iacute;nh hiệu quả của c&aacute;c phương ph&aacute;p v&agrave; c&aacute;ch tiếp cận đăng k&yacute; cử tri kh&aacute;c nhau, v&agrave; đặc điểm của những người đăng k&yacute; mới;</li>
                <li>Bảo tr&igrave; v&agrave;/hoặc cải tiến Ứng dụng; v&agrave;</li>
                <li>Cho hoạt động kinh doanh, bao gồm bảo mật, ph&ograve;ng chống gian lận, v&agrave; tu&acirc;n thủ ph&aacute;p luật.</li>
            </ul>
            <p>Tổ chức kh&ocirc;ng thể sử dụng th&ocirc;ng tin đ&oacute; cho bất kỳ mục đ&iacute;ch thương mại n&agrave;o, bao gồm cả ch&agrave;o mời Người đăng k&yacute; hoặc Người vận động bỏ phiếu mua h&agrave;ng hoặc dịch vụ hoặc cho bất kỳ mục đ&iacute;ch n&agrave;o bị cấm theo luật hiện h&agrave;nh.</p>
            <p>Tổ chức c&oacute; thể chia sẻ v&agrave;/hoặc tiết lộ th&ocirc;ng tin thu thập từ Người đăng k&yacute; v&agrave; Người vận động bỏ phiếu (ngoại trừ số giấy ph&eacute;p l&aacute;i xe của Người đăng k&yacute; v&agrave; bốn chữ số cuối trong Số An sinh X&atilde; hội của họ) trong c&aacute;c trường hợp sau:</p>
            <ul>
                <li>Cho c&aacute;c cơ quan ch&iacute;nh phủ tiểu bang, địa phương hoặc li&ecirc;n bang nếu v&agrave; khi luật hiện h&agrave;nh y&ecirc;u cầu;</li>
                <li>Cho c&aacute;c tổ chức hoặc nh&agrave; cung cấp học thuật hoặc nghi&ecirc;n cứu v&igrave; mục đ&iacute;ch phi thương mại;</li>
                <li>Cho c&aacute;c tổ chức kh&ocirc;ng đảng ph&aacute;i kh&aacute;c tham gia v&agrave;o c&aacute;c hoạt động đăng k&yacute; v&agrave; vận động cử tri v&igrave; mục đ&iacute;ch phi thương mại;</li>
                <li>Cho PA Vote for Change v&agrave; c&aacute;c tổ chức li&ecirc;n kết của họ, cũng như c&aacute;c nh&agrave; t&agrave;i trợ của ứng dụng n&agrave;y</li>
                <li>Nếu Tổ chức mua lại, s&aacute;p nhập hoặc hợp t&aacute;c với c&aacute;c tổ chức kh&aacute;c; v&agrave;</li>
                <li>Nếu th&ocirc;ng tin được tổng hợp v&agrave;/hoặc th&ocirc;ng tin được khử nhận dạng.</li>
            </ul>
            <p>&nbsp;</p>
            <p>Nếu bạn có bất kỳ thắc mắc nào về Chính sách Quyền riêng tư hoặc Điều khoản Sử dụng này, bạn có thể liên hệ với chúng tôi bằng cách gửi email cho chúng tôi theo địa chỉ help@pavoteforchange.com.</p>
            <p>&nbsp;</p>
            <p>Cập nhật Gần nhất: 9/21/2022</p>
        </>,
    },
    "registration": {
        heading_1: "Hãy bắt đầu đăng ký của bạn!",
        heading_2: "Cập nhật đăng ký",
        heading_vmb_only: "VI - Request a Mail-In Ballot",
        content: "Vui lòng xác nhận hoặc cung cấp thông tin sau.",
    },
    "registration_address": {
        heading: "Vui lòng điền địa chỉ đăng ký của bạn.",
    },
    "review": {
        heading: "Vui lòng xác nhận thông tin bạn đã cung cấp.",
        content: "Vui lòng xác nhận rằng thông tin dưới đây là chính xác. Nếu thông tin không đúng, hãy nhấp vào biểu tượng chỉnh sửa ở góc trên cùng bên phải của mỗi phần.",
        personal_info_heading: "Thông tin Cá nhân Hiện tại",
        residential_heading: "Thông tin Cư dân",
        license_heading: "Giấy phép Lái ​​xe PA hoặc ID PennDOT",
        ssn_heading: "Số An sinh Xã hội",
        prev_name_heading: "Tên Trước đây",
        prev_address_heading: "Địa chỉ Trước đây",
        additional_info_heading: "Thông tin Bổ sung",
        email_optin: "Chọn tham gia bằng email",
        phone_optin: "Chọn tham gia bằng điện thoại",
        county: "Quận",
        municipality: "Thành phố",
        poll_worker: "Nhân viên Phòng phiếu",
        bilingual_interpreter: "Thông dịch viên Song ngữ",
        languages: "(Các) ngôn ngữ",
        requires_help_to_vote: "Cần Trợ giúp để Bỏ phiếu?",
        assistance_needed: "Hỗ trợ Cần thiết",
        preferred_language: "Ngôn ngữ Ưa thích",
        have_mailing_address: "Tôi có địa chỉ gửi thư",
    
        ballot_request: "Annual Mail-in Ballot Application",
    },
    "required_help": {
        heading: "Bạn có cần giúp đỡ để bỏ phiếu không?",
        content: "Vui lòng cho biết nguyện vọng của bạn bên dưới.",
        label: "Tôi cần giúp đỡ để bỏ phiếu. Tôi cần loại hỗ trợ này:",
        language_label: "Tôi cần trợ giúp về ngôn ngữ. Ngôn ngữ ưu tiên của tôi là:",
        type_error: "Vui lòng chọn loại hỗ trợ cần thiết.",
    },
    "stay_in_touch": {
        heading: "Hãy Giữ Liên lạc!",
        content: "Chúng tôi sẽ giữ liên lạc về tổ chức của chúng tôi.",
    },
    "thank_you": {
        heading: "Xin cảm ơn!",
        content: "Chúng tôi sẽ giữ liên lạc về các dịch vụ khác sắp diễn ra vào ngày bỏ phiếu!",
    },
    "terms": {
        heading: "Điều khoản và Điều kiện",
        content: <>
            <p>Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư v&agrave; Điều khoản Sử dụng n&agrave;y &aacute;p dụng cho tất cả người d&ugrave;ng ứng dụng di động đăng k&yacute; cử tri PA Vote For Change (&ldquo;Ứng dụng&rdquo;), bao gồm Người đăng k&yacute;, Người vận động bỏ phiếu, v&agrave; Tổ chức, y như những điều khoản đ&oacute; được x&aacute;c định b&ecirc;n dưới (&ldquo;người d&ugrave;ng&rdquo; hoặc &ldquo;bạn&rdquo;).&nbsp;</p>
            <p>Ứng dụng được cung cấp bởi PA Vote for Change LLC (&ldquo;PA Vote for Change&rdquo;, hoặc &ldquo;ch&uacute;ng t&ocirc;i&rdquo;), được ủy quyền cho ph&eacute;p c&aacute;c tổ chức kh&aacute;c (&ldquo;Tổ chức&rdquo;), th&ocirc;ng qua nh&acirc;n vi&ecirc;n, nh&agrave; thầu v&agrave;/hoặc t&igrave;nh nguyện vi&ecirc;n của họ (&ldquo;Người vận động bỏ phiếu&rdquo;), d&ugrave;ng Ứng dụng để đăng k&yacute; cho mọi người bỏ phiếu trong Khối thịnh vượng chung Pennsylvania (&ldquo;Người đăng k&yacute;&rdquo;).&nbsp; Trước khi bạn sử dụng hoặc nộp bất kỳ th&ocirc;ng tin n&agrave;o li&ecirc;n quan đến Ứng dụng, h&atilde;y xem kỹ Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư v&agrave; Điều khoản Sử dụng n&agrave;y. Khi sử dụng Ứng dụng, bạn chấp thuận cho ph&eacute;p thu thập, sử dụng v&agrave; tiết lộ th&ocirc;ng tin của bạn, cũng như điều khoản v&agrave; điều kiện sử dụng, như được n&ecirc;u b&ecirc;n dưới.</p>
            <p>Ch&uacute;ng t&ocirc;i sẽ li&ecirc;n tục r&agrave; so&aacute;t c&aacute;c ch&iacute;nh s&aacute;ch n&agrave;y v&agrave; đ&ocirc;i khi ch&uacute;ng t&ocirc;i c&oacute; thể thực hiện thay đổi đối với ch&iacute;nh s&aacute;ch. Ng&agrave;y ở cuối trang n&agrave;y cho biết lần gần nhất thay đổi được thực hiện. Bạn n&ecirc;n định kỳ kiểm tra xem trang n&agrave;y c&oacute; cập nhật kh&ocirc;ng. Nếu bạn tiếp tục d&ugrave;ng Ứng dụng th&igrave; tức l&agrave; bạn đ&atilde; đọc, hiểu, v&agrave; chấp nhận điều khoản của Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư v&agrave; Điều khoản Sử dụng đ&atilde; cập nhật.</p>
            <p>&nbsp;</p>

            <p className="text-center underline"><strong>ĐIỀU KHOẢN SỬ DỤNG</strong></p>
            <ol className="list-decimal">
                <li className="pb-8">
                    <strong>Truy cập vào Ứng dụng.</strong> Mỗi Người vận động bỏ phiếu truy cập vào Ứng dụng đảm bảo với PA Vote for Change LLC (“PA Vote for Change”, hoặc “chúng tôi”) rằng Người vận động bỏ phiếu được ủy quyền dùng ứng dụng bởi một tổ chức được PA Vote for Change ủy quyền dùng Ứng dụng.   Mỗi Người vận động bỏ phiếu đảm bảo rằng thông tin mà họ cung cấp trong Ứng dụng là chính xác, đầy đủ và mới nhất. Mỗi Người đăng ký dùng Ứng dụng đảm bảo rằng họ đủ điều kiện bỏ phiếu ở Hoa Kỳ và thông tin họ cung cấp là chính xác, đầy đủ và mới nhất.<br /><br />
                    Mỗi Người vận động bỏ phiếu chịu trách nhiệm bảo vệ Mã Đối tác và mật khẩu được sử dụng để truy cập Ứng dụng và đồng ý rằng Người vận động bỏ phiếu sẽ không tiết lộ Mã Đối tác hoặc mật khẩu cho bất kỳ cá nhân hoặc tổ chức nào khác. Người vận động bỏ phiếu phải thông báo ngay cho Tổ chức mà Người vận động bỏ phiếu là nhân viên, nhà thầu hoặc tình nguyện viên khi biết về hành vi vi phạm bảo mật hoặc sử dụng trái phép tài khoản của Người vận động bỏ phiếu.  Sau đó thì Tổ chức phải thông báo ngay cho PA Vote for Change.
                </li>
                <li className="pb-8">
                    <strong>Sở hữu Trí tuệ.</strong> Mỗi người dùng Ứng dụng (bao gồm mỗi Người đăng ký, Người vận động bỏ phiếu và Tổ chức) đồng ý rằng, giữa người dùng đó và PA Vote for Change, tất cả bản quyền, nhãn hiệu và các quyền sở hữu trí tuệ khác trong và đối với phần mềm mà Ứng dụng hoạt động qua đó, dưới bất kỳ hình thức nào, mã đối tượng hoặc mã nguồn, và kiến trúc, tính năng và chức năng của Ứng dụng, đang và sẽ vẫn là tài sản độc hữu và độc quyền của PA Vote for Change, mỗi Người dùng cũng đồng ý rằng họ không có quyền sở hữu trong và đối với Ứng dụng thông qua việc sử dụng Ứng dụng.
                </li>
                <li className="pb-8">
                    <strong>Chấm dứt.</strong> PA Vote for Change có thể đình chỉ hoặc hủy bỏ tài khoản của bất kỳ Người vận động bỏ phiếu nào hoặc đình chỉ hoặc hủy bỏ quyền truy cập của Tổ chức vào Ứng dụng mà không cần thông báo trước hoặc chịu trách nhiệm pháp lý, trong trường hợp Người vận động bỏ phiếu hoặc Tổ chức vi phạm Chính sách Quyền riêng tư hoặc Điều khoản Sử dụng nếu áp dụng.
                </li>
                <li className="pb-8">
                    <strong>Giới hạn Trách nhiệm.</strong>  Trong mọi trường hợp, PA Vote for Change sẽ không chịu trách nhiệm cho bất kỳ thiệt hại, chi phí, tiền phạt hoặc hình phạt nào mà Tổ chức hoặc bất kỳ Người vận động bỏ phiếu nào hoặc bất kỳ Người đăng ký nào hoặc bất kỳ cá nhân hoặc tổ chức nào khác phải chịu do dùng Ứng dụng.  Trong phạm vi tối đa được luật pháp hiện hành cho phép, trong mọi trường hợp, PA Vote for Change sẽ không chịu trách nhiệm cho bất kỳ thiệt hại đặc thù, ngẫu nhiên, gián tiếp hoặc do hậu quả nào (bao gồm nhưng không giới hạn ở thiệt hại do mất lợi nhuận, mất dữ liệu hoặc thông tin khác, do gián đoạn kinh doanh, do thương tích cá nhân, mất quyền riêng tư phát sinh từ hoặc theo bất kỳ cách nào liên quan đến việc dùng hoặc không thể dùng Ứng dụng, phần mềm của bên thứ ba và/hoặc phần cứng của bên thứ ba được sử dụng với Ứng dụng hoặc Ứng dụng được vận hành trên đó, ngay cả khi PA Vote for Change đã được thông báo về khả năng có thiệt hại như vậy và ngay cả khi biện pháp khắc phục không đạt được mục đích thiết yếu của nó.<br /><br />
                    Một số tiểu bang không cho phép loại trừ bảo đảm ngầm định hoặc giới hạn trách nhiệm đối với thiệt hại ngẫu nhiên hoặc do hậu quả, có nghĩa là một số giới hạn ở trên có thể không áp dụng.  Ở các tiểu bang này, trách nhiệm của mỗi bên sẽ được giới hạn ở phạm vi lớn nhất được pháp luật cho phép.<br /><br />
                    Giữa PA Vote for Change và mỗi Tổ chức, Người vận động bỏ phiếu và Người đăng ký, Ứng dụng được cung cấp “nguyên trạng” và “sẵn sàng để dùng” và với tất cả các lỗi và ảnh hưởng mà không có bất kỳ hình thức bảo đảm nào.  Trong phạm vi tối đa được cho phép theo luật hiện hành, PA Vote for Change, nhân danh chính họ và đại diện cho nhóm tổ chức của PA Vote for Change, cũng như các nhà tài trợ của Ứng dụng và người cấp phép và nhà cung cấp dịch vụ tương ứng của họ, tuyệt đối từ chối trách nhiệm về tất cả bảo đảm, bất kể rõ ràng, ngầm định, theo luật định hay cách khác, đối với Ứng dụng, bao gồm tất cả các bảo đảm ngụ ý về khả năng bán được, tính phù hợp cho mục đích cụ thể, chứng thư và không vi phạm, và các bảo đảm có thể phát sinh trong quá trình giao dịch, quá trình hoạt động, sử dụng hoặc thực hành thương mại. Không giới hạn những điều đã nói ở trên, PA Vote for Change không cung cấp bảo hành hoặc cam kết nào và không tuyên bố dưới bất kỳ hình thức nào rằng Ứng dụng sẽ đáp ứng các yêu cầu của bất kỳ Tổ chức nào, đạt được bất kỳ kết quả dự kiến nào, tương thích hoặc hoạt động với bất kỳ phần mềm, ứng dụng, hệ thống hoặc dịch vụ nào khác, hoạt động không bị gián đoạn, đáp ứng bất kỳ tiêu chuẩn hiệu suất hoặc độ tin cậy nào hoặc không có lỗi hoặc bất kỳ lỗi hoặc hư hỏng nào có thể hoặc sẽ được sửa chữa.<br /><br />
                    Không giới hạn những điều đã nói ở trên, PA Vote for Change không đưa ra bất kỳ tuyên bố hoặc bảo đảm nào dưới bất kỳ hình thức nào, rõ ràng hay ngầm định: (i) về hoạt động hoặc tính khả dụng của Ứng dụng, hoặc thông tin, nội dung, và tư liệu hoặc sản phẩm có trong đó; (ii) rằng Ứng dụng sẽ không bị gián đoạn hoặc không có lỗi; (iii) về độ chính xác, độ tin cậy, hoặc độ cập nhật của bất kỳ thông tin hoặc nội dung nào được cung cấp thông qua Ứng dụng; hoặc (iv) Ứng dụng, máy chủ của Ứng dụng, nội dung hoặc email được gửi từ hoặc thay mặt cho PA Vote for Change không có vi rút, mã lệnh, mã độc, sâu máy tính, phần mềm độc hại, phần mềm bom hẹn giờ hoặc các thành phần có hại khác.<br /><br />
                    Một số khu vực pháp lý không cho phép loại trừ các hình thức bảo đảm hoặc giới hạn nhất định đối với các quyền theo luật định hiện hành của người tiêu dùng, vì vậy một số hoặc tất cả các loại trừ và giới hạn ở trên có thể không áp dụng. Nhưng trong trường hợp như vậy, các loại trừ và giới hạn quy định trong phần này sẽ được áp dụng ở phạm vi lớn nhất có thể thực thi theo luật hiện hành.
                </li>
                <li className="pb-8">
                    <strong>Luật Chi phối.</strong> Các Điều khoản này sẽ được chi phối và hiểu theo luật của Khối thịnh vượng chung Pennsylvania.
                </li>
                <li className="pb-8">
                    <strong>Tính hiệu lực từng phần.</strong> Nếu bất kỳ điều khoản nào trong các Điều khoản này được coi là không thể thực thi hoặc không hợp lệ, điều khoản đó sẽ được thay đổi và giải thích để hoàn thành mục tiêu của điều khoản đó ở mức độ cao nhất có thể theo luật hiện hành và các điều khoản còn lại sẽ vẫn có hiệu lực đầy đủ.
                </li>
                <li className="pb-8">
                    <strong>Từ bỏ.</strong> Trừ khi được quy định ở đây, việc không thực hiện quyền hoặc yêu cầu thực hiện nghĩa vụ theo các Điều khoản này sẽ không ảnh hưởng đến khả năng của một bên trong việc thực hiện quyền đó hoặc yêu cầu thực hiện nghĩa vụ đó vào bất kỳ thời điểm nào sau đó cũng như việc bỏ qua vi phạm sẽ không cấu thành việc bỏ qua bất kỳ vi phạm nào tiếp theo.
                </li>
            </ol>
            <p>&nbsp;</p>
            <p>Nếu bạn có bất kỳ thắc mắc nào về Chính sách Quyền riêng tư hoặc Điều khoản Sử dụng này, bạn có thể liên hệ với chúng tôi bằng cách gửi email cho chúng tôi theo địa chỉ help@pavoteforchange.com.</p>
            <p>&nbsp;</p>
            <p>Cập nhật Gần nhất: 9/21/2022</p>
        </>,
    },
    "update_information": {
        heading: "Bạn có cần thực hiện bất kỳ thay đổi nào đối với đăng ký của mình không?",
        content: "Vui lòng chọn tất cả các mục áp dụng:",
        error: "Vui lòng đánh dấu chọn ít nhất một ô.",
        change_name: "Tôi cần thay đổi tên trong đăng ký cử tri.",
        change_address: <>Tôi cần thay đổi địa chỉ liên quan đến đăng ký cử tri.<br /><em><span className="text-xs">Nếu gần đây bạn đã chuyển đến Pennsylvania từ một tiểu bang khác, bạn phải <Link className="underline" to={'/new-registration'} state={{ resetRegistration: true }}>nộp đăng ký mới</Link> chứ không chỉ thay đổi địa chỉ.</span></em></>,
        change_party: "Tôi cần thay đổi đảng chính trị ủng hộ.",
        federal_employee: "Tôi là nhân viên Liên bang hoặc Tiểu bang đăng ký tại quận có nơi cư trú gần nhất của tôi.",

    },

    "ballot_request": {
        heading: "VI - Annual Mail-In Ballot Application",
        content: "VI - If you would like to receive mail-in ballots for the remainder of this year and if you would like to automatically receive an annual application for mail-in ballots each year, please indicate below.",
        content2: "VI - Your name, address, county, date of birth, and PA driver's license or PennDOT ID card number provided on the previous screens will be used for your Mail-In Ballot Application.",
        label: "VI - Would you like to receive mail-in ballots for any election you qualify for?",
        address_type_label: "VI - Mail ballot to me at the following address:",
        address_type_1: "VI - Residential Address",
        address_type_2: "VI - Mailing Address",
        address_type_3: "VI - Alternate Address",
        ballot_request_type_error: "VI - Please select the address type.",
        ballot_ward_label: "VI - Please indicate your Ward or Voting District (if known)",
        ballot_lived_since_label: "VI - I have lived at this address since:",
        ballot_lived_since_error: "VI - Please provide the year you moved to this address.",
        ballot_lived_since_error_2: "VI - Please provide a valid year.",
        id_text: "VI - Identification: Your PA driver's license or PennDOT ID card number provided above will be used for your Mail-In Ballot Application. If this information is not correct please make any necessary edits in ID Info.",
        id_field: "VI - PA Driver's License of PennDOT ID card number: ",
        annual_request: "Nếu quý vị muốn đăng ký nhận lá phiếu qua thư trong thời gian còn lại của năm nay và nếu quý vị muốn được tự động nhận đơn đăng ký hàng năm lá phiếu qua thư mỗi năm, vui lòng cho biết dưới đây.",
        annual_request_field: "Tôi muốn nhận lá phiếu qua thư trong năm nay và nhận đơn đăng ký hàng năm lá phiếu qua thư mỗi năm.",
        confirmation_heading: "Người trợ lý: Vui lòng xem lại tuyên bố dưới đây và ký tên để xác nhận đồng ý với các điều khoản.",
        review_terms_confirm: "TÔI XÁC NHẬN RẰNG TÔI ĐÃ ĐỌC VÀ ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN TRÊN.",
    },
}


export const getContent = (section) => {
    const currentLanguage = store.getState().settings.currentLanguage
    const contentArray = section.split('.');

    if (currentLanguage === 'en') {
        return english[contentArray[0]][contentArray[1]];
    }
    if (currentLanguage === 'es') {
        return spanish[contentArray[0]][contentArray[1]];
    }
    if (currentLanguage === 'zh') {
        return chinese[contentArray[0]][contentArray[1]];
    }
    if (currentLanguage === 'vi') {
        return vietnamese[contentArray[0]][contentArray[1]];
    }
    console.error(`ERROR: Can't find section ${section} for ${currentLanguage}`);
}
