import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import { useForm, Controller, set } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";
import { isValidZip } from '../helpers';
import InputMask from "react-input-mask";

function VBMTypeForm({ submit, cancel, hideYesNo = false, isUpdateForm = false }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);

  const { handleSubmit, control, formState: { errors }, setValue, watch, clearErrors } = useForm({
    defaultValues: {
      ballot_request_address_type: '',
      ballot_request_address_1: '',
      ballot_request_city: '',
      ballot_request_state: '',
      ballot_request_zipcode: '',
      ballot_mailing_country: '',
      ballot_request_ward: '',
      ballot_request_lived_since: '',
      ballot_request_annual_request: false,
    }
  });

  const { ballot_request_address_type } = watch();

  React.useEffect(() => {
    const {
      ballot_request_address_type,
      ballot_request_address_1,
      ballot_request_city,
      ballot_request_state,
      ballot_request_zipcode,
      ballot_mailing_country,
      ballot_request_ward,
      ballot_request_lived_since,
      ballot_request_annual_request,
    } = registrant;

    setValue('ballot_request_address_type', ballot_request_address_type);
    setValue('ballot_request_address_1', ballot_request_address_1);
    setValue('ballot_request_city', ballot_request_city);
    setValue('ballot_request_state', ballot_request_state);
    setValue('ballot_request_zipcode', ballot_request_zipcode);
    setValue('ballot_mailing_country', ballot_mailing_country);
    setValue('ballot_request_ward', ballot_request_ward);
    setValue('ballot_request_lived_since', ballot_request_lived_since);
    setValue('ballot_request_annual_request', ballot_request_annual_request);
  }, [registrant]);

  React.useEffect(() => {
    clearErrors();
    if (ballot_request_address_type === 'R') {
      const {
        address_1,
        address_2,
        address_2_type,
        address_2_number,
        city,
        state,
        zipcode,
      } = registrant;

      setValue('ballot_request_address_1', `${address_1}${address_2_type !== '' ? ', '+address_2_type+' '+address_2_number : ''}${address_2 !== '' ? ', '+address_2 : ''}`.substring(0, 40));
      setValue('ballot_request_city', city);
      setValue('ballot_request_state', state);
      setValue('ballot_request_zipcode', zipcode);
      setValue('ballot_request_ward', '');
      setValue('ballot_request_lived_since', '');
      setValue('ballot_request_country', '');
    } else if (ballot_request_address_type === 'M') {
      const {
        mailing_address_1,
        mailing_address_2,
        mailing_unit_type,
        mailing_unit_number,
        mailing_city,
        mailing_state,
        mailing_zipcode,
        mailing_country,
      } = registrant;

      setValue('ballot_request_address_1', `${mailing_address_1}${mailing_unit_type !== '' ? ', '+mailing_unit_type+' '+mailing_unit_number : ''}${mailing_address_2 !== '' ? ', '+mailing_address_2 : ''}`.substring(0, 40));
      setValue('ballot_request_city', mailing_city);
      setValue('ballot_request_state', mailing_state);
      setValue('ballot_request_zipcode', mailing_zipcode);
      setValue('ballot_request_ward', '');
      setValue('ballot_request_lived_since', '');
      setValue('ballot_request_country', mailing_country);
    } else {
      const {
        ballot_request_address_1,
        ballot_request_city,
        ballot_request_state,
        ballot_request_zipcode,
        ballot_request_ward,
        ballot_request_lived_since,
      } = registrant;

      setValue('ballot_request_address_1', ballot_request_address_1);
      setValue('ballot_request_city', ballot_request_city);
      setValue('ballot_request_state', ballot_request_state);
      setValue('ballot_request_zipcode', ballot_request_zipcode);
      setValue('ballot_request_ward', ballot_request_ward);
      setValue('ballot_request_lived_since', ballot_request_lived_since);
      setValue('ballot_request_country', '');
    }

    const {
      ballot_request_ward,
      ballot_request_lived_since,
      ballot_request_annual_request,
    } = registrant;

    setValue('ballot_request_ward', ballot_request_ward);
    setValue('ballot_request_lived_since', ballot_request_lived_since);
    setValue('ballot_request_annual_request', ballot_request_annual_request);
  }, [ballot_request_address_type]);

  const onSubmit = function(data) {
    data.ballot_request = "yes";
    data.ballot_request_zipcode = data.ballot_request_zipcode.replace(/\-\_*$/g, '');
    
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="flex items-center justify-between ">
        {errors.ballot_request?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>

      <div className="flex-1 p-2">
        <label>{getContent('ballot_request.address_type_label')}</label>
        <Controller
          name="ballot_request_address_type"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => {
            return (
              <div className={`select ${errors.address_type ? 'error' : ''}`} >
                <select {...field}>
                  <option value="">{getContent('placeholders.dropdown')}</option>
                  <option value="R">{getContent('ballot_request.address_type_1')}</option>
                  {registrant.has_mailing_address === 'yes' && (<option value="M">{getContent('ballot_request.address_type_2')}</option>)}
                </select>
              </div>
            );
          }}
        />
      </div>
      <div className="flex items-center justify-between ">
        {errors.ballot_request_address_type?.type === 'required' && <span className="error">{getContent('ballot_request.ballot_request_type_error')}</span>}
      </div>

      <div className="flex-1 p-2">
        <label>{getContent('fields.ballot_mailing_address')}*</label>
        <Controller
          name="ballot_request_address_1"
          control={control}
          rules={{
            required: true,
            maxLength: 40
          }}
          render={({ field }) => <input disabled={ballot_request_address_type !== 'A'} {...field} placeholder="555 Oak St." type="text" maxLength="40" autoComplete="off" className={`${errors.ballot_request_address_1 ? 'error' : ''}`} />}
        />
        {errors.ballot_request_address_1?.type === 'required' && <span className="error">{getContent('validation.mailing_address')}</span>}
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.ballot_mailing_city')}*</label>
          <Controller
            name="ballot_request_city"
            control={control}
            rules={{
              required: true,
              maxLength: 20,
            }}
            render={({ field }) => <input disabled={ballot_request_address_type !== 'A'} {...field} placeholder="City" type="text" maxLength="20" autoComplete="off" className={`${errors.ballot_request_city ? 'error' : ''}`} />}
          />
          {errors.ballot_request_city?.type === 'required' && <span className="error">{getContent('validation.mailing_city')}</span>}

        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.ballot_mailing_state')}*</label>
          <Controller
            name="ballot_request_state"
            control={control}
            rules={{
              required: true,
              maxLength: 20,
            }}
            render={({ field }) => <input disabled={ballot_request_address_type !== 'A'} {...field} placeholder="State" type="text" maxLength="20" autoComplete="off" className={`${errors.ballot_request_state ? 'error' : ''}`} />}
          />
          {errors.ballot_request_state?.type === 'required' && <span className="error">{getContent('validation.mailing_state')}</span>}

        </div>
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.ballot_mailing_zipcode')}*</label>
          <Controller
            name="ballot_request_zipcode"
            control={control}
            rules={{
              required: true,
              maxlength: 10,
            }}
            render={({ field }) => {
              return (
                <input disabled={ballot_request_address_type !== 'A'} placeholder="12345" className={`input ${errors.ballot_request_zipcode ? 'error' : ''}`} {...field} autoComplete="false" maxLength="10" />
              )
            }}
          />
          {errors.ballot_request_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
          {errors.ballot_request_zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.mailing_zipcode')}</span>}

        </div>
      </div>

      {ballot_request_address_type === 'M' && (
        <div className="flex">
          <div className="flex-1 p-2">
            <label>{getContent('fields.ballot_mailing_country')}*</label>
            <Controller
              name="ballot_request_country"
              control={control}
              rules={{
                required: true,
                maxlength: 40,
              }}
              render={({ field }) => {
                return (
                  <input disabled={ballot_request_address_type !== 'A'} placeholder="Country" className={`input ${errors.ballot_request_country ? 'error' : ''}`} {...field} />
                )
              }}
            />
            {errors.ballot_request_country?.type === 'required' && <span className="error">{getContent('validation.country')}</span>}

          </div>
        </div>
      )}

      <div className="flex-1 p-2">
        <label>{getContent('ballot_request.ballot_lived_since_label')}*</label>
        <Controller
          name="ballot_request_lived_since"
          control={control}
          rules={{
            required: true,
            maxLength: 4,
            min: 1900,
            max: new Date().getFullYear(),
            validate: year => {
              return /^\d{4}$/.test(year);
            },
          }}
          render={({ field }) => <input {...field} placeholder="" type="number" maxLength="4" className={`${errors.ballot_request_lived_since ? 'error' : ''}`} />}
        />
        {errors.ballot_request_lived_since?.type === 'required' && <span className="error">{getContent('ballot_request.ballot_lived_since_error')}</span>}
        {(errors.ballot_request_lived_since?.type === 'validate' || errors.ballot_request_lived_since?.type === 'maxLength' || errors.ballot_request_lived_since?.type === 'min' || errors.ballot_request_lived_since?.type === 'max') && <span className="error">{getContent('ballot_request.ballot_lived_since_error_2')}</span>}
      </div>

      <p className="pt-4">{getContent('ballot_request.id_text')}</p>

      <div className="flex-1 p-2">
        <label>{getContent('ballot_request.id_field')}</label>
        <input disabled placeholder="" type="text" value={registrant.pa_id_number} />
      </div>

      <p className="pt-4">{getContent('ballot_request.annual_request')}</p>

      <div className="flex-1 p-2">
        <div className="flex items-center justify-between p-2 pt-6 ">
          <Controller
            name="ballot_request_annual_request"
            control={control}
            render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.ballot_request_annual_request} /><span></span> <span>{getContent('ballot_request.annual_request_field')}</span></label>}
          />
        </div>
      </div>

      <div className="flex justify-end pt-8 pb-2">
        {isUpdateForm && (<TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>)}
        <PrimaryButton submit>{isUpdateForm ? getContent('buttons.update_information') : getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default VBMTypeForm;
