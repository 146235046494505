import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "../Components/BackButton";
import VBMOnlyForm from "../Forms/VBMOnlyForm";
import { useDispatch } from "react-redux";
import { updateRegistrant, clearCurrentRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";

function VBMOnly() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        dispatch(changeStep([1, 6]));
        
        if (location.state?.resetRegistration) {
            dispatch(clearCurrentRegistrant());
            dispatch(updateRegistrant({
                registered: false,
            }));
        }
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        dispatch(updateRegistrant({
            ...data
        }));

        navigate("/vbm-id-entry");
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:my-8 lg:my-16">{getContent('registration.heading_vmb_only')}</h2>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-4 border-4 rounded-lg border-pvr-darkblue sm:p-8">
                    <VBMOnlyForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default VBMOnly;